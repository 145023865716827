html {
  overflow: scroll;
  /*scroll-behavior: smooth;*/
  overflow-x: hidden;
}

/* do media break like solace */


body {
  margin: 0;
  background-color: white;
  font-family: 'futura-pt', sans-serif;
}

/* intro on homepage */

div.header {
  margin-top: 70px; /*7em */
  background-color: #f0f0f0;
  height: 22em; /* 15em */
  width: 100%;
  line-height: 75px;
  padding-top: 0.7em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  flex-basis: 20em;
}

div.border {
  line-height: 75px;
  width: 20em;
  text-align: left;
  margin-left: 2em; /* 6em full */
  background-color: #f0f0f0;
}

div.border a {
  margin-bottom: 1em;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
}

div.border a:hover, div.border a:focus, div.border a:active {
  opacity: 0.7;
}

img.lilacLogo {
  height: 40px;
  width: auto;
  padding-left: 0px;
  margin-left: 0px;
  margin-top: 12px;
}

div.titleIntro {
  display: flex;
  flex-direction: row;
}

div.titleIntro p.h1 {
  margin-right: 0px;
  padding-right: 0px;
  margin-bottom: 15px;
}

div.titleIntro img {
  margin-left: 10px;
  padding-left: 0px;
}

img.emailLink {
  height: 20px;
  width: auto;
  margin-left: 2em;
}

img.linkedin {
  height: 15px;
  width: auto;
  margin-left: 1em;
}

img.paper {
  height: 20px;
  width: auto;
  margin-left: 1em;
}

img.paper:hover, img.linkedin:hover, img.emailLink:hover, img.paper:active, img.paper:focus, img.linkedin:focus, img.linkedin:active, img.emailLink:focus, img.emailLink:active {
  opacity: 0.7;
}

div.social {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}

/* arrow stuff */

button.arrow {
  opacity: 1;
  transition: .5s ease;
  backface-visibility: visible;
  position: relative;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  /*margin-top: 1em;*/
}

button.arrow:active, .middle2:active, .text2:active, .text2 a:active {
  border: none;
}

.middle2 {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.text2 a:hover, .text2 a, .text2, .text2 a:focus, .text2:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

button.arrow:hover .arr, button.arrow:focus .arr {
  opacity: 0;
  cursor: pointer;
}

button.arrow:hover .middle2, button.arrow:focus .middle2 {
  opacity: 1;
}

.text2 {
  color: black;
  font-size: 16px;
  padding: 5px;
  line-height: normal;
  width: 6em;
  font-weight: 400;
}

button.arrow img {
  margin-top: 0px;
  padding-top: 0px;
  /*padding-right: 30px;*/
}

/* header text */

div.titleIntro p.h1 {
  font-family: 'Lato', sans-serif; 
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 1em;
  text-align: left;
}

main {
  background-color: #f0f0f0;
}

div.border p.h2 {
  font-family: 'europa', sans-serif; 
  font-weight: 500;
  font-size: 14px;
  margin-top: 0.5em;
  line-height: 35px; 
  padding-right: 25px;
  margin-bottom: 0px;
}

div.border p.h2 b {
  color: #947CBB !important;
}

div.border p.h2 {
  color: #333333;
}

p a:hover, p a, p a:focus {
  color: #947CBB;
}

p a:hover, p a:focus {
  opacity: 0.5;
}


/* nav */

nav {
  margin: 0 !important;
  padding: 0;
  position: fixed !important;
  font-family: 'futura-pt', sans-serif !important;
  font-weight: 400;
  width: 100% !important;
  top: 0;
  left: 0;
  z-index: 100;
  border: none !important;
}

nav.navbar {
  background-color: white !important;
}

nav.navbar a {
  color: black;
}

nav.navbar a.first2:hover, nav.navbar a.first2:focus {
  color: #446992 !important;
}

nav.navbar a.first4:hover, nav.navbar a.first4:focus {
  color: #3E5679 !important;
}

nav.navbar a:hover, nav.navbar a:active, nav.navbar a:focus {
  color: #9C82E7;
}

nav.navbar a.first3:hover, nav.navbar a.first3:focus, nav.navbar a.first3:active {
  color: #7E5DDC !important;
}

.navbar li a {
  color: black;
}

nav ul {
  padding: 0;
  margin-bottom: 0;
  /*display: inline-block;*/
  display: none;
}

 ul.navbar-right  {
  margin-right: 2em;
}

nav div.name {
  font-weight: 700;
  font-size: 21px;
  padding-left: 15px;
}

nav li, button.dropdown-toggle {
  display: inline-block;
  line-height: 47px; /* 64px origin */
}

nav li.team, nav li.about, nav li.home {
  margin-right: 1em;
}

nav li.about a:active, nav li.about a:focus, nav li.about a:hover {
  color: #9C82E7 !important;
}

nav a {
  margin-right: 1em;
  text-decoration: none;
}

.navbar-brand, .navbar-brand a:hover, .navbar-brand a:focus, .navbar-brand a:active{
  color: #333333 !important;
  text-decoration: none;
}

nav div.name, nav div.name:hover, nav div.name:focus, nav div.name:active {
  color: #333333;
  text-decoration: none;
}

nav li.home a, nav li.team a, nav li.about a, nav li.dropdown a {
  font-family: 'futura-pt', sans-serif;
  color: #333333;
  font-size: 17px;
  font-weight: 500;
}

nav li.team a {
  color: black !important;
}

.navbar-inverse .navbar-nav>li>a {
  color: black;
}

nav li.home, nav li.home a {
  margin-right: 0px;
}

/* dropdown */

nav li.open button.dropbtn {
  color: rgb(126, 93, 220);
  font-weight: 500;
}

button.dropbtn:hover {
  font-weight: 500;
}

.dropdown {
  padding-top: 0px;
  margin-top: 0;
  margin-right: 0.5em;
}

/* Dropdown button */
.dropdown .dropdown-toggle {
  font-size: 17px !important;
  border: none;
  /*padding-top: 15px;*/ /*0px*/
  padding-left: 15px; /* added for collapse!! */
  color: black;
  text-align: center;
  height: 50px; /* 47px */
  padding-bottom: 0px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

button.dropdown-toggle {
  font-weight: 500;
}

.navbar-toggle {
  border: none;
}

.navbar-toggle .icon-bar, .navbar-inverse .navbar-toggle .icon-bar {
  background-color: black;
}

.navbar-inverse .navbar-toggle, .navbar-inverse .navbar-toggle:focus {
  background-color: white !important;
}

div.navbar-header button {
  color: black;
}

.dropdown-toggle:focus {
  outline: -webkit-focus-ring-color auto 1px;
}

.navbar ul a:hover, .navbar ul a:focus, li.dropdown a:focus-within {
  color: #9C82E7 !important;
  font-weight: 500;
}

/* Dropdown content (hidden by default) */
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 95px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  font-size: 17px;
  padding: 0px !important;
  border: none !important;
  line-height: 35px;
}

.dropdown:focus .dropdown-menu, .dropdown:active .dropdown-menu {
  /*display: block;*/
  display: block; /* added !!! check mobile */
}

li.open a {
  background-color: #f0f0f0;
}

div.collapse {
  background-color: #f0f0f0;
  border: none;
}

div.navbar-collapse button.dropdown-toggle {
  padding-top: 0px;
}

.dropdown-menu a {
  float: none;
  color: black;
  margin-right: 0px;
  text-decoration: none;
  display: block;
}  

.dropdown-menu a {
  text-align: left;
  padding-left: 25px;
}

.dropdown-menu a:focus-within>li.dropdown a {
  visibility: visible;
  opacity: 1;
  color: #9C82E7 !important;
}

.dropdown-menu a:active .dropbtn, .dropdown-menu a:focus .dropbtn {
  color: #9C82E7 !important;
}

/* other */

div.body {
  display: flex;
  flex-direction: column;
}

div.foot {
  bottom: 0;
  width: 100%;
  margin-top: 3em; /*3em */
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #f0f0f0;
  height: 8em; /* 10em */
  font-size: 23px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

div.foot a {
  color: #947CBB;
}

div.foot a:hover, div.foot a:focus {
  opacity: 0.8;
  text-decoration: none;
}

div.email {
  /*margin-right: 5em;*/
  margin-top: 2em;
  font-weight: 500;
}

div.email a {
  text-decoration: none;
}


div.credit2 {
  background-color: #f0f0f0;
  margin-bottom: 0px;
  bottom: 0%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 190px;
  line-height: 25px;
}

div.credit2 p {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
}

div.credit2 {
  font-family: 'europa', 'sans-serif';
  font-weight: 500;
}

@media (min-width: 275px) and (max-width: 282px) {
  div.border {
    width: 17em;
  }

  div.border p.h2 {
    font-size: 13px;
  }

  div.border p.h1 {
    margin-bottom: 0.5em;
  }

  button.arrow {
    padding-left: 30px;
    background-color: transparent;
    border: none;
  }

}

@media (min-width: 330px) {
  div.border {
    margin-left: 3em;
  }
}

@media (min-width: 375px) /* iPhone ?? */ {
  div.credit2 {
    width: auto;
    line-height: auto;
  }

  div.social {
    margin-left: 15px;
  }
  
}

@media only screen and (device-width: 414px) and (device-height: 736px) { /* iphone 6+ */
  div.border {
    width: 22em;
  }

  button.arrow {
    text-align: center;
    background-color: transparent;
    border: none;
  }

  div.social {
    margin-left: 15px;
  }
}

@media (min-width: 530px) and (max-width: 545px) { /* surface pro */

  div.border {
    width: 25em;
    margin-left: 3em;
  }

  div.border p.h2 {
    font-size: 16px;
  }

  button.arrow {
    text-align: left;
    margin-left: 12em;
  }
  
  div.middle2 {
    left: 2%;
  }
} 

@media (min-width: 598px) {

  div.border {
    width: 40em;
  }

}

@media (min-height: 730px) and (min-width: 370px) { /* iphone x and above ? */

  div.social {
    margin-left: 0px;
  }

  div.titleIntro p.h1 {
    font-weight: 700;
  }

}  

@media only screen and (device-width: 375px) and (device-height: 812px) { /* iphone 11 pro */

  div.titleIntro p.h1 {
    font-size: 25px;
    font-weight: 700;
  }

  div.border p.h2 {
    font-size: 17px;
  }

  img.emailLink {
    height: 30px;
    width: auto;
    margin-left: 3em;
  }

  .dropdown-menu, .dropdown-menu a {
    background-color: white;
  }

  li.open a {
    background-color: white;
  }
  
  img.linkedin {
    height: 25px;
    width: auto;
    margin-left: 1em;
  }
  
  img.paper {
    height: 30px;
    width: auto;
    margin-left: 1em;
  }

  button.arrow {
    display: none;
  }

  div.project {
    margin-top: 190px;
  }

  div.social {
    margin-left: 0px;
  }
}  

@media (min-width: 768px) { /* iPad */

  nav li.home, nav li.team, nav li.about {
    display: inline-block;
  }

  nav ul {
    display: inline-block;
  }

  .dropdown-menu {
    background-color: #f0f0f0;
  }

  div.collapse {
    background-color: white;
  }

  .dropdown-toggle {
    min-width: 95px;
    padding-left: 0px;
  }

  button.dropdown-toggle {
    font-weight: 400;
  }

  .dropdown .dropdown-toggle {
    padding-top: 0px;
  }

  nav li.home a, nav li.team a, nav li.about a {
    font-weight: 400;
  }

  .navbar-inverse .navbar-nav>li>a {
    color: black;
  }

  nav li.nav-item {
    margin-top: 2px;
  }
  
  nav li.team a {
    margin-right: 0px;
  }

  nav li.open button.dropbtn {
    color: rgb(126, 93, 220);
    font-weight: 500;
  }

  div.border {
    width: 27em;
  }

  button.arrow {
    text-align: left;
    background-color: transparent;
    border: none;
    margin-left: 12em;
    display: none;
  }

  div.middle2 {
    left: 2%;
    top: 57%;
  }

  div.border p.h2 {
    font-size: 16px;
    line-height: 35px;
  }

  div.border p.h1 {
    font-size: 25px;
    font-weight: 600;
  }

  /*nav li.team, nav li.home, nav li.about, nav li.about a, nav li.team a {
    margin-right: 0px;
  } put this back */

  /*div.foot {
    margin-left: auto;
    margin-right: 3em;
  }*/

  div.email {
    margin-right: 3em;
    text-align: right;
  }

  div.social {
    margin-left: 0px;
  }

  img.emailLink {
    height: 30px;
    width: auto;
    margin-left: 3em;
  }
  
  img.linkedin {
    height: 25px;
    width: auto;
    margin-left: 1em;
  }
  
  img.paper {
    height: 30px;
    width: auto;
    margin-left: 1em;
  }

}



@media (min-width: 992px) {

  div.titleIntro p.h1 {
    font-size: 37px;
    font-weight: 600;
  }

  nav ul {
    display: inline-block;
  }

  .dropdown-menu {
    background-color: #f0f0f0;
  }
  
  div.border p.h2 {
    font-size: 23px;
    padding-left: 0px; /*check this! */
    padding-right: 0px;
  }

  button.arrow img {
    padding-right: 0px;
    margin-top: 1em;
  }

  button.arrow {
    display: block;
  }

  img.emailLink {
    height: 30px;
    width: auto;
    margin-left: 2em;
  }
  
  img.linkedin {
    height: 25px;
    width: auto;
    margin-left: 1em;
  }
  
  img.paper {
    height: 30px;
    width: auto;
    margin-left: 1em;
  }
  
}

@media (min-width: 1020px) { /* iPad pro */

  div.border {
    margin-left: 4em;
    width: 30em;
  }

  nav ul {
    display: inline-block;
  }

  .dropdown-menu {
    background-color: #f0f0f0;
  }

  button.arrow {
    margin-left: 16em;
    background-color: transparent;
    border: none;
    display: block;
  }

  div.social {
    margin-left: 27px;
  }

}

@media (min-width: 1200px) {

  nav div.name {
    padding-left: 3.7em;
  }

  .dropdown-menu {
    background-color: #f0f0f0;
  }

  nav ul {
    display: inline-block;
  }

  .dropdown-menu a {
    text-align: center;
    padding-left: 0px;
  }  

  nav.navbar li a {
    color: black;
  }

  nav li.nav-item {
    margin-top: 0px;
  }

  /* fix this so that no margin top */

  div.header {
    margin-top: 8em;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  div.border {
    width: 50em;
    margin-left: 7em;
    margin-top: 3em;
    padding-bottom: 0px;
    background-color: #f0f0f0;
  }


  p.h2 b {
    color: #947CBB !important;
  }

  p.h2 {
    color: #333333;
  }

  /* fix above */

  p.h1, p.h2 {
    margin-left: 50px;
  }

  p.h1 {
    font-size: 37px;
    font-weight: 600;
  }

  p.h2 {
    font-size: 23px; 
    padding-left: 0px; /*check this! */
    padding-right: 0px;
  }

  img.emailLink {
    height: 35px;
    width: auto;
  }
  
  img.linkedin {
    height: 30px;
    width: auto;
    margin-left: 1.5em;
  }
  
  img.paper {
    height: 35px;
    width: auto;
    margin-left: 1.5em;
  }

  div.social {
    margin-top: 0.5em;
  }

  /* more nav below */

  li.open a {
    background-color: white !important;
  }

  .dropdown-menu {
    background-color: white;
    line-height: 47px;
  }

  .dropdown-menu a.first:hover, .dropdown-menu a.first:focus {
    background-color: #f0f0f0 !important;
    color: #9C82E7 !important;
    font-weight: 500;
  }

  .dropdown-menu a.first2:hover, .dropdown-menu a.first3:hover, .dropdown-menu a.first2:focus, .dropdown-menu a.first3:focus, .dropdown-menu a.first4:hover, .dropdown-menu a.first4:focus {
    background-color: #f0f0f0 !important;
    font-weight: 500;
  }

  li.dropdown:hover, li.dropdown:active, li.dropdown:hover button, li.dropdown:focus {
    color: #9C82E7 !important;
    font-weight: 500;
  }

  div.dropdown-toggle:hover, div.dropdown-toggle:focus, div.dropdown-toggle:active, button.dropdown-toggle:hover, button.dropdown-toggle:focus, button.dropdown-toggle:active {
    color: #9C82E7 !important;
    font-weight: 500 !important;
  }

  .dropdown-menu a.first3:active {
    opacity: 1 !important;
  }

  /* adding padding */

  .dropdown .dropdown-toggle {
    padding-top: 2px;
  }

  /* okay */

  li.active>.dropdown-toggle {
    color: #9C82E7 !important;
    font-weight: 500 !important;
  }

  /* didn't really work above */

  div.footer {
    font-family: 'futura-pt', sans-serif;
  }

  .dropdown:hover .dropdown-menu, .dropdown:focus .dropdown-menu {
    display: block;
  }

  /*nav li.team a, nav li.about a {
    padding-bottom: 0 !important;
    margin-right: 1em !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }*/

  nav li.team {
    margin-right: 0px;
  }

  .dropdown .dropbtn {
    /*padding-left: 15px;*/
    padding-left: 0px;
  }

  li.dropdown {
    margin-right: 0px;
  }

  .dropdown button.dropdown-toggle {
    padding-left: 8px; /* added ! */
    padding-top: 2px;
  }

  .dropdown-toggle, .dropdown-menu {
    min-width: 90px;
  }

  div.dropdown-menu a {
    padding-left: 0px;
    text-align: center;
    padding-right: 0px;
  }

  div.social {
    margin-left: 0px;
  }

  button.arrow {
    margin-left: 29em;
    background-color: transparent;
    border: none;
    margin-top: 0em;
    display: block;
    width: 100px;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  img.lilacLogo {
    /*height: 60px;*/
    height: 50px;
    width: auto;
  }

  div.titleIntro {
    display: flex;
    flex-direction: row;
  }

  div.titleIntro p.h1 {
    margin-right: 0px;
    padding-right: 0px;
    margin-bottom: 15px;
  }

  div.titleIntro img {
    margin-left: 10px;
    padding-left: 0px;
  }

  div.middle2 {
    /*left: 1.3%;*/
    left: 50%;
    top: 57.5%;
  }

  div.email {
    margin-right: 5em;
  }

  div.credit2 {
    font-family: 'europa', 'sans-serif';
    font-weight: 500;
  }

  img.emailLink {
    margin-left: 10.7em;
  }

}