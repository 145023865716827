body, html {
    height: 100%;
    scroll-behavior: smooth;
  }


  /* */

  div.container1 {
    width: 100%;
    /*padding-top: 6em;*/ /* margin top 1em */
    /*align-items: center;*/ /* added */
    padding-top: 10em; /* added */
    padding-bottom: 13em; /* 15em */
    display: flex;
    /*flex-direction: row;*/
    flex-direction: column;
    justify-content: center; /* added */
    background-color: #f0f0f0;
  }

  img.ampPic, img.ampPic1, img.ampPic2 {
    display: none;
  }

  div.titleAmp img.logo2 {
    width: 90px; /*60px */
    height: auto; 
    margin-left: 15px;
    margin-top: 10px;
  }

  div.container1 h2 {
    font-family: 'europa', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px; /* 18px */
    margin-top: 10px;
    width: 17em; /* 20em or 19em */
    line-height: 32px; /*29px*/
    align-self: center;
    text-align: center;
  }
  
  div.container1 p {
    font-family: 'roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  div.credit3 p {
    padding-left: 0px !important;
    width: auto;
  }

  div.amplifyPage h1, div.amplifyPage h2 {
    padding-left: 42px;
  }

  div.centered2 {
    display: flex;
    flex-direction: column;
   /* margin-left: auto;
    margin-right: auto;*/
  }

  .centered2 h1 {
    font-size: 35px; 
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #333333;
    padding-left: 0px !important;
  }

  .centered2 h2 {
    padding-left: 0px !important;
  }

  div.titleAmp {
    display: flex;
    flex-direction: row;
    align-self: center;
    /* going to add */
    padding-left: 10px;
  }

  img.ampPic {
    height: 45em;
    width: auto;
  }

  button.arrow2A, button.arrow4A, button.arrow5A, button.arrow6A, button.arrow7A, button.arrow8A {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute; 
    left: 50%;*/
    transform: translate(-50%, -50%);
    position: static;
    margin-left: 50%;
    width: 60px;
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }

  button.arrow2A {
    margin-top: 2em;
  }
  
  button.arrow3A {
    top: 195%;
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    position: absolute; 
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }

  button.arrow4A {
    top: 448%;
  }

  button.arrow6A {
    top: 680%;
  }

  button.arrow5A {
    top: 1480%;
    margin-top: 2em;
  }

  button.arrow7A {
    top: 520%;
  }

  button.arrow8A {
    margin-top: 2em;
  }

  button.arrow8A img.arr8A, button.arrow5A img.arr5A {
    box-shadow: none !important;
  }
  
  div.middlethree {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    /*padding-left: 5px;*/
  }
  
  .text3 a:hover, .text3 a, .text3, .text3 a:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .text5 a:hover, .text5 a, .text5, .text5 a:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  div.text4 {
    width: 6em;
    color: black;
    font-size: 16px;
    padding: 5px;
    font-weight: 400;
    font-family: 'futura-pt', 'sans-serif';
  }

  div.largeConc p, div.interviewA p, div.audioText p, div.tagText p, div.noteText p, div.ideationA p, div.reflectionA p {
    font-size: 18px;
    padding-left: 42px;
    width: 17em;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 1em;
  }

  div.text5 {
    color: black;
    font-size: 16px;
    padding: 5px !important;
    width: 5em !important;
    font-weight: 400;
  }
  
  button.arrow3A:hover .arr3A, button.arrow2A:hover .arr2A, button.arrow4A:hover .arr4A, button.arrow5A:hover .arr5A, button.arrow6A:hover .arr6A,
  button.arrow7A:hover .arr7A, button.arrow8A:hover .arr8A {
    opacity: 0;
  }
  
  button.arrow3A:hover .middlethree, button.arrow2A:hover .middlethree, button.arrow4A:hover .middlethree, button.arrow5A:hover .middlethree, button.arrow6A:hover .middlethree, 
  button.arrow7A:hover .middlethree, button.arrow8A:hover .middlethree {
    opacity: 1;
  }

  button.arrow3A:focus .arr3A, button.arrow2A:focus .arr2A, button.arrow4A:focus .arr4A, button.arrow5A:focus .arr5A, button.arrow6A:focus .arr6A,
  button.arrow7A:focus .arr7A, button.arrow8A:focus .arr8A {
    opacity: 0;
  }
  
  button.arrow3A:focus .middlethree, button.arrow2A:focus .middlethree, button.arrow4A:focus .middlethree, button.arrow5A:focus .middlethree, button.arrow6A:focus .middlethree, 
  button.arrow7A:focus .middlethree, button.arrow8A:focus .middlethree {
    opacity: 1;
  }
  
  div.text3 {
    /*font-family: 'europa', 'sans-serif';*/
    color: black;
    font-size: 16px;
    padding: 5px !important;
    width: 5em !important;
    font-weight: 400;
  }

  /* next container */

  div.ampcontainer2 {
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    height: 48em;
    padding-top: 5em;
    margin-top: 0px;    
    display: flex;
    flex-direction: column;
  }

  div.ampcontainer2 div.larger2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  div.ampcontainer2 .amptimeline, div.ampcontainer2 .ampaffil {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .amptimeline div, .ampaffil div {
    width: 14em;
    margin-bottom: 1.5em;
  }

  div.ampaff {
    text-align: center;
  }

  div.amprole {
    margin-left: 5em;
    margin-right: 5em;
  }

  div.ampcontainer2 div h4 {
    font-size: 20px;
    margin-bottom: 1em;
  }
  
  div.ampcontainer2 h4 {
    font-weight: 600 !important;
    font-family: 'futura-pt', sans-serif;
  }

  div.ampcontainer2 div h5 {
    font-size: 17px;
    text-align: center;
  }

  /* research */

  div.amproleFull, div.featuresA, div.ideationA, div.reflectionA {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  div.researchA {
    padding-top: 5em;
  }

  /* interview quote */

  div.interviewQuote {
    /*margin-top: 3em;*/
    padding-top: 4em;
    height: 38em; 
    flex-grow: 1;
    padding-right: 1em;
  }

  blockquote.b3 {
    font-size: 1.4em;
    width:17em; /* 25em */
    margin:30px auto;
    margin-left: 0;
    font-family: 'europa', sans-serif;
    color: #555555;
    padding:1.2em 30px 1.2em 75px;
    border-right:8px solid #6287B0;
    line-height:1.6;
    position: relative;
    border-left: none;
  }
  
  blockquote.b3::before {
    font-family: Arial;
    content: "\201C";
    /*color: #446992;*/
    color: #C69A5A;
    font-size:4em;
    position: absolute;
    left: 20px;
    top:-10px;
  }
  
  blockquote.b3::after {
    content: '';
  }
  
  blockquote.b3 span{
    display:block;
    color:#333333;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
  } 

  /* circles */
  
  div.circle1A {
    opacity: 1;
    backface-visibility: visible;
    text-align: center;
    position: relative;
    /*margin-left: 3em;*/
    margin-top: 3em;
    /*margin-right: 1em;*/
    margin-bottom: 3em;
  }

  div.circle1A img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    opacity: 1; 
  }

  .takeTextA {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .takeMidA {
    position: absolute;
    top: 0;
    bottom: 0;
    
    left: 18.7%;
    right: 0;
    width: 200px;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #f0f0f0;
    color: black;
    border-radius: 50%;
  }
  
  .circle1A:hover .takeMidA, .circle1A:focus .takeMidA, .circle1A:active .takeMidA {
    opacity: 0.97;
  }

  .takeTextA p {
    width: 160px;
    padding-left: 0px;
    font-size: 17px;
  }
  
  .takeTextA:hover, .takeTextA:focus, .takeTextA, .takeTextA:active {
    color: black;
    text-decoration: none;
    opacity: 1;
  }

  /* findings */

  div.findingsA {
    padding-top: 4em;
    padding-bottom: 3em;
  }

  div.findings1 h3 {
    padding-left: 42px;
    width: 13em;
  }

  /* images */

  img.tagImage2, img.noteImage2, img.ampwire1, img.ampwire2, img.protoA1, img.protoA3, img.protoA4, img.protoA5, img.protoA6, img.workingProto {
    width: 23em; /* 27em */
    height: auto;
    margin-left: 0px;
    padding-left: 0px;
  }

  .videoAmp {
    display: block;
  }

  .videoAmp1, div.videoAmp1 video {
    display: none;
  }

  div.reflectionA, div.amproleFull, div.featuresA {
    background-color: #f0f0f0;
  }

  div.reflectionA {
    padding-bottom: 2em;
    margin-bottom: 0px;
  }

  div.reflectionA p, div.amproleFull p, div.interviewA p, div.featuresA p, div.ideationA p {
    font-family: 'europa', sans-serif;
  }


  /* footer */

  div.credit3 p {
    text-align: center;
    font-size: 16px;
    font-family: 'europa', 'sans-serif';
    font-weight: 500;
  }

  div.credit3 {
    margin-bottom: 0px;
  }

  div.email3 a {
    color: #333333;
  }

  #backTopA {
    background-color: #B6C5D6;
    border: none;
    /*width: 100px;*/
    border-radius: 50%;
    height: 50px;
    width: 50px;
    outline: none;
    bottom: 75px;
    left: 270px;
    padding: 0px;
    display: block;
    position: fixed;
    z-index: 99;
  }

  div.backTop3 {
    background-color: transparent;
    outline: none;
    left: 240px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-right: 6px;
  }

  img.backTop2 {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
    padding-bottom: 0px;
    background-color: transparent;
  }  

  div.bottomLinks2 {
    background-color: #B6C5D6;
    width: 100%;
    height: 6.5em;
    border: none;
  }

  div.email3 {
    /*margin-right: 95px;*/
    padding-bottom: 5px;
    padding-top: 35px;
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
  }

  div.email3 a {
    font-weight: 500;
    text-decoration: none;
  }

  /*img.backTop2 {
    width: 80px;
    height: 80px;
    margin-bottom: 0px;
    background-color: transparent;
  }  */

  div.credit3 {
    text-align: center;
    padding-top: 10px;
    align-self: flex-end;
    padding-bottom: 5px;
    display: block;
    padding-left: 30px;
    padding-right: 30px;
  }

  div.credit3 p {
    margin-bottom: 0px;
  }

  div.ampFooter {
    height: 9.1em;
    margin-top: 0em;
    display: block;
  }    

  div.email3 a:hover, div.email3 a:focus, div.email3 a:active {
    text-decoration: none;
    opacity: 0.5;
  }

  div.plink2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 2em;
    margin-bottom: 0px;
  }

  div.nextP2 {
    font-family: 'europa', sans-serif;
    margin-bottom: 1em;
    font-size: 16px;
  }
  
  div.pName2 {
    font-size: 32px;
    font-family: 'Castoro', sans-serif;
    color: #333333;
    margin-bottom: 0px;
  }

  div.pName2 a {
    color: #333333;
    text-decoration: none;
    padding-left: 15px;
    opacity: 0.8;
  }

  div.ideationA img.ampwire1 {
    display: none;
    margin-left: auto;
    margin-right: auto;
  }

  img.footLink2 {
    padding-left: 2px;
  }

  div.pName2 a:hover, img.footLink2:hover, div.pName2 a:active, div.pName2 a:focus, img.footLink2:active, img.footLink2:focus {
    text-decoration: none;
    opacity: 0.8;
  }

  div.ampFooter, div.credit3 {
    background-color: #B6C5D6;
  }


  @media only screen and (min-width: 370px) and (max-width: 380px) and (min-height: 660px) and (max-height: 680px) and (-webkit-device-pixel-ratio: 2) { /* iphone 6 */

      div.bgimg {
        height: 1000px;
      }

      div.ideationA img.ampwire1 {
        display: none;
        margin-left: auto;
        margin-right: auto;
      }

      .box {
        margin-left: 20px;
        padding-left: 1em;
        padding-right: 1em;
        margin-right: 20px;
        margin-top: 0px;
      }

      .takeMidA {
        left: 23.4%; /* new */
      }

      .box img.logo2 {
        padding-left: 0px;
      }

      p.smaller {
        margin-top: 1em;
      }

      div.left {
        right: 16px;
      }
      
      #backTopA {
        left: 310px;
      }

      div.largeConc p, div.interviewA p, div.audioText p, div.tagText p, div.noteText p, div.ideationA p, div.reflectionA p {
        font-weight: 500;
      }

      img.tagImage2, img.noteImage2, img.ampwire1, img.ampwire2, img.protoA1, img.protoA3, img.protoA4, img.protoA5, img.protoA6, img.workingProto {
        width: 27em; 
      }
  }

  @media only screen and (device-height: 736px) and (device-width: 414px) { /* iphone 6+ */

    .takeMidA {
      left: 25.8%;
    }

    button.arrow3A {
      top: 171%;
    }

    div.featuresA img {
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }

    img.ampwire2, img.protoA1, img.protoA3, img.protoA4, img.protoA5, img.protoA6, img.workingProto {
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }

    div.ideationA img.ampwire1 {
      display: none;
      margin-left: auto;
      margin-right: auto;
    }

    img.tagImage2, img.noteImage2, img.ampwire1, img.ampwire2, img.protoA1, img.protoA3, img.protoA4, img.protoA5, img.protoA6, img.workingProto {
      width: 27em; 
    }

    #backTopA {
      left: 340px;
    }

    div.largeConc p, div.interviewA p, div.audioText p, div.tagText p, div.noteText p, div.ideationA p, div.reflectionA p {
      font-weight: 500;
    }

  }  
  
  @media (min-height: 810px) and (min-width: 370px) { /* iphone x */

    div.ideationA img.ampwire1 {
      display: none;
      margin-left: auto;
      margin-right: auto;
    }

    button.arrow7A {
      top: 895%;
    }

    #backTopA {
      left: 310px;
    }

    div.featuresA img {
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }

    img.ampwire2, img.protoA1, img.protoA3, img.protoA4, img.protoA5, img.protoA6, img.workingProto {
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }

    div.largeConc p, div.interviewA p, div.audioText p, div.tagText p, div.noteText p, div.ideationA p, div.reflectionA p {
      font-weight: 500;
    }

    img.tagImage2, img.noteImage2, img.ampwire1, img.ampwire2, img.protoA1, img.protoA3, img.protoA4, img.protoA5, img.protoA6, img.workingProto {
      width: 27em; 
    }

    .takeMidA {
      left: 23.4%; /* new */
    }


  }  

  @media only screen and (device-width: 375px) and (device-height: 812px) { /* iphone 11 pro */
    button.arrow7A {
      top: 895%;
    }

    div.largeConc p, div.interviewA p, div.audioText p, div.tagText p, div.noteText p, div.ideationA p, div.reflectionA p {
      font-weight: 500;
    }

    img.tagImage2, img.noteImage2, img.ampwire1, img.ampwire2, img.protoA1, img.protoA3, img.protoA4, img.protoA5, img.protoA6, img.workingProto {
      width: 27em; 
    }

    .takeMidA {
      left: 23.4%; /* new */
    }

  }  

  @media (min-width: 768px) { /* iPad */

    div.ampcontainer2 {
      margin-top: 0px; /* added for iPad */
      height: 73em;
      justify-content: center;
      padding-top: 0px;
    }

    img.ampPic, picture {
      margin-left: 0px;
      margin-top: 0px;
      padding-top: 0px;
      display: block;
    }

    img.ampPic1, img.ampPic2 {
      display: none;
    } 

    div.titleContent2 {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
    }

    img.ampPic {
      width: 31em;
      height: auto;
      margin-top: 2em;
    }

    div.titleAmp {
      display: flex;
      /*align-content: center;*/
      align-self: normal;
      margin-left: 0px;
      padding-left: 0px;
    }

    div.centered2 {
      /*margin-left: 20em;*/
      margin-right: 0px;
      margin-top: 5em;
      justify-content: normal;
      display: flex;
      flex-direction: column;
      margin-left: 4em;
    }

    div.centered2 h2 {
      text-align: left;
    }

    div.larger2 h5 {
      line-height: 23px !important;
    }

    button.arrow3A {
      top: 160%;
    }

    div.amproleFull div.largeConc {
      display: flex;
      flex-direction: column;
      padding-top: 1.5em;
    }

    div.amproleFull div.largeConc div {
      display: flex;
      flex-direction: row;
    }

    div.amproleFull div p {
      line-height: 36px;
      font-family: 'europa', sans-serif;
      font-size: 17px;
      width: 21em;
    }

    button.arrow4A {
      margin-top: 2em;
    }

    div.researchA {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
    }

    div.topTest {
      display: flex;
      flex-direction: row;
    }

    div.largeConc p, div.interviewA p, div.audioText p, div.tagText p, div.noteText p, div.ideationA p, div.reflectionA p {
      font-weight: 500;
    }

    div.interviewA p, div.audioText p, div.tagText p, div.noteText p, div.ideationA p, div.reflectionA p {
      font-family: 'europa', sans-serif;
    }

    div.topTest p {
      width: 22em;
    }

   div.interviewQuote {
      width: 18em;
      padding-top: 4em; /* added */
    }

    blockquote.b3 {
      line-height: 1.8;
      font-size: 1.6em;
      width: 15em;
    }
    
    blockquote.b3::before {
      top: -30px;
    }

    /* findings */

    div.findings1 h3 {
      width: 18em;
      line-height: 33px;  
    }

    div.findings2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 4em;
    }

    div.findings2 .circle1A {
      margin-right: 3em; /* 1.5em to 5 em */
      margin-left: 3em;
      margin-top: 0em;
      margin-bottom: 30px;
    }

    .takeMidA {
      left: 0%;
    }

    /* stuff */

    div.audioRec, div.audioNote {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    div.audioTag {
      flex-direction: row-reverse;
      display: flex;
      align-items: center;
    }

    div.audioText p {
      width: 19em;
      margin-right: 2em;
    }

    div.noteText p {
      width: 18em; 
      margin-right: 2em;
    }

    div.protoFirst, div.protoA, div.protoA2, div.superProto, div.finalProto, div.finalDesign {
      margin-bottom: 3em;
    }

    .protoText {
      margin-bottom: 3em;
    }

    div.tagText p {
      width: 19em;
      margin-right: 2em;
    }

    /*div.tagImage, div.audioVid, div.noteImage {
      flex-grow: 1;
    }*/

    div.featuresA, div.amproleFull, div.ideationA {
      padding-top: 2em;
      padding-bottom: 2em;
    }

    /* ideation */

    div.ideationA img.ampwire1 {
      display: none;
    }

    div.imgRow2 {
      display: flex;
      flex-direction: row;
    }

    img.protoA1 {
      width: 25em;
      height: auto;
      /*margin-left: 112px;*/
      margin-left: auto;
      margin-right: 0.5em;
    }

    img.protoA3 {
      margin-left: 0.5em;
      width: 25em;
      height: auto;
      margin-right: auto;
    }

    div.ideationA p {
      width: 25em;
    }

    div.reflectionA p {
      width: 25em;
    }

    div.reflectionA {
      padding-bottom: 2em;
      padding-top: 3em;
    }

    div.ampFooter {
      margin-top: 0px;
    }

  }


  @media (min-width: 1200px) {
    div.bgimg {
      height: 705px;
    }

    img.ampPic1, img.ampPic2 {
      display: none;
    } 

    .videoAmp {
      display: none;
    }
  
    .videoAmp1, .videoAmp1 video {
      display: block;
    }

    div.ideationA img.ampwire1 {
      display: block;
      margin-left: 0px;
      margin-right: 2em;
    }

    div.imgRow {
      justify-content: center;
    }

    div.imgRow2 {
      display: flex;
      flex-direction: row;
    }

    div.ideationA img.ampwire2 {
      margin-right: 0px;
      margin-left: 2em;
    }

    div.amplifyPage p {
      padding-left: 0px;
    }

    div.ampcontainer2 h1, div.amproleFull h1, div.featuresA h1, div.researchA h1, div.ideationA h1, div.prototypeA h1, div.reflectionA h1 {
      font-family: 'Lato', 'sans-serif';
      font-weight: 600;
      color: #333333;
      padding-left: 112px;
      display: block !important;
    }

    div.ampcontainer2 h2, div.amproleFull h2, div.featuresA h2, div.researchA h2, div.ideationA h2, div.prototypeA h2, div.reflectionA h2 {
      font-family: 'Lato', 'sans-serif';
      font-weight: 600;
      color: #333333;
      padding-left: 112px;
      display: block !important;
    }

     div.featuresA p, div.researchA p, div.ideationA p, div.prototypeA p, div.reflectionA p {
      font-family: 'europa', 'sans-serif';
      font-weight: 400;
      color: #333333;
      display: block !important;
      font-size: 18px;
      padding-left: 112px;
    }

    div.featuresA p {
      width: 42em;
      line-height: 35px;
    }

    div.reflectionA p {
      width: 40em;
      line-height: 32px;
    }

    div.container1 {
      /*margin-top: 1em;*/
      padding-bottom: 8em;
      display: flex;
      flex-direction: column;
      align-items: normal;
      padding-top: 0px;
      margin-top: 3em;
      justify-content: center;
    }

    img.ampPic {
      margin-left: 0px;
      display: block;
      height: 45em;
      width: auto;
      margin-top: 0px;
    }

    div.centered2 {
      /*margin-left: 20em;*/
      margin-right: 0px;
      margin-top: 12em;
      justify-content: normal;
      display: flex;
      flex-direction: column;
      margin-left: 14em;
    }

    div.titleAmp {
      display: flex;
      align-content: center;
      align-self: normal;
      margin-left: 0px;
      padding-left: 0px;
    }

    div.titleContent2 {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
    }

    .centered2 h1 {
      font-size: 40px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      color: #333333;
      padding-left: 0px;
      margin-left: 0px;
    }

    div.centered2 h2 {
      font-size: 20px !important;
      width: 22em;
      line-height: 40px;
      text-align: left;
      margin-top: 20px;
    }

    button.arrow2A {
      /*top: 95%;*/
      margin-top: 0px;
    }

    button.arrow3A {
      top: 203%;
    }

    button.arrow4A {
      top: 315%;
    }

    button.arrow6A {
      top: 455%;
    }

    /* next container */

    div.ampcontainer2 {
      /*padding-top: 19em;*/
      margin-top: 0px;
    }

    div.ampcontainer2 {
      height: 53em; /* might need to delete */
    }

    .ampcontainer2 div.larger2 {
      flex-direction: row !important;
      justify-content: center;
    }

    .amptimeline div, .ampaffil div {
      width: 12em;
      align-self: flex-start;
    }

    .amptimeline, .ampaffil {
      flex-direction: row !important;
      align-self: flex-start;
    }

    div.ampaff {
      margin-right: 5em;
    }

    /* thing */

    div.amproleFull {
      margin-top: 2em;
      padding-top: 5em;
      background-color: #f0f0f0;
      padding-bottom: 7em;
    }

    div.amproleFull div.largeConc {
      display: flex;
      flex-direction: column;
      padding-top: 1.5em;

      /* trying something */
      margin-left: auto;
      margin-right: auto;
    }

    div.amproleFull div.largeConc div {
      display: flex;
      flex-direction: row;
      padding-left: 112px;
    }

    div.amproleFull div p {
      line-height: 36px;
      font-family: 'europa', sans-serif;
      font-size: 17px;
      /*width: 32em;*/
      flex-basis: 32em;
    }

    div.amproleFull p.firstP {
      margin-right: 12em;
    }

    /* research */

    div.researchA {
      padding-top: 2em;
    }

    div.interviewA p {
      width: 33em;
      line-height: 33px;
    }

    div.topTest {
      display: flex;
      flex-direction: row;
    }

    div.topTest p {
      font-size: 18px;
      width: 35em;
      margin-right: 0px;
      padding-right: 0px;
      font-family: 'europa', 'sans-serif';
    }

    div.interviewA {
      flex-grow: 1;
    }

    div.interviewQuote {
      /*margin-top: 3em;*/
      padding-top: 4em;
      height: 38em; 
      flex-grow: 1;
      padding-right: 1em;
      width: auto;
    }

    blockquote.b3 {
      margin-left: 100px;
      font-family: 'europa', 'sans-serif';
      font-size: 1.7em;
      flex-grow: 1;
      width: 22em; 
      margin-top: 30px; 
      line-height: 1.8;
      padding-right: 30px;
      text-align: left;
    }

    blockquote.b3::before {
      left: 0px;
      top: -25px;
    }

    div.researchA li {
      font-size: 17.5px;
      width: 35em;
      margin-left: 112px;
      line-height: 30px;
      font-family: 'europa', 'sans-serif';
      margin-bottom: 15px;
      font-weight: 500;
    }

    div.researchA h2 {
      padding-bottom: 10px;
    }

    div.findings1 h2 {
      font-family: 'Lato', 'sans-serif';
      font-weight: 600;
    }

    /* findings */

    div.findingsA {
      display: flex;
      flex-direction: column;
      margin-top: 6em;
      padding-top: 3em;
      padding-bottom: 5em;
    }

    div.findings2 {
      margin-top: 4em;
    }

    /* circles */ 

    div.findings2 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
    }

    div.findings1 h3 {
      font-family: 'europa', 'sans-serif' !important;
      text-align: left !important;
      font-weight: 500 !important;
      font-size: 20px;
      width: 30em;
      line-height: 30px;
      color: #333333;
      padding-left: 112px;
    }

    div.findings2 .circle1A {
      opacity: 1;
      backface-visibility: visible;
      text-align: center;
      position: relative;
      margin-left: 5em;
      margin-top: 0em;
      margin-right: 1em;
      margin-bottom: 5em;
    }

    div.circle1A img {
      border-radius: 50%;
      width: 250px;
      height: 250px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }

    .takeTextA {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      width: 240px;
      margin-left: auto;
      margin-right: auto;
    }

    .takeMidA {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 250px;
      opacity: 0;
      transition: .5s ease;
      background-color: #f0f0f0;
      color: black;
      border-radius: 50%;
    }
    
    .circle1A:hover .takeMidA, .circle1A:focus .takeMidA {
      opacity: 0.97;
    }

    .takeTextA p {
      padding: 12px !important;
      margin: auto;
      line-height: 28px;
      width: 240px;
    }
    
    .takeTextA:hover, .takeTextA:focus, .takeTextA {
      color: black;
      text-decoration: none;
      opacity: 1;
      cursor: pointer;
    }

    button.arrow6A {
      top: 423%;
    }

    button.arrow7A {
      top: 525%;
    }

      /* features */

    div.featuresA {
      background-color: #f0f0f0;
      padding-top: 5em;
      display: block;
      padding-bottom: 4em;
    }

    div.featuresA h1 {
      padding-bottom: 0.5em;
    }

    div.audioRec, div.audioNote {
      display: flex;
      flex-direction: row;
      align-items: normal;
    }

    div.audioTag {
      flex-direction: row-reverse;
      display: flex;
      align-items: normal;
    }

    div.audioTag, div.audioNote {
      margin-bottom: 1em;
    }

    div.audioNote {
      padding-bottom: 3em;
    }

    div.audioText, div.tagText, div.noteText {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    div.audioText p, div.tagText p, div.noteText p {
      width: 34em;
      line-height: 38px;
      margin-right: 0px; /* added */
    }

    div.audioText h2, div.tagText h2, div.noteText h2 {
      padding-bottom: 0.5em;
    }

    div.audioVid {
      margin-left: 2em;
      flex-grow: 1;
      margin-top: -1em;
    }

    img.tagImage, img.noteImage {
      flex-grow: 1;
    }

    img.tagImage2 {
      width: 650px;
      height: auto;
      margin-left: 112px;
      margin-top: 4em;
     /* margin-right: 4em;
      margin-top: 7em;*/
    }

    img.noteImage2 {
      width: 650px;
      height: auto;
      margin-right: 8em;
      margin-top: 4em;
    }

    button.arrow8A {
      top: 762%;
    }

    /* ideation */

    /* wireframes */

    img.ampwire1, img.ampwire2 {
      height: 300px;
      width: auto;
    }

    img.ampwire1 {
      margin-left: 8em;
      margin-right: 3em;
      margin-bottom: 2em;
    }

    div.imgRow {
      display: flex;
      flex-direction: row;
    }

    div.ideationA {
      padding-top: 3em;
      padding-bottom: 4em;
    }

    div.ideationA p {
      line-height: 35px;
      width: 30em;
      margin-bottom: 2em;
    }

    div.protoA2, div.protoFirst {
      display: flex;
      flex-direction: column;
      align-items: normal;
    }

    div.protoFirst, div.protoA, div.protoA2, div.superProto, div.finalProto, div.finalDesign {
      margin-bottom: 0em;
    }

    .protoText {
      margin-bottom: 0em;
    }

    div.protoA2 img, img.protoA5, img.protoA6 {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      width: 750px;
      height: auto;
    }

    /* prototype */

    /*img.protoA4, img.protoA5, img.protoA6 {
      width: 750px; 
      height: auto;
      margin-left: 112px;
    }*/

    img.protoA1 {
      width: 650px;
      height: auto;
      /*margin-left: 112px;*/
      margin-left: auto;
      margin-right: 1em;
    }

    img.protoA3 {
      margin-left: 1em;
      width: 650px;
      height: auto;
      margin-right: auto;
    }

    img.workingProto {
      width: 900px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }

    img.protoA1, img.protoA5, img.protoA6, img.workingProto {
      margin-bottom: 2em;
    }

    div.ideationA h2 {
      margin-bottom: 0.5em;
      margin-top: 2em;
    }

    div.ideationA h2.wireA {
      margin-top: 1em !important;
    }

    div.protoA {
      display: flex;
      flex-direction: column;
      /*padding-top: 3em;*/
    }

    div.featuresA img, div.ideationA img {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }

    button.arrow5A {
      top: 1532%;
    }

    /* reflection */
    
    div.reflectionA {
      background-color: #f0f0f0;
      padding-top: 4em;
      padding-bottom: 3em;
    }

    div.reflectionA h1 {
      padding-bottom: 0.5em;
    }

    div.featuresA img, div.ideationA img {
      display: block;
    }

    /* footer */

    div.ampFooter {
      height: 9em;
      margin-top: 0em;
      display: block;
    }

    div.email3 {
      font-weight: 500;
      font-size: 20px;
      text-align: right;
    }

    div.email3 a {
      text-decoration: none;
    }

    div.email3 a:hover, div.email3 a:focus {
      text-decoration: none;
      opacity: 0.5;
    }

    div.plink2 {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding-top: 4.5em;
      margin-bottom: 0px;
    }

    div.nextP2 {
      font-family: 'europa', sans-serif;
      margin-bottom: 1em;
      font-size: 16px;
    }
    
    div.pName2 {
      font-size: 32px;
      font-family: 'Castoro', sans-serif;
      color: #333333;
      margin-bottom: 0px;
    }

    div.pName2 a {
      color: #333333;
      text-decoration: none;
      padding-left: 15px;
      opacity: 1;
    }

    img.footLink2 {
      padding-left: 2px;
    }

    div.pName2 a:hover, img.footLink2:hover, div.pName2 a:focus, img.footLink2:focus {
      text-decoration: none;
      opacity: 0.8;
    }

    div.bottomLinks2 {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      background-color: #B6C5D6;
      width: 100%;
      height: 6.5em;
    }

    div.email3 {
      margin-right: 95px;
    }

    div.email3, div.backTop3 {
      padding-bottom: 5px;
      flex-grow: 1;
    }

    img.backTop2 {
      width: 80px;
      height: 80px;
      margin-bottom: 0px;
      background-color: transparent;
    }  

    div.credit3 {
      text-align: center !important;
      padding-top: 10px;
      align-self: flex-end;
      padding-bottom: 5px;
      display: block;
      padding-left: 0px;
      padding-right: 0px;
      width: auto;
    }

    div.credit3 p {
      margin-bottom: 0px;
      width: auto;
    }

    img.backTop2:hover, img.backTop2:focus {
      opacity: 0.8;
    }

    #backTopA {
      background-color: #B6C5D6;
      border: none;
      width: 80px;
      height: 80px;
      outline: none;
      position: static;
      bottom: 41px;
      margin-left: 95px;
      display: block;
      z-index: 99;
    }

    div.backTop3 {
      background-color: transparent;
      outline: none;
      display: flex;
      position: static;
      flex-direction: column;
      justify-content: flex-end;
    }

  }

  @media (min-width: 2440px) {
      
    div.amproleFull div.largeConc div {
      padding-left: 0px;
      justify-content: center;
    }

    div.amproleFull div p {
      line-height: 40px;
      font-size: 20px;
      flex-basis: 40em;
    }

    button.arrow3A {
      top: 180%;
    }
  }
  
  