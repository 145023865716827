html {
    overflow: scroll;
}


div.aboutfull {
    background-color: #f0f0f0;
    height: 100%;
    /*padding-bottom: 5em; */
}

div.about {
    background-color: #f0f0f0;
}

body {
    border: transparent;
}


div.text, div.text:hover, div.text:focus, div.text:active {
    cursor: pointer;
}

div.bodyText {
    /*margin-top: 2em; */
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    flex-basis: 8em; /*30em */
    line-height: 30px;
}

div.bodyText h1 img {
    width: 20px;
    height: 20px;
}

div.location h1 {
    font-size: 19px;
    margin-left: 5px;
    font-family: 'europa', 'sans-serif';
    font-weight: 600;
}

h1.myName {
    font-family: 'Lato', 'sans-serif';
    font-weight: 600;
}

div.location {
    display: flex;
    flex-direction: row;
    margin-left: 2.3em;
}

div.bodyText h3 {
    line-height: 25px;
}

div.bodyText p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
    text-align: left;
    margin-left: 2em;
    margin-right: 2em;
    font-family: 'europa', 'sans-serif';
}

div.bodyText p.one {
    margin-top: 5px;
}

h1.myName {
    font-size: 20px;
    /*margin-left: 20px;*/
    margin-left: 2em;
}

div.bodyText p a {
    /*color: #4B449B;*/
    color: #947CBB;
    text-decoration: none;
}

main.main img.selfie, main.main picture {
    height: 250px;
    width: auto;
    /*margin-top: 1em; */
    margin-top: 3em;
    flex-basis: 250px;
    display: flex;
    margin-left: 1em;
    margin-right: 1em;
}

  main.main {
    display: flex;
    flex-direction: column;
    font-family: 'futura-pt', sans-serif;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    background-color: #f0f0f0;
    height: 100%;
  }

  div.credit5 {
    background-color: #f0f0f0;
    margin-bottom: 5px;
    bottom: 0%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 190px;
    line-height: 25px;
    margin-top: 2.5em;
  }
  
  div.credit6 {
      background-color: white;
      margin-bottom: 5px;
      bottom: 0%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      width: 190px;
      line-height: 25px;
      margin-top: 2.5em;
  }
  
  div.credit5 p, div.credit6 p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-family: 'europa', 'sans-serif';
    font-weight: 500;
  }  

/* photos page */

div.photos {
    margin-top: 5em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

div.photos img {
    width: 250px;
    height: auto;
    /*margin-left: 2em;
    margin-right: 2em;*/
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-top: 1em;
}

/*div.img1, div.img2, div.img3 {
    margin-left: auto;
    margin-right: auto;
}*/

div.img1, div.image2, div.image3, div.image4, div.img2, div.cabo, div.all, div.cabo2, div.vie, div.img3, div.vien, div.vienn {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: hidden;
    position: relative;
    /*margin-left: auto;
    margin-right: auto;*/
}

.middle {
    transition: .5s ease;
    opacity: 0.8;
    position: absolute;
    top: 79%;
    left: 79.5%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

div.cabo2 .middle {
    top: 80%;
    left: 69%;
    width: 155px;
}

 div.cabo2 .text {
    padding: 14px 20px;
}

div.img1:hover .image, div.image2:hover .image, div.image3:hover .image, div.image4:hover .image, 
div.img2:hover .image, div.cabo:hover .image, div.all:hover .image,
div.cabo2:hover .image, div.vie:hover .image, div.img3:hover .image, div.vien:hover .image, div.vienn:hover .image {
    opacity: 0.3;
}

div.img1:focus .image, div.image2:focus .image, div.image3:focus .image, div.image4:focus .image, 
div.img2:focus .image, div.cabo:focus .image, div.all:focus .image,
div.cabo2:focus .image, div.vie:focus .image, div.img3:focus .image, div.vien:focus .image, div.vienn:focus .image {
    opacity: 0.3;
}


  
/*div.img1:hover .middle, div.image2:hover .middle, .image3:hover .middle, div.image4:hover .middle, div.img2:hover .middle, div.cabo:hover .middle, div.all:hover .middle,
div.cabo2:hover .middle, div.vie:hover .middle, div.img3:hover .middle, div.vien:hover .middle, div.vienn:hover .middle {
    opacity: 1;
}*/

div.photos .image:hover .middle, div.photos .image:focus .middle {
    opacity: 1;
}

.text {
    background-color: #BD79DD;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
  }

  @media (min-width: 375px) /* iPhone ?? */ {
  
    div.credit5, div.credit6 {
      width: auto;
      line-height: auto;
      margin-top: 2.5em;
    }

    main.main img.selfie, main.main picture {
        height: auto;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    div.photos .middle {
        top: 82%;
        left: 82.5%;
    }

    div.image3 .middle, div.img3 .middle, div.vie .middle, div.vien .middle, div.vienn .middle {
        left: 83%;
    }

    div.vienn .middle {
        top: 82.7%;
    }

    div.cabo .middle, div.img1 .middle, div.image2 .middle, div.vie .middle, div.vien .middle {
        top: 82.5%;
    }

    div.cabo2 .middle {
        left: 69.5%;
        top: 86%;
    }

    div.photos {
        margin-bottom: 2em;
    }
  }

  @media (min-width: 410px) {
    div.bodyText {
        margin-left: 1.5em;
        margin-top: 5em;
    }

    main.main img.selfie, main.main picture {
        margin-top: 3em;
    }
  }

  @media (min-width: 598px) {

  }

  @media only screen and (device-width: 375px) and (device-height: 812px) { /* iphone 11 pro */ 

    div.credit5 {
        width: 300px;
        line-height: auto;
        margin-top: 46em;
        bottom: 0%;
        display: block;
      }

      div.bodyText {
          margin-top: 2em;
      }

      div.credit6 {
        width: auto;
        line-height: auto;
        margin-top: 2.5em;
        bottom: 0%;
        display: block;
      }

      h1.myName {
          font-size: 23px;
          font-weight: 700;
          margin-left: 36px;
      }

      div.bodyText p {
        margin-left: 2em;
      }
  
      main.main img.selfie, main.main picture {
          height: auto;
          width: 300px;
          margin-left: auto;
          margin-right: auto;
      }

      div.aboutfull {
        height: 100%;
        background-color: #f0f0f0;
        padding-bottom: 2em;
      }

      /*div.main, div.aboutfull {
          display: flex;
          flex-direction: column;
          background-color: #f0f0f0;
      }*/

      div.bodyText p {
          background-color: #f0f0f0;
      }
  
      div.photos .middle {
          top: 82%;
          left: 82.5%;
      }
  
      div.image3 .middle, div.img3 .middle, div.vie .middle, div.vien .middle, div.vienn .middle {
          left: 83%;
      }
  
      div.vienn .middle {
          top: 82.7%;
      }
  
      div.cabo .middle, div.img1 .middle, div.image2 .middle, div.vie .middle, div.vien .middle {
          top: 82.5%;
      }
  
      div.cabo2 .middle {
          left: 69.5%;
          top: 86%;
      }
  
      div.photos {
          margin-bottom: 2em;
      }

  }  

  @media (min-width: 768px)  { /* iPad */

    main.main {
        align-items: center;
        justify-content: center;
    }

    main.main img.selfie, main.main picture {
        padding-top: 1em;
    }

    div.bodyText p {
        margin-left: 1em;
        margin-right: 1em;
        font-size: 20px;
    }

    div.location {
        display: none;
    }
    
    div.bodyText {
        width: 400px;
    }

    div.aboutFull {
        height: 100%;
        background-color: #f0f0f0;
    }

    div.credit5, div.credit6 {
        padding-top: 7.5em;
        margin-top: 40em;
    }

    h1.myName {
        margin-left: 20px;
        font-size: 22px;
    }

    /* photos page */

    div.photos img {
        width: 300px;
        height: auto;
        /*margin-left: 1em;
        margin-right: 1em;*/
        margin-right: 1em;
    }

    /*div.vie, div.image2, div.image4 {
        margin-right: 0.5em;
    }*/

    div.photos {
        justify-content: center;
    }

   /* div.img1, div.img2, div.img3 {
        margin-left: 7em;
    }*/

    div.middle {
        top: 50%;
        left: 50%;
        opacity: 0.9;
    }

    div.cabo2 .middle {
        width: 155px;
    }

    div.image3 .middle, div.img3 .middle, div.vie .middle, div.vien .middle, div.vienn .middle, div.cabo .middle, 
    div.img1 .middle, div.image2 .middle, div.image4 .middle, div.img2 .middle, div.all .middle, div.cabo2 .middle {
        left: 50%;
        top: 50%;
    }
    
  }

  @media (min-width: 1020px) and (max-width: 1030px) { /* iPad pro */
    div.bodyText {
        flex-basis: 30em;
    }

    div.aboutFull {
        height: 100%;
    }

    main.main {
        flex-direction: row;
        margin-top: 15em;
    }

    main.main picture {
        padding-top: 0px;
        margin-top: 0px;
    }

    div.credit5, div.credit6 {
       /* padding-top: 25em;*/
        margin-top: 40em;
    }
  }


  @media (min-width: 1200px) {
    main.main {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    div.picture {
        padding-top: 0px;
    }

    div.bodyText img.loc {
        width: 40px;
        height: 40px;
        /*margin-left: 20px;*/
        padding-top: 8px;
        margin-top: 4px;
    }
    
    div.bodyText {
        width: auto;
    }

    div.bodyText h1 {
        font-weight: 600;
    }

    div.location h1 {
        margin-left: 0px;
        font-size: 21px;
        padding-bottom: 5px;
    }

    div.location {
        margin-left: 15px;
        display: flex;
        flex-direction: row;
    }

    img.locArr {
        width: 26px;
        margin-top: 9px;
    }

    main.main img.selfie, main.main picture {
        margin-right: 10em;
        padding-top: 0px;
        width: 400px;
        height: auto;
    }

    main.main picture {
        margin-left: 0px;
        margin-top: 12em;
    }

    div.bodyText {
        margin-top: 7em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 37em;
        line-height: 30px;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    div.bodyText h3 {
        line-height: 30px;
    }
    
    div.bodyText p {
        font-size: 20px;
    }

    h1.myName {
        font-size: 25px;
        margin-left: 20px;
        text-align: left;
    }

    div.credit5, div.credit6 {
        padding-top: 0px;
        margin-top: 40px;
        padding-bottom: 0px;
        display: block;
    }

    /* photos stuff */

   /* div.photos img {
        margin-right: 0.5em;
        margin-left: 0px;
    }*/

    div.photos .text {
        padding: 16px 28px;
    }

    div.photos .middle {
        top: 50%;
        left: 50%;
        opacity: 0;
    }

    div.img1:hover .middle, div.image2:hover .middle, .image3:hover .middle, div.image4:hover .middle, div.img2:hover .middle, div.cabo:hover .middle, div.all:hover .middle,
        div.cabo2:hover .middle, div.vie:hover .middle, div.img3:hover .middle, div.vien:hover .middle, div.vienn:hover .middle {
        opacity: 1;
    } 

    div.img1:focus .middle, div.image2:focus .middle, .image3:focus .middle, div.image4:focus .middle, div.img2:focus .middle, div.cabo:focus .middle, div.all:focus .middle,
        div.cabo2:focus .middle, div.vie:focus .middle, div.img3:focus .middle, div.vien:focus .middle, div.vienn:focus .middle {
        opacity: 1;
    } 
    
    

    div.cabo2 .text {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 22px;
        padding-right: 22px;
    }

  }