
* {
    box-sizing: border-box;
}


h3 {
    color: black;
    font-size: 23px;
    font-family: 'futura-pt', sans-serif;
    font-weight: 500;
}

h3.nSol, h3.nAmp, h3.nSol2, h3.nDem {
    font-family: 'futura-pt', sans-serif;
    text-align: left;
    font-weight: 600;
    /*font-size: 25px !important;*/
    color: black;
    position: relative;
    z-index: 0;
    text-align: center;
}

h3.n:hover, h3.n:focus {
    font-style: italic;
    font-weight: 600;
}

h3.nSol:before {
    content:'';
    position: absolute;
    width: 96px;
    top: 21px;
    height: 7px;
    left: -2px;
    z-index: -1;
    background-color: #C1B0FB;
}

h3.nAmp:before {
    content:'';
    position: absolute;
    width: 110px;
    top: 21px;
    height: 7px;
    left: -2px;
    z-index: -1;
    background-color: #B4BBFA;
}

h3.nSol2:before {
    content:'';
    position: absolute;
    width: 105px;
    top: 21px;
    height: 7px;
    left: -2px;
    z-index: -1;
    background-color: #B3B8DF;
}

h3.nDem:before {
    content:'';
    position: absolute;
    width: 110px;
    top: 21px;
    height: 7px;
    left: -2px;
    z-index: -1;
    background-color: #B1BFD4;
}

h3 a {
    color: black;
}

h4.def, h4.def2, h4.def3 {
    font-family: 'futura-pt', sans-serif;
    font-weight: 400;
    line-height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
}

h4.def3 {
    margin-bottom: 5px;
}

h3.title {
    font-weight: 300;
    margin-top: 0;
    text-align: center;
}

div.project, div.amplify, div.solstice, div.democratli {
    display: flex;
    flex-direction: row;
}

div.project, div.amplify, div.solstice, div.democratli {
    margin-top: 3em;
}

div.project {
    /*margin-top: 5em;*/
    margin-top: 150px;
}

div.solace2 {
    padding-left: 1.5em;
    padding-right: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-basis: 440px;
    width: 550px;
    margin-left: 6em;
    margin-right: 4em;
    margin-top: 5em;
    text-align: left;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

div.solace, div.amplify2, div.solstice2, div.demo2 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    position: relative;
    background-color: white;
}

div.solace .middleHome, div.amplify2 .middleHome, div.demo2 .middleHome {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.middleHome {
    background-color: transparent;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-top: 0px;
    border-radius: 10px;
    width: 15em;
}

div.solstice2 .middleHome {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.textHome a:hover, .textHome a, .textHome a:focus {
    color: black;
    text-decoration: none;
}

div.solace .middleHome, div.amplify2 .middleHome, div.solstice2 .middleHome, div.demo2 .middleHome {
    opacity: 1;
}

div.solace img.solaceImage, div.amplify2 img.amplifyImage, div.solstice2 img.solsticeImage, div.demo2 img.demoImage {
    opacity: 0.3;
}

div.high a:hover, div.high a:focus {
    color: black;
    text-decoration: none;
}

.nAmp a:hover, .nSol2 a:hover, .nSol a:hover, .nAmp a:focus, .nSol2 a:focus, .nSol a:focus, .nDem a:focus {
    opacity: 0.8;
}
  
div.textHome {
    color: #333333;
    font-size: 30px;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 500;
    font-family: 'futura-pt', sans-serif;
  }

  div.subText, div.subText2 {
    font-weight: 400;
    font-size: 16px;
  }

  div.subText {
    margin-top: 0px;
    padding-top: 0px;
  }

div.carousel {
    /*width: 20em; *//*50em */
    width: 20em;
    border-radius: 10px;
    text-decoration: none;
    /*margin-right: 2em;
    margin-left: 3em;*/
    margin-left: auto;
    margin-right: auto;
}

.carousel-control.left, .carousel-control.right {
    background-image:none !important;
    filter:none !important;
 }

div.solace, div.amplify2, div.solstice2, div.demo2 {
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    width: 20em; /* 50em */
    height: 12.8em; /* 32em */
    margin: 0 auto;
}

div.amplify a, div.democratli a {
    text-decoration: none;
}

div.item img {
    width: 20em; /* 100% */
    height: 12.8em; /* used to be not present */
    border-radius: 10px;
}

div.item {
    border-radius: 10px;
}

div.definition {
    display: none;
}

@media (min-width: 275px) and (max-width: 282px) {
    div.item img, div.carousel, div.solace, div.amplify2, div.solstice2, div.demo2 {
        width: 16em; /* 100% */
        height: auto;
    }

    div.solace, div.amplify2, div.solstice2, div.demo2 {
        border-radius: 10px;
    }
  
}

@media (min-width: 410px) and (max-width: 415px) { /* iphone 6+  */ 
    div.item img, div.carousel, div.solace, div.amplify2, div.solstice2, div.demo2 {
        width: 22em; /* 100% */
        height: auto;
    }

    div.solace, div.amplify2, div.solstice2, div.demo2 {
        border-radius: 10px;
    }
}    

@media (min-height: 730px) and (max-height: 740px) and (min-width: 410px) and (max-width: 420px) { /* iphone 6/7/8 plus*/

}

@media (min-width: 530px) and (max-width: 545px) { /* surface pro */

    div.item img, div.carousel, div.solace, div.amplify2, div.solstice2, div.demo2 {
        width: 28em; /* 100% */
        height: auto;
    }

    div.solace .middleHome, div.solstice2 .middleHome {
        box-shadow: 4px 4px 8px 4px rgba(0,0,0,0.2);
        top: 50%;
    }

    div.amplify2 .middleHome, div.demo2 .middleHome {
        top: 45%;
        box-shadow: 4px 4px 8px 4px rgba(0,0,0,0.2);
    }

    div.solace, div.amplify2, div.solstice2, div.demo2 {
        border-radius: 10px;
    }

}

@media (min-height: 730px) and (min-width: 370px) { /* iphone x */ 

    div.project {
        margin-top: 190px;
    }

    div.solace, div.amplify2, div.solstice2, div.carousel, div.item img, div.demo2 {
        border-radius: 10px;
    }

}   

@media only screen and (device-width: 375px) and (device-height: 812px) { /* iphone 11 pro */

    div.project {
        margin-top: 190px;
    }

    div.solace, div.amplify2, div.solstice2, div.carousel, div.item img, div.demo2 {
        border-radius: 10px;
    }


}   

@media (min-width: 768px) { /* iPAD */

    /* fix text sizing, margin left on intro, and size of all definitions for this size & iPad pro while preserving 1200+ */

    div.item img, div.carousel, div.solace, div.amplify2, div.solstice2, div.demo2 {
        width: 25em; /* 100% */
        height: auto;
    }

    div.definition {
        display: block;
    }

    h3.nSol, h3.nAmp, h3.nSol2, h3.nDem {
        font-weight: 500;
        /*font-size: 25px !important;*/
        margin-top: 10px;
        font-size: 20px;
        text-align: left;
    }

    h3.nSol:before {
        width: 75px;
        top: 17px;
        height: 5px;
    }

    h3.nAmp:before {
        width: 80px;
        top: 17px;
        height: 5px;
    }

    h3.nSol2:before {
        width: 80px;
        top: 17px;
        height: 5px;
    }

    h3.nDem:before {
        width: 85px;
        top: 17px;
        height: 5px;
    }

    div.solace2 {
        padding-left: 1em; 
        padding-bottom: 0px;
        width: 300px; /* added */
        padding-top: 0px;
        padding-right: 20px;
        margin-left: 3em;
        margin-right: 0px; 
        margin-top: 25px;
    }

    h4.def, h4.def2, h4.def3 {
        line-height: 20px;
        font-size: 13px;
        margin-bottom: 2px;
        margin-top: 2px;
    }

    h5 {
        font-size: 11px;
    }

    div.project {
        /*margin-top: 5em;*/
        margin-top: 0px;
    }

    div.solace:hover img.solaceImage, div.amplify2:hover img.amplifyImage, div.solstice2:hover .solsticeImage, div.demo2:hover img.demoImage {
        opacity: 0.2;
    }

    div.solace:focus img.solaceImage, div.amplify2:focus img.amplifyImage, div.solstice2:focus .solsticeImage, div.demo2:focus img.demoImage {
        opacity: 0.2;
    }
      
    div.solace:hover .middleHome, div.amplify2:hover .middleHome, div.solstice2:hover .middleHome, div.solace:focus .middleHome, div.amplify2:focus .middleHome, div.solstice2:focus .middleHome, div.solace:active .middleHome, div.amplify2:active .middleHome, div.solstice2:active .middleHome {
        opacity: 1;
        background-color: transparent;
    }

    div.demo2:hover .middleHome, div.demo2:focus .middleHome, div.demo2:active .middleHome {
        opacity: 1;
        background-color: transparent;
    }

    div.solace .middleHome, div.amplify2 .middleHome, div.solstice2 .middleHome, div.demo2 .middleHome {
        opacity: 0;
    }

    div.solace img.solaceImage, div.amplify2 img.amplifyImage, div.solstice2 img.solsticeImage, div.demo2 img.demoImage {
        opacity: 1;
    }
}

@media (min-width: 992px) {
    div.project {
        margin-top: 7em;
        padding-top: 7em;
    }
}

@media (min-width: 1020px) { /* iPad pro */

    /* fill this */

    h4.def, h4.def2, h4.def3 {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    div.solace2 {
        width: 400px;
        padding-left: 1.5em;
        padding-right: 1em;
        padding-top: 1em;
        padding-bottom: 1em;
        margin-left: 4em;
        margin-right: 3em;
    }

    div.carousel {
        margin-left: 0px;
        margin-right: 0px;
    }

    div.item img, div.carousel, div.solace, div.amplify2, div.solstice2, div.demo2 {
        width: 35em; /* 100% */
        height: auto;
    }

    h3.nSol, h3.nAmp, h3.nSol2, h3.nDem {
        font-size: 25px;
        font-weight: 600;
        text-align: left;
    }

    h3.nSol:before {
        width: 96px;
        top: 21px;
        height: 7px;
    }

    h3.nAmp:before {
        width: 110px;
        top: 21px;
        height: 7px;
    }

    h3.nSol2:before {
        width: 105px;
        top: 21px;
        height: 7px;
    }

    h3.nDem:before {
        width: 140px;
        top: 21px;
        height: 7px;
    }

    h5 {
        font-size: 14px;
    }


}

@media (min-width: 1200px) {

    div.item img {
        width: 100%;
    }

    div.project {
        /*margin-top: 5em;*/
        margin-top: 190px;
    }

    div.solace, div.amplify2, div.solstice2, div.item img, div.demo2 {
        width: 43em;
        height: auto; 
    }

    div.carousel {
        width: 43em;
        height: auto;
        margin-right: 2em;
        margin-left: 3em;
    }

    div.solace2 {
        padding-left: 1.5em;
        padding-top: 1em;
        padding-bottom: 1em;
        flex-basis: 440px;
        width: 550px;
        margin-left: 10.5em;
        padding-right: 1em;
        margin-right: 5em;
        margin-top: 5em;
    }

    h3.nSol, h3.nAmp, h3.nSol2, h3.nDem {
        text-align: left;
    }

    div.textHome {
        color: #333333;
        font-size: 35px;
        padding-top: 10px;
        padding-left: 16px;
        padding-right: 16px;
        font-weight: 500;
        font-family: 'futura-pt', sans-serif;
      }

      div.middleHome {
          width: 15em;
      }
    
      div.subText, div.subText2 {
        font-weight: 400;
        font-size: 20px;
      }
    
      div.subText {
        margin-top: 0px;
        padding-top: 0px;
      }

}
