  html {
    scroll-behavior: smooth !important;
  }

  /* smallest screens first (iphone SE below) */  
    
  img.solace {
    border-radius: 10px;
    width: 50px;
    margin-top: 1em;
    margin-left: 0px;
  }

  div.container2 {
    width: 100%;
    /*padding-top: 6em;*/ /* margin top 1em */
    /*align-items: center;*/ /* added */
    padding-top: 10em; /* added */
    padding-bottom: 15em; /* 10em */
    display: flex;
    /*flex-direction: row;*/
    flex-direction: column;
    justify-content: center; /* added */
    background-color: #f0f0f0;
  }

  img.solaceH {
    width: 10em;
    height: auto;
    margin: 0;
    display: none; /* hidden at smallest size */
  }

  img.solaceHome, img.solaceHome2 { /* display none for iPhone 5 */
    display: none;
  }

  div.container2 h2 {
    font-family: 'europa', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px; /* 18px */
  }

  div.centered {
    display: flex;
    flex-direction: column;
   /* margin-left: auto;
    margin-right: auto;*/
  }

  .centered h1 {
    font-size: 35px; /*40px */
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #333333;
  }

  div.titleSol img.logo {
    width: 50px; /*60px */
    height: 50px; /* auto? */
    margin-left: 5px;
    margin-right: 15px;
    /*margin-top: 11px; 
    padding-top: 4px;*/
    margin-top: 13px;
    padding-top: 4px;
    padding-bottom: 2px;
  }

  div.titleSol img.test {
    width: 80px; /*130px */
    margin-top: 26px;
    display: none;
  }

  .centered h2 {
    margin-top: 10px;
    width: 17em; /* 20em or 19em */
    line-height: 32px; /*29px*/
    align-self: center;
    text-align: center;
  }

  div.titleSol {
    display: flex;
    flex-direction: row;
    align-self: center;
    /* going to add */
    padding-left: 10px;
  }

  button.arrow2 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute; 
    top: 90%;
    left: 50%;*/
    position: static;
    margin-left: 50%;
    margin-top: 2em;
    width: 48px;
    /*width: auto;*/
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  button.arrow2:hover, button.arrow2:focus, button.arrow2:active {
    cursor: pointer !important;
  }
  
  .middlethree, div.middle4 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
  }
  
  .text3 a:hover, .text3 a, .text3, .text3 a:focus, .text3 a:active {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
    font-family: 'futura-pt', 'sans-serif';
    font-size: 16px;
  }

  
  button.arrow2:hover .arr2, button.arrow2:focus .arr2, button.arrow2:active .arr2 {
    opacity: 0;
  }
  
  button.arrow2:hover .middlethree, button.arrow2:focus .middlethree, button.arrow2:active .middlethree {
    opacity: 1;
  }
  
  .text3 {
    color: black;
    font-size: 16px !important;
    font-family: 'futura-pt', 'sans-serif';
    padding: 5px;
    width: 5em;
    font-weight: 400;
  }

  /* second container (role/etc) */

  div.container3 {
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    height: 48em;
    padding-top: 2em; /* 5em */
    margin-top: 0px;    
    display: flex;
    flex-direction: column;
  }

  div.container3 div.larger {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  div.container3 .timeline, div.container3 .affil {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .timeline div, .affil div {
    width: 10em;
    margin-bottom: 2em;
  }

  div.aff {
    text-align: center;
  }

  div.role {
    margin-left: 5em;
    margin-right: 5em;
  }

  div.container3 div h4 {
    font-size: 20px;
    margin-bottom: 1em;
  }
  
  div.container3 h4 {
    font-weight: 600 !important;
    font-family: 'futura-pt', sans-serif;
  }

  div.container3 div h5 {
    font-size: 17px;
    text-align: center;
  }

  /* arrow */

  button.arrow3 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    position: absolute;
    top: 220%;
    left: 50%;
    /*position: static;
    margin-left: 50%;
    width: 60px;*/
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent;
    border: none;
  }

  .textfour a:hover, .textfour a, .textfour, .textfour a:active, .textfour a:focus {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
    font-family: 'futura-pt', 'sans-serif';
    font-size: 16px;
  }
    
  button.arrow3:hover .arr3, button.arrow3:focus .arr3, button.arrow3:active .arr3 {
    opacity: 0;
  }
    
  button.arrow3:hover .middle4, button.arrow3:focus .middle4, button.arrow3:active .middle4 {
    opacity: 1;
  }

  div.textfour {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 6em;
    font-weight: 400;
    font-family: 'futura-pt', 'sans-serif';
  }

  /* container 4 */

  div.container4 {
    background-color: #f0f0f0;
    /*height: 50em;*/
    height: 104em;
    /* might need to add height 107em */
    margin-top: 0px;
    padding-top: 0px;
    font-family: 'europa', sans-serif;
    /*display: flex;
    flex-direction: column;*/
    /*align-content: center;*/
  }

  div.container4 .textSide {
    flex-grow: 1;
    padding-left: 3em; /* changed from margin-left 8em; */
    padding-top: 40px;
    background-color: #f0f0f0; /* added for mobile */
    margin-right: 0;
    padding-right: 0;
    padding-bottom: 5em; /* added */
  }

  .textSide p {
    margin-bottom: 0.7em;
    width: 16em; /* 17em */
    font-size: 17px;
    line-height: 24px; 
    margin-right: 0;
    padding-right: 0;
  }

  .textSide h1 { /* might just be for iPhone 6, check on other screens !!!1 */
    margin-left: 0px;
  }

  /*button {
    padding: 5px;
    font-family: 'San Francisco', sans-serif;
  }*/

  video {
    border: none;
  }

  div.interview {
    display: flex;
    flex-direction: column;
    margin-top: 5em; /* added */
  }

  .textSide p b {
    color: #873CB5;
  }

  div.container4 h1, div.container5 h1 {
    font-size: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #333333;
    margin-bottom: 0.7em;
  }

  div.quote {
    margin-top: 3em;
    /*padding-top: 4em;*/
    height: auto; /* might just be for iphone 6 !!! */
    flex-grow: 1;
    padding-right: 1em;
    display: flex; /* added */
    /* might need to take away height, add padding bottom three */
  }

  blockquote.b1 {
    font-size: 1.4em;
    width: 17em; /* 25em */
    margin: 30px auto;
    margin-left: 0;
    font-family: 'europa', sans-serif;
    color: #555555;
    padding: 1.2em 30px 1.2em 75px;
    border-right: 8px solid #78C0A8;
    line-height: 1.6;
    position: relative;
    border-left: none;
  }

  div.quote a {
    color: #333333;
  }
  
  blockquote.b1::before {
    font-family: Arial;
    content: "\201C";
    color:#78C0A8;
    font-size: 4em;
    position: absolute;
    left: 25px; /* 10px */
    top: -10px;
  }
  
  blockquote.b1::after {
    content: '';
  }
  
  blockquote.b1 span{
    display: block;
    color: #333333;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
  } 

  /* next arrow */

  button.arrow4 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 347%; 
    left: 50%; */
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }

  div.middle5 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .text5 a:hover, .text5 a, .text5, .text5 a:focus, .text5 a:active {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
    font-family: 'futura-pt', sans-serif;
  }

  button.arrow4:hover .arr4, button.arrow4:active .arr4, button.arrow4.focus .arr4 {
    opacity: 0;
  }

  button.arrow4:hover .middle5, button.arrow4:active .middle5, button.arrow4:focus .middle5 {
    opacity: 1;
  }

  div.text5 {
    color: black;
    font-family: 'futura-pt', sans-serif;
    padding: 5px;
    width: 5em;
    font-weight: 400;
    font-size: 16px;
  }

  /* next block quote for research */

  div.quote2 {
    display: flex;
    font-family: 'EB Garamond', serif;
    box-sizing: border-box;
    margin: 0;
  }

  /* quote arrow !! */

  button.arrowQuote {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 464%; 
    left: 50%;*/
    position: static;
    width: 60px;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  
  .middleQuote {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
      
  .textQuote a:hover, .textQuote a, .textQuote, .textQuote a:focus, .textQuote a:active {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }
      
  button.arrowQuote:hover .arrQuote, button.arrowQuote:active .arrQuote, button.arrowQuote:focus .arrQuote {
    opacity: 0;
  }
      
  button.arrowQuote:hover .middleQuote, button.arrowQuote:active .middleQuote, button.arrowQuote:focus .middleQuote {
    opacity: 1;
  }
      
  div.textQuote {
    color: black;
    font-size: 16px !important;
    padding: 5px;
    width: 6em;
    font-weight: 400 !important;
    font-family: 'futura-pt', sans-serif;
  }

  /* mobile arrows */

  button.arrowVid { /* hide on bigger */
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 587%; 
    left: 50%; */
    margin-left: 50%;
    position: static;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none !important;
  }

  button.arrow52 { /* hide on bigger */
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    margin-left: 50%;
    width: 60px;
    margin-top: 2em;
    position: static;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none !important;
  }
  
  .middleVid {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
      
  .textVid a:hover, .textVid a:active, .textVid a:focus, .textVid a, .textVid {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
    font-family: 'futura-pt', sans-serif;
  }
      
  button.arrowVid:hover .arrVid, button.arrowVid:active .arrVid, button.arrowVid:focus .arrVid {
    opacity: 0;
  }
      
  button.arrowVid:hover .middleVid, button.arrowVid:active .middleVid, button.arrowVid:focus .middleVid {
    opacity: 1;
  }
      
  div.textVid {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 6em;
    font-weight: 400;
    font-family: 'futura-pt', sans-serif;
  }

  /* next mobile Vid arrow */

  button.arrowVid2 { /* hide on bigger */
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 837%; 
    left: 50%; */
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }
  
  .middleVid2 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
      
  .textVid2 a:hover, .textVid2 a, .textVid2, .textVid2 a:focus, .textVid2 a:active {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }
      
  button.arrowVid2:hover .arrVid2, button.arrowVid2:active .arrVid2, button.arrowVid2:focus .arrVid2 {
    opacity: 0;
  }
      
  button.arrowVid2:hover .middleVid2, button.arrowVid2:focus .middleVid2, button.arrowVid2:active .arrVid2 {
    opacity: 1;
  }
      
  div.textVid2 {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 6em;
    font-weight: 400;
  }

  /* next one */

  button.arrowVid3 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 1074%; 
    left: 50%; */
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }

  .middleVid3 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
    
  .textVid3 a:hover, .textVid3 a,.textVid3, .textVid3 a:focus {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }
    
  button.arrowVid3:hover .arrVid3, button.arrowVid3:focus .arrVid3 {
    opacity: 0;
  }
    
  button.arrowVid3:hover .middleVid3, button.arrowVid3:focus .middleVid3 {
    opacity: 1;
  }
  
  .textVid3 {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 6em;
    font-weight: 400;
  }

  /* next one */

  button.arrowVid4 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 1315%; 
    left: 50%; */
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }

  .middleVid4 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
    
  .textVid4 a:hover, .textVid4 a, .textVid4, .textVid4 a:focus {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }
    
  button.arrowVid4:hover .arrVid4, button.arrowVid4:focus .arrVid4 {
    opacity: 0;
  }
    
  button.arrowVid4:hover .middleVid4, button.arrowVid4:focus .middleVid4 {
    opacity: 1;
  }
  
  .textVid4 {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 6em;
    font-weight: 400;
  }

  /* next one */

  button.arrowVid5 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 1550%;   
    left: 50%; */
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }

  .middleVid5 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
    
  .textVid5 a:hover, .textVid5 a, .textVid5, .textVid5 a:focus {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }
    
  button.arrowVid5:hover .arrVid4, button.arrowVid5:focus .arrVid4 {
    opacity: 0;
  }
    
  button.arrowVid5:hover .middleVid4, button.arrowVid5:focus .middleVid4 {
    opacity: 1;
  }
  
  .textVid5 {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 6em;
    font-weight: 400;
  }

  /* features arrows */

  button.arrow5 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 714%;     
    left: 50%; */
    margin-top: 2em; /* added ! */
    position: static;
    display: none;
    width: 60px;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    border: none;
    background-color: transparent !important;
  }
  
  .middle6, .middle62 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
      
  .text6 a:hover, .text6 a, .text6, .text6 a:focus, .text62 a:hover, .text62 a, .text62, .text62 a:focus {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }
      
  button.arrow5:hover .arr5, button.arrow5:focus .arr5, button.arrow52:hover .arr52, button.arrow52:focus .arr52 {
    opacity: 0;
  }
      
  button.arrow5:hover .middle6, button.arrow5:focus .middle6, button.arrow52:hover .middle62, button.arrow52:focus .middle62 {
    opacity: 1;
  }
      
  div.text6, div.text62 {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 6em;
    font-weight: 400;
    font-family: 'futura-pt', sans-serif;
  }

  /* next feature arrow */

  button.arrow6 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 953%;   
    left: 50%; */
    margin-left: 50%;
    position: static;
    margin-top: 2em;
    width: 60px;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }

  button.arrow62 {
    display: none;
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    margin-left: 50%;
    position: static;
    margin-top: 0em;
    width: 60px;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }
  
  .middle7, .middle72 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
      
  .text7 a:hover, .text7 a, .text7, .text7 a:focus, .text72 a:hover, .text72 a, .text72, .text72 a:focus {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }
      
  button.arrow6:hover .arr6, button.arrow6:focus .arr6, button.arrow62:hover .arr62, button.arrow62:focus .arr62 {
    opacity: 0;
  }
    
  button.arrow6:hover .middle7, button.arrow6:focus .middle7, button.arrow62:hover .middle72, button.arrow62:focus .middle72 {
    opacity: 1;
  }
      
  div.text7, div.text72 {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 6em;
    font-weight: 400;
    font-family: 'futura-pt', sans-serif;
  }

  /* next feature arrow */

  button.arrow7 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 1190%;
    left: 50%; */
    position: static;
    margin-left: 50%;
    margin-top: 2em;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
    display: none;
  }

  button.arrow72 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 1190%;
    left: 50%; */
    position: static;
    margin-left: 50%;
    margin-top: 2em;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }

  .middle8, .middle82 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
    
  .text8 a:hover, .text8 a, .text8, .text8 a:focus, .text82 a:hover, .text82 a, .text82, .text82 a:focus {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
    font-family: 'futura-pt', sans-serif;
  }
    
  button.arrow7:hover .arr7, button.arrow7:focus .arr7, button.arrow72:hover .arr72, button.arrow72:focus .arr72 {
    opacity: 0;
  }
    
  button.arrow7:hover .middle8, button.arrow7:focus .middle8, button.arrow72:hover .middle82, button.arrow72:focus .middle82 {
    opacity: 1;
  }
    
  .text8, .text82 {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 6em;
    font-weight: 400;
    font-family: 'futura-pt', sans-serif;
  }

  /* next arrow */

  button.arrow8 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute; 
    top: 1430%; 
    left: 50%;*/
    position: static;
    margin-left: 50%;
    width: 60px;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
    margin-top: 2em;
  }

  button.arrow82 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
    display: none;
  }

  .middle9, .middle92, .middle102 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
    
  .text9 a:hover, .text9 a, .text9, .text9 a:focus, .text102 a:hover, .text102 a, .text102, .text102 a:focus, .text92 a:hover, .text92 a, .text92 a:focus {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
    font-family: 'futura-pt', sans-serif;
  }
    
  button.arrow8:hover .arr8, button.arrow8:focus .arr8, button.arrow82:hover .arr82, button.arrow82:focus .arr82 {
    opacity: 0;
  }
    
  button.arrow8:hover .middle9, button.arrow8:focus .middle9, button.arrow82:hover .middle92, button.arrow82:focus .middle92 {
    opacity: 1;
  }

  button.arrow92:hover .arr92, button.arrow92:focus .arr92 {
    opacity: 0;
  }

  button.arrow92:hover .middle102, button.arrow92:focus .middle102 {
    opacity: 1;
  }
    
  .text9, .text102, .text92 {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 6em;
    font-weight: 400;
    font-family: 'futura-pt', sans-serif;
  }

  /* next feature arrow */

  button.arrowRes, button.arrowRes2, button.arrowRes3, button.arrowDes {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    left: 50%; */
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }

  button.arrow9 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    left: 50%; */
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
    display: none;
  }

  button.arrow92 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
    margin-top: 2em;
  }

  /*button.arrow9 {
    top: 1660%; 
  }

  button.arrowRes2 {
    top: 2240%; 
  }

  button.arrowRes3 {
    top: 2500%; 
  }

  button.arrowDes {
    top: 2380%; 
  }*/

  .middle10, div.middleRes, div.middleRes2, div.middleRes3, div.middleDes {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
    
  .text10 a:hover, .text10 a, .textRes a:hover, .textRes a, .textRes2 a:hover, .textRes2 a, .textRes3 a:hover, .textRes3 a, .textDes a:hover, .textDes a
  , .text10, .textRes, .textRes2, .textRes3, .textDes {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }
    
  button.arrow9:hover .arr9, button.arrowRes:hover .arrRes, button.arrowRes2:hover .arrRes2, button.arrowRes3:hover .arrRes3, button.arrowDes:hover .arrDes {
    opacity: 0;
  }

  button.arrow9:focus .arr9, button.arrowRes:focus .arrRes, button.arrowRes2:focus .arrRes2, button.arrowRes3:focus .arrRes3, button.arrowDes:focus .arrDes {
    opacity: 0;
  }
    
  button.arrow9:hover .middle10, button.arrowRes:hover .middleRes, button.arrowRes2:hover .middleRes2, button.arrowRes3:hover .middleRes3, button.arrowDes:hover .middleDes {
    opacity: 1;
  }

  button.arrow9:focus .middle10, button.arrowRes:focus .middleRes, button.arrowRes2:focus .middleRes2, button.arrowRes3:focus .middleRes3, button.arrowDes:focus .middleDes {
    opacity: 1;
  }
    
  .text10, .textRes, .textRes3, .textDes {
    color: black;
    font-size: 16px;
    padding: 5px;
    width: 7em;
    font-weight: 400;
    font-family: 'futura-pt', sans-serif;
  }

  .textRes2 {
    width: 9em;
    color: black;
    font-size: 16px;
    padding: 5px;
    font-weight: 400;
  }

  /* features list */

  div.features {
    display: flex;
    flex-direction: column; 
    padding-left: 0em; /* 8em */
    /*margin-top: 52.5em;  
    padding-top: 4em;*/ /* added */
    height: auto; 
    background-color: #f0f0f0;
  }

  /* previous features */

  div.features h1 {
    font-size: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #333333;
    margin-left: 42px;
    margin-bottom: 0.7em;
    padding-left: 0px;
  }

  div.features h1.fea {
    margin-bottom: 0px;
  }

  div.features h2 {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 30px;
    margin-left: 42px;
    padding-bottom: 0.5em;
  }

  div.onboarding h2 {
    padding-left: 0;
  }

  div.shelters h2  {
    padding-left: 0;
    margin-left: 42px;
  }

  div.shelters h2 {
    text-align: left;
  }

  div.videos {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0
  }

  /* photo remove for mobile */

  div.photoOne, div.photo2, div.photo1, div.callVid, div.planVid {
    display: none;
  }

  div.onP, div.safeP, div.shelP, div.messP {
    display: flex;
    flex-direction: column;
  }

  div.onP, div.safeP, div.shelP, div.safetyP /* added safeP & shelP, messP, safetyP */ {
    margin-top: 0px;
    background-color: #f0f0f0;
    padding-left: 0px;
    padding-right: 25px;
    padding-bottom: 25px;
    border-radius: 10px;
    font-family: europa, sans-serif;
    font-size: 17px;
    font-weight: 400;
  }

  div.shelP {
    height: 37em;
    padding-top: 0px;
    line-height: 34px;
  }
  
  div.safetyP {
    line-height: 37px;
  }

  div.onP {
    height: 29em; /* 26em */
  }

  div.safetyP {
    height: 36em;
  }

  div.safeP {
    line-height: 35px;
    height: 38em;/* make sure this works */
  }

  div.onP {
    padding-top: 0px;
    line-height: 30px;
    margin-bottom: 5em;
  }

  div.safeP, div.safetyP {
    padding-top: 25px;
  }

  div.messP {
    margin-top: 0px;
    /*height: 36em;*/ /* for mobile */
    height: auto;
    background-color: #f0f0f0;
    padding-left: 0px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 25px;
    border-radius: 10px;
    font-family: 'europa', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 37px;
  }

  div.shelters {
    padding-top: 1em; /* 3.8em, margin 0px */
    margin-top: 2em;/* 4em */
  }

  p.sheltervid2, p.hotlineone, p.safescreentwo, p.onboardP2, p.callvid, p.planP {
    font-family: europa, sans-serif;
    color: #333333;
    font-weight: 600;
    font-size: 15px;
    margin-top: 0.7em;
  }

  p.onboardP, p.safescreen1, p.sheltervid, p.chatvid, p.safeP2 {
    text-align: center; /* for mobile */
    margin-top: 1em;
    font-family: europa, sans-serif;
    color: #333333;
    font-weight: 400;
    font-size: 18px; /* 15px */
  }

  img.onboard {
    height: 500px;
  }

  video {
    border-radius: 10px;
  }

  div.video2, div.messVid, div.safeVid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.safeVid {
    margin-top: 5em; /* 4.5em */
  }

  /* changing sizes for iPhone 5 */

  div.safeVid video.normSafe, div.video video.normShel, div.video2 video.normSafe2, div.video1 video.normOn {
    display: none;
  }

  div.video .smallShel, div.video2 .smallSafe2, div.video1 .smallOn, div.safeVid .smallSafe {
    display: block;
  }

  /* finished size vid changes */

  div.video1 {
    display: flex;
    flex-direction: column;
    padding-top: 1em; /* 5em */
    margin-top: 5em;
    align-items: center;
  }

  div.messVid {
    margin-top: 8em; /* 0px */
    padding-top: 1em; /* 5em */
    
  }

  div.video {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5em; /* 0px */
    padding-top: 1em; /* 5em */    
  }

  div.safetyP a, div.safetyP p a:hover, div.safetyP p a:focus, div.safetyP p a:active {
    color: #43B197;
    text-decoration: underline;
  }

  div.onP p, div.safeP p, div.shelP p, div.messP p, div.safetyP p { /* for mobile */
    width: 16em;
    padding-left: 0px;
    margin-left: 42px;
  }

  div.safetyP p, div.messP p {
    line-height: 30px;
  }
  p.safeP, p.messP {
    padding-left: 0px;
  }

  div.messP h2 {
    margin-left: 42px; /* added for mobile, might need to take out */
  }  

  div.safe, div.mess { /* mobile */
    display: flex;
    flex-direction: column;
  }

  div.on, div.shel, div.safe2 {
    display: flex;
    flex-direction: column-reverse; /* take out for full */
  }
  
  div.safescreen {
    margin-top: 1em; /* 5em, 3em */
    padding-top: 0em; /* 3em */
  } /* check this one with beforehand, might just be for iphone6 !!!! */

  div.message {
    margin-top: 1em; /* 5em */
    padding-top: 1em; /* 3em or 5em */
    margin-bottom: 0px;
  }

  /* for mobile */

  div.safescreen h2 {
    margin-left: 42px;
    padding-right: 0px; /* 8em full */
  }

  div.photo2 {
    margin-top: 1em;
  }

  div.video2 {
    padding-top: 10px;
    margin-top: 5em;
  }

  div.onboarding {
    background-color: #f0f0f0;
    border-radius: 20px;
    /*width: 85em; taken out for mobile */
  }

  img.shelter, .photo2 img {
    height: 500px;
    margin-top: 0;
  }

  div.message {
    padding-left: 0px;
  }

  div.callVid, div.messVid {
    margin-left: 0px;
    margin-right: 0px;
  }

  div.safeVid {
    margin-right: 0px;
    margin-left: 0px;
    /*margin-top: 23.5em; make sure not needed later */
  }

  div.safety {
    padding-top: 0em; /* 2.5*/
    margin-top: 4em; /* 1.2, 6 */
  }  

  /* research */

  div.container5 {
    padding-left: 0px;
    font-family: 'europa', sans-serif;
    background-color: #f0f0f0;
    height: auto; /* 311, 322, 329 */
    padding-top: 30px;
    /*padding-bottom: 0px;*/
    padding-bottom: 3em; /* 5em */
  }

  div.container5 p {
    font-size: 17px;
  }

  div.research p {
    margin-right: 5em;
    line-height: 35px; /* try more */
    width: 15.5em;
    margin-bottom: 20px;
  }

  div.research {
    display: flex;
    flex-direction: column;
    margin-left: 42px;
    padding-left: 0px;
  }

  div.container5 h1 {
    padding-left: 42px;
    margin-left: 0px;
  }

  /* research Arrow */

  button.arrowRes {
    top: 1952%; /* 1555, 1922 */
  }

  button.arrowRes3 {
    margin-top: 2em;
  }

  /* research takeaways */

  div.statement {
    padding-top: 0em;
    margin-top: 7em; 
  }

  h3.resSub {
    text-align: left;
    padding-left: 42px;
    width: 13em;
  }

  div.interview h2 {
    padding-left: 42px; /* check */
    line-height: 35px; /* check */
  }

  div.interview h2 {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 30px;
    padding-bottom: 0.5em;
  }

  div.interview p {
    margin-top: 0.5em;
  }

  /* research ideation */

  div.ideation h1, div.ideation h2, div.ideation h3 {
    font-family: 'Lato', sans-serif;
    font-weight: 600;
  }

  /* circles */

  div.circle1 {
    opacity: 1;
    backface-visibility: visible;
    text-align: center;
    position: relative;
    /*margin-left: 3em;*/
    margin-top: 3em;
    /*margin-right: 1em;*/
    margin-bottom: 3em;
  }

  div.circle1 img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    opacity: 1; 
  }

  .takeText {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .takeMid {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 19%; /* new */
    right: 0;
    width: 200px;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #f0f0f0;
    color: black;
    border-radius: 50%;
  }
  
  .circle1:hover .takeMid, .circle1:focus .takeMid {
    opacity: 0.97;
  }

  .takeMid p {
    width: 160px;
  }
  
  .takeText:hover, .takeText:focus, .takeText, .takeText:active {
    color: black;
    text-decoration: none;
    opacity: 1;
  }

  /* problem statement */

  div.problem h3 {
    text-align: left;
    padding-left: 42px;
    line-height: 30px;
  }

  /* blockquote */

  div.quote2 {
    margin-top: 3em; /* added back */
    /*padding-top: 4em;*/
    /*height: 38em;*/ 
    flex-grow: 1;
    padding-right: 1em;
    background-color: #f0f0f0;
  }

  blockquote.b2 {
    font-size: 1.4em;
    width: 17em; /* 25em */
    margin: 30px auto;
    margin-top: 10px;
    margin-left: 0;
    font-family: 'europa', sans-serif;
    color: #555555;
    padding: 1.2em 30px 1.2em 75px;
    border-right: 8px solid #78C0A8;
    line-height: 1.6;
    position: relative;
    border-left: none;
  }

  div.quote2 a {
    color: #333333;
  }
  
  blockquote.b2::before {
    font-family: Arial;
    content: "\201C";
    color:#78C0A8;
    font-size:4em;
    position: absolute;
    left: 25px;
    top:-10px;
  }
  
  blockquote.b2::after {
    content: '';
  }
  
  blockquote.b2 span{
    display:block;
    color:#333333;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
  } 

  /* arrow */

  /* 506 */

  /*button.arrowDes {
    top: 1885%; 
  }*/

  /* more problem statement */

  div.designC {
    padding-left: 42px;
    margin-top: 5em; /* these two added */
    padding-top: 1em;
  }

  div.designC h3 {
    text-align: left;
    margin-bottom: 20px;
    line-height: 30px;
  }

  div.designC h4 {
    width: 13em;
    line-height: 28px;
  }

  /* prototype !!! */

  div.ideation {
    padding-top: 2em; /* 3em */
    /*margin-top: 1em;*/
  }

  /* intro & prototype */

  div.ideateIntro {
    padding-left: 42px;
    margin-left: 0px;
    height: auto;
  }

  div.ideateIntro h1 {
    margin-bottom: 20px;
  }

  p.ideate {
    width: 15em;
    font-size: 17px;
    line-height: 32px;
    /* added below */
    font-family: 'europa', sans-serif;
    font-weight: 500;
  }

  /* arrow */

  button.arrowPro {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 2623%; 
    left: 50%; */
    margin-top: 2em; /* added */
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }
  
  .middlePro {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
      
  .textPro a:hover, .textPro a:focus, .textPro a, .textPro a:active, .textPro {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }
      
  button.arrowPro:hover .arrPro, button.arrowPro:focus .arrPro, button.arrowPro:active .arrPro {
    opacity: 0;
  }
    
  button.arrowPro:hover .middlePro, button.arrowPro:focus .middlePro, button.arrowPro:active .middlePro {
    opacity: 1;
  }
      
  div.textPro {
    color: black;
    font-size: 16px;
    font-family: 'futura-pt', sans-serif;
    padding: 5px;
    width: 6em;
    font-weight: 400;
  }

  /* */

  div.interact, div.interArrow {
    display: none;
  }

  /* prototype !! */

  div.wireframeFull {
    height: auto;
  }

  div.proto {
    padding-top: 1em; /* 4em, 8em */
    margin-top: 9em; /* 0em */
    margin-left: 3em; /* 4em */
  }
  
  div.protoFull {
    /*top: 1001em;*/
    position: absolute;
  } /* check this */

  img.bottomFull2, img.topFull2, img.leftFull2, img.rightFull2 {
    display: none;
  }

  div.iframe3 {
    display: block;
  }

  div.iframe, div.iframe2 {
    display: none;
  }

  /*img.topFull {
    width: 415px;
    height: auto;
    position: absolute;
    left: -5.9em;
    top: -7em;
  }*/

  img.topFull {
    width: 385px;
    height: auto;
    position: absolute;
    left: -5.5em;
    top: -4.3em;
  }

  img.leftFull {
    position: absolute;
    height: 461px; 
    width: auto;
    top: 2.8em;
    left: -5.54em;
  }

  img.rightFull {
    position: absolute;
    width: auto;
    /*top: 593.3em;*/ /* 600.27em */
    height: 461px; /* 515px */
    /*left: 94.8em;*/ /* 94.75 */
    /*left: 17.69em;*/
    left: 15.8em;
    top: 2.8em;
   }

   img.bottomFull {
    position: absolute;
    width: 385px;
    height: auto;
    top: 35.7em;
    left: -5.5em;
  }

  /*img.leftFull {
    position: absolute;
    height: 493px; 
    width: auto;
    top: 0.6em;
    left: -5.85em;
  }*/

  /*img.rightFull {
    position: absolute;
    width: auto;
    height: 493px; 
    left: 17em;
    top: 0.6em;
   }*/

  /*img.bottomFull {
    position: absolute;
    width: 415px;
    height: auto;
    top: 35.8em;
    left: -5.9em;
  }*/

  /* wireframes */ 

  button.arrowWire {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    position: absolute;
    top: 2710%; /* 506, 2690 */
    left: 50%; /* 28 % */
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    display: none;
    background-color: transparent !important;
    border: none;
  }
  
  .middleWire {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
      
  .textWire a:hover, .textWire a:focus, .textWire a, .textWire {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }
      
  button.arrowWire:hover .arrWire, button.arrowWire:focus .arrWire, button.arrowWire:active .arrWire {
    opacity: 0;
  }
    
  button.arrowWire:hover .middleWire, button.arrowWire:focus .middleWire, button.arrowWire:active .middleWire {
    opacity: 1;
  }
      
  div.textWire {
    color: black;
    font-size: 16px;
    font-family: futura-pt, sans-serif;
    padding: 5px;
    width: 7em;
    font-weight: 400;
  }

  /* wireframes continued */ 

  div.wireframes {
    padding-top: 2em;
    background-color: #f0f0f0;
    margin-top: 2em;
    height: 139em; /* 150 */
  }

  div.wireframes h2 {
    padding-left: 42px;
    margin-left: 0px;
  }

  div.wireframes h3 {
    text-align: left;
    padding-left: 42px;
  }

  div.row1, div.row2, div.row3 {
    display: flex;
    flex-direction: row;
    padding-left: 42px;
    flex-wrap: wrap;
  }

  div.wire p {
    font-size: 16px;
    font-family: 'europa', 'sans-serif';
  }

  img.arrowLong3 {
    display: none;
  }

  div.cont1, div.cont2, div.cont3, div.cont4, div.shel1, div.shel2, div.hot1, div.hot2, div.safe1, div.safety2, div.safety3, div.safety4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img.contwire, img.contwire2, img.contwire4, img.contwire6, img.shelwire1, img.shelwire2, img.hotwire1, img.hotwire2,
  img.safewire1, img.safewire2, img.safewire3, img.safewire4 {
    width: 9em; /* 10em */
    height: auto;
  }

  /* arrow */

  button.arrowIA {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 3079%; 
    left: 50%; */
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none;
  }

  button.arrowIA:hover .arrWire, button.arrowIA:focus .arrWire, button.arrowIA:active .arrWire {
    opacity: 0;
  }
    
  button.arrowIA:hover .middleWire, button.arrowIA:focus .middleWire, button.arrowIA:active .middleWire {
    opacity: 1;
  }

  /* IA */

  div.IAFull {
    padding-top: 3em;
    padding-bottom: 8em;
  }

  div.IAFull h2 {
    padding-left: 30px;
    padding-bottom: 1.5em;
    margin-left: 0px;
  }

  img.IA {
    width: 22em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    height: auto;
  }

  div.IAEnl, img.IA {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    position: relative;
  }

  div.innerText {
    opacity: 0.8;
    transition: .5s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background-color: #f0f0f0;
    width: 15em;
    height: 5em;
    border-radius: 10px;
  }

  div.textIA {
    color: black !important;
    font-size: 20px;
    font-family: futura-pt, sans-serif;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 7em;
    font-weight: 500;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
  }

  div.textIA a, div.textIA:active a, div.textIA:focus a, div.textIA {
    color: black;
    text-decoration: none;
    cursor: pointer !important;
  }

  /* arrow2 */

  button.arrowIA2 {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute;
    top: 3155%; 
    left: 50%; */
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: transparent !important;
    border: none;
    cursor: pointer !important;
  }

  button.arrowIA2:hover .arrRef, button.arrowIA2:focus .arrRef, button.arrowIA2:active .arrRef {
    opacity: 0;
  }
    
  button.arrowIA2:hover .middleWire, button.arrowIA:focus .middleWire, button.arrowIA:active .middleWire {
    opacity: 1;
  }

  /* container 7 reflection */

  div.container7 {
    /*padding-left: 8em;*/
    padding-left: 42px;
    padding-top: 2em; /* 20em */
    padding-bottom: 2em;
  }

  div.container7 p {
    /*font-size: 20px;
    line-height: 35px;
    width: 30em;*/
    font-size: 17px;
    line-height: 35px;
    width: 14em;
    font-family: 'europa', sans-serif;
    font-weight: 500;
  }

  div.container7 h1 {
    margin-bottom: 1em;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
  }

  img.reflectionImage {
    display: none;
  }
  
   img.wave {
    display: none; /* hidden on mobile */
  }

  /* footer */

  div.credit p {
    text-align: center;
    font-size: 16px;
    font-family: 'europa', 'sans-serif';
    font-weight: 500;
  }

  div.footer, div.credit {
    background-color: #D9D3F2;
  }

  div.credit {
    margin-bottom: 0px;
  }

  div.email2 a {
    color: #333333;
  }

  /* footer attempt dos */

  #backTopB {
    background-color: #D9D3F2;
    border: none;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    outline: none;
    bottom: 75px;
    left: 270px;
    padding: 0px;
    display: block;
    position: fixed;
    z-index: 99;
  }

  div.backTop2 {
    background-color: transparent;
    outline: none;
    left: 240px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-right: 6px;
  }

  img.backTop {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
    padding-bottom: 0px;
    background-color: transparent;
  }  

  div.bottomLinks {
    /*display: flex !important;
    flex-direction: row;
    align-items: center;*/
    background-color: #D9D3F2;
    width: 100%;
    height: 6.5em;
    border: none;
  }

  div.email2 {
    /*margin-right: 95px;*/
    padding-bottom: 5px;
    padding-top: 35px;
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
  }

  div.email2 a {
    font-weight: 500;
    text-decoration: none;
  }

  div.credit {
    text-align: center;
    padding-top: 10px;
    align-self: flex-end;
    padding-bottom: 5px;
    display: block;
    padding-left: 30px;
    padding-right: 30px;
  }

  div.credit p {
    margin-bottom: 0px;
  }

  div.footer {
    height: 9.1em;
    margin-top: 2em;
    display: block;
  }    

  div.email2 a:hover, div.email2 a:focus, div.email2 a:active {
    text-decoration: none;
    opacity: 0.5;
  }

  div.plink {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 2em;
    margin-bottom: 0px;
  }

  div.nextP {
    font-family: 'europa', sans-serif;
    margin-bottom: 1em;
    font-size: 16px;
  }
  
  div.pName {
    font-size: 32px;
    font-family: 'Castoro', sans-serif;
    color: #333333;
    margin-bottom: 0px;
  }

  div.pName a {
    color: #333333;
    text-decoration: none;
    padding-left: 15px;
    opacity: 0.8;
  }

  img.footLink {
    padding-left: 2px;
  }

  div.pName a:hover, img.footLink:hover, div.pName a:active, div.pName a:focus, img.footLink:active, img.footLink:focus {
    text-decoration: none;
    opacity: 0.8;
  }
  

  @media (min-width: 370px) and (max-width: 380px) and (min-height: 660px) and (max-height: 680px) { /* iphone 6 */
    
    img.solaceHome2 {
      display: block;
      width: 100%;
      height: auto;
      margin-top: 25px; /* added */
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
    }

    img.solaceHome { 
      display: none;
    }

    /* container 2 */

    /*button.arrow2 {
      top: 95%;
    }*/

    div.iframe3, img.iframe3 {
      display: none;
    }

    div.container2 {
      padding-top: 80px; /* changed from 60px */
      padding-bottom: 10em;
    }

    div.centered {
      margin-left: 0px;
      margin-right: 0px;
    }

    div.titleSol, div.container2 h2 {
      margin-left: auto;
      margin-right: auto;
    }

    div.centered h2 {
      margin-bottom: 0px;
    }

    /* container 3 */

    div.container3 {
      padding-top: 5em;
    }

    .timeline div, .affil div {
      margin-bottom: 1.5em;
    }

    button.arrow3 {
      top: 217%;
    }

    /* container 4 */

    div.container4 {
      height: 90em;
      /*padding-top: 30px;*/
    }

    div.container4 .textSide {
      padding-top: 30px;
      padding-bottom: 20px;
      flex-grow: 0;
    }

    div.textSide h1 {
      margin-bottom: 20px;
    }

    div.textSide p {
      margin-bottom: 10px;
      line-height: 23px;
    }

    button.arrow4 {
      top: 304%;
    }

    div.quote {
      padding-top: 3em;
      flex-grow: 0;
      height: auto;
    }

    button.arrowQuote {
      top: 395%;
      background-color: transparent;
      border: none;
    }

    .textSide p, div.onP p, div.safeP p, div.shelP p, div.messP p, div.safetyP p {
      width: 17em; 
    }

    blockquote.b1 {
      border-right: 8px solid #78C0A8;
    }

    /* features */

    div.features {
      padding-top: 2em;
      margin-top: 0px;
      height: auto;
    }

    div.video1 {
      padding-top: 3em;
      margin-top: 2em;
    }

    div.safescreen {
      margin-top: 3em;
      padding-top: 1em;
    }

    div.safeP {
      height: 37em;
    }

    div.video2 {
      margin-top: 1em;
      padding-top: 3em;
    }

    button.arrowVid {
      top: 494%;
      
    }

    button.arrow5 {
      top: 600%;
    }

    button.arrowVid2 {
      top: 693%;
    }

    button.arrow6 {
      top: 794%;
    }

    div.shelters {
      padding-top: 3em;
      margin-top: 2em;
    }

    div.shelP {
        line-height: 32px;
    }

    button.arrowVid3 {
      top: 885%;
    }

    div.video {
      padding-top: 3em;
      margin-top: 0px;
    }

    button.arrow7 {
      top: 988%;
    }

    div.message {
      padding-top: 2em;
      margin-top: 2em;
    }

    button.arrowVid4 {
      top: 1080%;
    }

    div.messVid {
      padding-right: 0px;
      padding-top: 3em;
      margin-top: 0em;
    }

    button.arrow8 {
      top: 1177%; 
    }

    div.safety {
      margin-top: 2em;
      padding-top: 1em;
    }

    div.safetyP p {
      line-height: 29px;
    }

    button.arrowVid5 {
      top: 1267%;
    }

    div.safeVid {
      padding-top: 3em;
      margin-top: 1em;
    }

    button.arrow9 {
      top: 1367%;
    }

    div.safeVid video.normSafe, div.video video.normShel, div.video2 video.normSafe2, div.video1 video.normOn {
      display: block;
    }

    div.safeVid video.smallSafe, div.video video.smallShel, div.video2 video.smallSafe2, div.video1 video.smallOn {
      display: none;
    }

    /* research */

    div.container5 h1 {
      margin-left: 42px;
      margin-top: 20px;
      padding-left: 0px;
    }

    div.research {
      margin-left: 42px;
      padding-left: 0px;
      flex-direction: column;
      /* make this white ??? */
    }

    div.research p {
      width: 17em;
    }

    div.container5 {
      padding-left: 0px;
      padding-top: 30px;
      /*height: 314em;*/
      height: auto;
      padding-bottom: 0px;
    }

    /* arrow */

    button.arrowRes {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 1592%; 
      left: 50%; */
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer !important;
    }
    
    .middleRes {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textRes a:hover, .textRes a:focus, .textRes a, .textRes {
      color: black;
      text-decoration: none;
      cursor: pointer !important;
    }
        
    button.arrowRes:hover .arrRes, button.arrowRes:focus .arrRes {
      opacity: 0;
    }
      
    button.arrowRes:hover .middleRes, button.arrowRes:focus .middleRes {
      opacity: 1;
    }
        
    div.textRes {
      color: black;
      font-size: 16px;
      font-family: 'futura-pt', sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* research takeaways */

    div.interview {
      padding-left: 0px;
      margin-left: 0px;
      padding-top: 3em;
      margin-top: 2em;
    }

    div.interview h2, div.interview h3 {
      margin-left: 42px;
      padding-left: 0px;
      text-align: left;
    }

    h3.resSub {
      font-weight: 400;
    }

    /* circles */

    div.circle1 {
      opacity: 1;
      backface-visibility: visible;
      text-align: center;
      position: relative;
      /*margin-left: 3em;*/
      margin-top: 3em;
      /*margin-right: 1em;*/
      margin-bottom: 3em;
    }

    div.circle1 img {
      border-radius: 50%;
      width: 200px;
      height: 200px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      opacity: 1; 
    }

    .takeText {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }

    .takeMid {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 23.4%; /* new */
      right: 0;
      width: 200px;
      opacity: 0;
      transition: 0.5s ease;
      background-color: #f0f0f0;
      color: black;
      border-radius: 50%;
    }
    
    .circle1:hover .takeMid, .circle1:focus .takeMid {
      opacity: 0.97;
    }

    .takeMid p {
      width: 160px;
    }
    
    .takeText:hover, .takeText:focus, .takeText, .takeText:active {
      color: black;
      text-decoration: none;
      opacity: 1;
    }

    /* next arrow */

    button.arrowRes2 {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 1825%; 
      left: 50%;*/
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer !important;
    }
    
    .middleRes2 {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textRes2 a:hover, .textRes2 a:focus, .textRes2 a, .textRes2 {
      color: black;
      text-decoration: none;
      cursor: pointer !important;
    }
        
    button.arrowRes2:hover .arrRes2, button.arrowRes2:focus .arrRes2 {
      opacity: 0;
    }
      
    button.arrowRes2:hover .middleRes2, button.arrowRes2:focus .middleRes2 {
      opacity: 1;
    }
        
    div.textRes2 {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* problem statement */

    div.statement {
      padding-left: 0px;
      margin-left: 0px;
      margin-top: 3em;
      padding-top: 3em;
      display: flex;
      flex-direction: column;
      /*height: 98em;*/
      background-color: #f0f0f0;
    }

    div.statement h2 {
      margin-bottom: 10px;
      padding-bottom: 0px;
    }

    div.problem h3 {
      width: 13em;
      font-weight: 500;
      line-height: 29px;
      padding-bottom: 0px;
      margin-bottom: 0px;
      margin-top: 15px;
    }

    div.fullProblem {
      display: flex;
      flex-direction: column;
      /* justify-content: center; ? */
    }

    /* quote */

    div.quote2 {
      /*margin-top: 3em;*/
      /*padding-top: 4em;*/
      /*height: 38em;*/ 
      margin-top: 1em;
      flex-grow: 1;
      padding-right: 1em;
      background-color: #f0f0f0;
    }
  
    blockquote.b2 {
      font-size: 1.4em;
      width:17em; /* 25em */
      margin: 30px auto;
      margin-top: 10px;
      margin-left: 0;
      font-family: Europa, sans-serif;
      color: #555555;
      padding:1.2em 30px 1.2em 75px;
      border-right:8px solid #78C0A8;
      line-height:1.6;
      position: relative;
      border-left: none;
    }
  
    div.quote2 a {
      color: #333333;
    }
    
    blockquote.b2::before {
      font-family: Arial;
      content: "\201C";
      color:#78C0A8;
      font-size:4em;
      position: absolute;
      left: 25px;
      top:-10px;
    }
    
    blockquote.b2::after {
      content: '';
    }
    
    blockquote.b2 span{
      display:block;
      color:#333333;
      font-style: normal;
      font-weight: bold;
      margin-top: 1em;
    } 

    /* next arrow */

    button.arrowDes {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 1920%; 
      left: 50%; */
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer !important;
    }
    
    .middleDes {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textDes a:hover, .textDes a:focus, .textDes a, .textDes a:active, .textDes {
      color: black;
      cursor: pointer !important;
      text-decoration: none;
    }
        
    button.arrowDes:hover .arrDes, button.arrowDes:focus .arrDes, button.arrowDes:active .arrDes {
      opacity: 0;
    }
      
    button.arrowDes:hover .middleDes, button.arrowDes:focus .middleDes, button.arrowDes:active .middleDes {
      opacity: 1;
    }
        
    div.textDes {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* next part */

    div.designC {
      padding-top: 3em;
      margin-top: 0em;
      margin-left: 0px;
      padding-left: 0px;
    }

    div.designC h3 {
      width: 14.5em;
      font-weight: 500;
      line-height: 29px;
      padding-bottom: 0px;
      margin-bottom: 20px;
      margin-top: 15px;
    }

    div.designC h4 {
      padding-left: 42px;
      line-height: 29px;
      font-size: 19px;
      width: 17em;
    }

    div.designC b {
      color: #78C0A8;
    }

    /* arrow */

    button.arrowRes3 {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 2017%; 
      left: 50%; */
      margin-top: 2em;
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer !important;
    }
    
    .middleRes3 {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textRes3 a:hover, .textRes3 a:focus, .textRes3 a, .textRes3 {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
        
    button.arrowRes3:hover .arrRes3, button.arrowRes3:focus .arrRes3 {
      opacity: 0;
    }
      
    button.arrowRes3:hover .middleRes3, button.arrowRes3:focus .middleRes3 {
      opacity: 1;
    }
        
    div.textRes3 {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

   /* ideation */

    div.ideation {
      padding-top: 2em;
      /*margin-top: 1em;*/
    }

    /* intro & prototype */

    div.ideateIntro {
      padding-left: 42px;
      margin-left: 0px;
      height: 40em;
    }

    div.ideateIntro h1 {
      margin-bottom: 20px;
    }

    p.ideate {
      width: 17em;
      font-size: 18px;
      line-height: 32px;
    }

    /* arrow */

    button.arrowPro {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 2122%; 
      left: 50%; */
      margin-top: 0px;
      position: static;
      margin-left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer !important;
    }
    
    .middlePro {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textPro a:hover, .textPro a:focus, .textPro a, .textPro a:active, .textPro {
      color: black;
      text-decoration: none;
      cursor: pointer !important;
    }
        
    button.arrowPro:hover .arrPro, button.arrowPro:focus .arrPro, button.arrowPro:active .arrPro {
      opacity: 0;
    }
      
    button.arrowPro:hover .middlePro, button.arrowPro:focus .middlePro, button.arrowPro:active .middlePro {
      opacity: 1;
    }
        
    div.textPro {
      color: black;
      font-size: 16px;
      font-family: 'futura-pt', sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* */

    div.interact, div.interArrow {
      display: none;
    }

    /* prototype !! */

    div.wireframeFull {
      height: 92em;
    }

    div.proto {
      padding-top: 4em;
      margin-top: 2em; /* 5em */
      margin-left: 4em;
    }
    
    div.protoFull {
      /*top: 1001em;*/
      top: auto;
      position: absolute;
      /*left: 4em;*/
    }

    div.iframe, div.iframe3 {
      display: none;
    }

    div.iframe2 {
      display: block;
    }

    img.topFull {
      /*width: 508px;*/
      width: 415px;
      height: auto;
      position: absolute;
      /*left: -9.25em;*/
      left: -5.9em;
      /*top: 13em;*/
      top: -5em;
    }

    img.leftFull {
      position: absolute;
      /*left: 68.6em;*/ /* 68.45 */
      /*top: 593.3em;*/ /* 600.27em */
      height: 493px; /* 515px */
      width: auto;
      top: 2.6em;
      /*left: -8.61em;*/
      left: -5.85em;
    }

    img.rightFull {
      position: absolute;
      width: auto;
      /*top: 593.3em;*/ /* 600.27em */
      height: 493px; /* 515px */
      /*left: 94.8em;*/ /* 94.75 */
      /*left: 17.69em;*/
      left: 17em;
      top: 2.6em;
     }

    img.bottomFull {
      position: absolute;
      /*width: 499.8px;*/
      width: 415px;
      height: auto;
      /*left: 68.45em;*/
      /*top: 629.4em;*/ /* 637em */
      top: 37.8em;
      /*left: -8.6em;*/
      left: -5.9em;
    }

    /* arrow */

    button.arrowWire {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      position: absolute;
      top: 2185%; /* 506 */
      left: 50%; /* 28 % */
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer !important;
      display: none;
      background-color: transparent !important;
      border: none;
    }
    
    .middleWire {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textWire a:hover, .textWire a:focus, .textWire a, .textWire {
      color: black;
      text-decoration: none;
      cursor: pointer !important;
    }
        
    button.arrowWire:hover .arrWire, button.arrowWire:focus .arrWire {
      opacity: 0;
    }
      
    button.arrowWire:hover .middleWire, button.arrowWire:focus .middleWire {
      opacity: 1;
    }
        
    div.textWire {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 7em;
      font-weight: 400;
    }

    /* wireframes */

    div.wireframes {
      padding-top: 2em;
      background-color: #f0f0f0;
      margin-top: 2em;
      height: 150em; /* 150 */
    }

    div.wireframes h2 {
      padding-left: 42px;
      margin-left: 0px;
    }

    div.wireframes h3 {
      text-align: left;
      padding-left: 42px;
    }

    div.row1, div.row2, div.row3 {
      display: flex;
      flex-direction: row;
      padding-left: 42px;
      flex-wrap: wrap;
    }

    div.wire p {
      font-size: 18px;
    }

    img.arrowLong3 {
      display: none;
    }

    div.cont1, div.cont2, div.cont3, div.cont4, div.shel1, div.shel2, div.hot1, div.hot2, div.safe1, div.safety2, div.safety3, div.safety4 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img.contwire, img.contwire2, img.contwire4, img.contwire6, img.shelwire1, img.shelwire2, img.hotwire1, img.hotwire2,
    img.safewire1, img.safewire2, img.safewire3, img.safewire4 {
      width: 10em;
      height: auto;
    }

    /* arrow */

    button.arrowIA {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 2543%; 
      left: 50%; */
      margin-left: 50%;
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer !important;
      margin-top: 2em;
    }

    button.arrowIA:hover .arrWire, button.arrowIA:focus .arrWire, button.arrowIA:active .arrWire {
      opacity: 0;
    }
      
    button.arrowIA:hover .middleWire, button.arrowIA:focus .middleWire, button.arrowIA:active .middleWire {
      opacity: 1;
    }

    /* IA */

    div.IAFull {
      padding-top: 3em;
      padding-bottom: 3em; /* 8em */
    }

    div.IAFull h2 {
      padding-left: 30px;
      padding-bottom: 1.5em;
      margin-left: 0px;
    }

    img.IA {
      width: 22em;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      height: auto;
    }

    div.IAEnl, img.IA {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      position: relative;
    }

    div.innerText {
      opacity: 0.8;
      transition: .5s ease;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      background-color: #f0f0f0;
      width: 15em;
      height: 5em;
      border-radius: 10px;
    }

    div.textIA {
      color: black !important;
      font-size: 20px;
      font-family: futura-pt, sans-serif;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 7em;
      font-weight: 500;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      opacity: 1;
    }

    div.textIA a, div.textIA:active a, div.textIA:focus a, div.textIA {
      color: black;
      text-decoration: none;
      cursor: pointer !important;
    }

    /* other arrow */

    button.arrowIA2 {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 2600%;
      left: 50%; */
      position: static;
      margin-left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer !important;
    }

    button.arrowIA2:hover .arrRef, button.arrowIA2:focus .arrRef, button.arrowIA2:active .arrRef {
      opacity: 0;
    }
      
    button.arrowIA2:hover .middleWire, button.arrowIA2:focus .middleWire, button.arrowIA2:active .middleWire {
      opacity: 1;
    }

    /* reflection */

    img.reflectionImage {
      display: none;
    }

    div.container7 {
      margin-left: 0px;
      padding-left: 0px;
    }

    div.container7 h1, div.container7 p {
      padding-left: 42px;
    }

    div.container7 h1 {
      margin-bottom: 20px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      color: #333333;
    }

    div.container7 p {
      width: 18em;
    }

    /* footer */

    #backTopB {
      background-color: #D9D3F2;
      border: none;
      /*width: 100px;*/
      border-radius: 50%;
      height: 50px;
      width: 50px;
      outline: none;
      /*bottom: 0;*/
      bottom: 65px;
      left: 290px;
      right: 5px;
      position: fixed;
      padding: 0px;
      display: block;
    }

    div.backTop2 {
      background-color: transparent;
      outline: none;
     /* padding-bottom: 0px;
      margin-bottom: 0px;
      margin-right: 5px;*/
    }

    img.backTop {
      width: 50px;
      height: 50px;
      margin-bottom: 5px;
      padding-bottom: 0px;
      background-color: transparent;
    }  

    div.bottomLinks {
      /*display: flex !important;
      flex-direction: row;
      align-items: center;*/
      background-color: #D9D3F2;
      width: 100%;
      height: 6.5em;
      border: none;
    }

    div.email2 {
      /*margin-right: 95px;*/
      padding-bottom: 5px;
      padding-top: 35px;
      font-size: 20px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      flex-grow: 1;
    }

    div.email2 a {
      font-weight: 500;
      text-decoration: none;
    }

    img.backTop {
      width: 50px;
      height: 50px;
      margin-bottom: 0px;
      background-color: transparent;
    }  

    div.credit {
      text-align: center;
      padding-top: 10px;
      align-self: flex-end;
      padding-bottom: 5px;
      display: block;
      padding-left: 60px;
      padding-right: 60px;
    }

    div.credit p {
      margin-bottom: 0px;
    }

    div.footer {
      height: 9.1em;
      margin-top: 2em;
      display: block;
    }    

    div.email2 a:hover, div.email2 a:focus, div.email2 a:active {
      text-decoration: none;
      opacity: 0.5;
    }

    div.plink {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding-top: 40px;
      margin-bottom: 0px;
    }

    div.nextP {
      font-family: 'europa', sans-serif;
      margin-bottom: 1em;
      font-size: 16px;
    }
    
    div.pName {
      font-size: 32px;
      font-family: 'Castoro', sans-serif;
      color: #333333;
      margin-bottom: 0px;
    }

    div.pName a {
      color: #333333;
      text-decoration: none;
      padding-left: 15px;
      opacity: 0.8;
    }

    img.footLink {
      padding-left: 2px;
    }

    div.pName a:hover, img.footLink:hover, div.pName a:focus, div.pName a:active, img.footLink:active, img.footLink:focus {
      text-decoration: none;
      opacity: 0.8;
    }

  }


  @media (min-height: 730px) and (min-width: 370px) { /* iphone x and above ? */

    img.solaceHome { 
      margin-top: 30px; /* used to be 15px */
      display: block;
      width: 375px;
      height: auto;
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
    }

    div.iframe3, img.iframe3, img.solaceHome2, div.safeVid video.smallSafe, div.video video.smallShel, div.video2 video.smallSafe2, div.video1 video.smallOn {
      display: none;
    }

    div.safeVid video.normSafe, div.video video.normShel, div.video2 video.normSafe2, div.video1 video.normOn {
      display: block;
    }

    div.callVid, div.planVid {
      display: none;
    }

   /* button.arrow2 {
      top: 90%; 
    }*/

    button.arrow2 {
      width: 70px;
    }

    div.container2 {
      padding-top: 6.5em; /* 7em */
      padding-bottom: 192px; /* 12.5em */
    }

    div.centered h2 {
      margin-bottom: 15px;
    }

    div.container3 {
      height: 58em; /* 53 */
      padding-top: 10em; /* 6em */
    }

    button.arrow3 {
      top: 260%; /*200 */
    }

    div.container4 {
      padding-top: 35px; /* 7em, 50px */
    }

    div.container4 .textSide {
      padding-top: 2em;
      padding-bottom: 0em; /* changed !!!! */
    }

    .textSide p {
      margin-bottom: 1.5em;
      font-size: 18px;
      width: 17em; /* 30em */
      line-height: 26px; /* added */
    }

    button.arrow4 {
      top: 300%; /* 305 */
    }

    div.quote {
      /*margin-top: 4.5em; *//* make sure these work later */
      padding-top: 6em; /* 9em */
      margin-top: 0em;
    }

    blockquote.b1::before {
      left: 25px;
    }

    blockquote.b1 {
      border-right:8px solid #78C0A8;
      font-size: 1.6em;
      line-height: 1.7;
    }

    button.arrowQuote {
      top: 390%; /* 406 */
      background-color: transparent;
      border: none;
    }

    div.features {
      /*margin-top: 71.5em;*/ /* 4.5em or 52.5em */ /* make it margin */
      padding-top: 5em;
      height: auto;
      /*padding-bottom: 5em;*/ /* added */
      padding-bottom: 1em;
    }

    div.features h2 {
      padding-bottom: 0.6em;
    }

    div.features h1.fea {
      margin-bottom: 15px;
    }

    div.onP p, div.shelP p, div.messP p, div.safetyP p {
      width: 17em;
    }

    div.onP {
      /*margin-bottom: 13.5em;*/ /* 5em */
      margin-bottom: 1em;
      line-height: 37px; /* check and make sure this works!!! */
      height: auto; /* new */
      /* check padding top 25px */
    }

    div.onP h2 {
      margin-top: 10px;
    }

    button.arrowVid {
      top: 482%;
    }

    div.video1 {
      padding-top: 6em; /* 7em */
      margin-top: 0em; /* changed */
    }

    p.onboardP {
      font-weight: 500;
    }

    button.arrow5 {
      top: 575%;/* 600 */
    }
    
    div.safescreen {
      /*margin-top: 8em;*/ /* padding-top 8em */
      padding-top: 4em; /* 4.8em */
    }

    div.safeP {
      line-height: 37px;
      /*height: 36em;*/
      height: auto;
      /* check padding top 25px */
    }

    button.arrowVid2 {
      top: 668%;/* 685 */
    }

    div.video2 {
      /*margin-top: 23em;*/
      /*padding-top: 8em;*/
      padding-top: 6em;
      margin-top: 0em;
    }

    button.arrow6 {
      top: 753%; /* 506, 753 */
    }

    div.shelters {
      padding-top: 4em; /* was 5em */
      margin-top: 0em; /* added */
    }

    div.shelP {
      line-height: 37px;
      /*height: 38em; *//* 26em */
      height: auto;
      padding-top: 25px; /* check this!! */
    }

    button.arrowVid3 {
      top: 846%; /* 606, 860 */
    }

    div.video {
      padding-top: 7em;
      margin-top: 0em; /* added */
    }

    button.arrow7 {
      top: 938%; /* 606, 948 */
    }
    
    div.message {
      /*padding-top: 3.7em; 
      margin-top: 2.1em;*/
      padding-top: 3em;
      margin-top: 5em;
      margin-bottom: 2em;
    }

    div.messP {
      padding-top: 25px;
    }

    div.messP, div.messP p {
      line-height: 36px;
    }

    button.arrowVid4 {
      top: 1032%; /* 606, 1042 */
    }

    div.messVid {
      padding-top: 6em; /* 7em */
      /*margin-top: 10em;*/
      margin-top: 0em;
    }

    button.arrow8 {
      top: 1125%; /* 706, 1138 */
    }

    div.safety {
      padding-top: 3em;
      /*margin-top: 3em;*/
      margin-top: 0em;
    }
    
    div.safetyP, div.safetyP p {
      line-height: 37px;
    }

    div.safetyP {
      /*height: 26em;*/
      height: auto;
    }

    button.arrowVid5 {
      top: 1220%; /* 606 */
    }

    div.safeVid {
      /*margin-top: 22em;*/
      margin-top: 1em;
      /*padding-top: 7em;*/
      padding-top: 1em;
    }

    button.arrow9 {
      top: 1312%; /* 808, 1402 */
    }

    button.arrowRes {
      top: 1500%;
    }

    div.container5 {
      /*padding-top: 5em;*/
      padding-top: 2em;
      /*padding-bottom: 10em;  */
      padding-bottom: 2em;
    }

    div.research p.int {
      width: 17em;
    }

    div.interview {
      padding-top: 5em;
      /*margin-top: 3em;*/
      margin-top: 0em;
    }

    .takeMid {
      left: 23.2%;
    }

    button.arrowRes2 {
      top: 1700%;
    }

    div.statement {
      margin-top: 3em;
      padding-top: 5em;
    }

    div.quote2 {
      margin-top: 2em;
    }

    div.problem h3 {
      width: 15em;
    }

    blockquote.b2 {
      font-size: 1.5em;
      line-height: 1.8;
    }

    button.arrowDes {
      display: block;
      top: 1795%;
    }

    div.designC {
      margin-top: 2em;
      padding-top: 6em;
    }

    div.designC h4 {
      width: 16em;
      font-size: 19px;
      line-height: 30px; /* changed */
      margin-bottom: 1em; /* changed */
    }

    div.designC b {
      color: #78C0A8;
    }

    button.arrowRes3 {
      top: 1885%;
    }

    div.ideation {
      padding-top: 5em;
    }

    div.ideateIntro {
      height: auto;
    }

    p.ideate {
      width: 15.5em;
      line-height: 35px;
      font-size: 18px;
    }

    button.arrowPro {
      top: 1990%;
      left: 50%;
    }

    div.wireframeFull {
      height: auto;
    }

    div.iframe {
      display: none;
    }

    div.iframe3 {
      display: block;
    }

    div.proto {
      margin-left: 5em;
      /*padding-top: 8em;
      margin-top: 3em;*/
      padding-top: 6em;
      margin-top: 0em;
    }

    button.arrowWire {
      display: block;
      /*top: 2078%;*/
      position: static;
      margin-left: 50%;
      margin-top: 2em; /* added */
      background-color: transparent !important;
      border: none;
      cursor: pointer !important;
    }

    div.wireframes {
      /*margin-top: 12em;*/
      margin-top: 5em;
      padding-top: 4em;
      height: auto;
      /*padding-bottom: 7em;*/ /* added */
      padding-bottom: 3em;
    }

    div.row1, div.row2, div.row3 {
      padding-left: 0px;
      justify-content: center;
    }

    button.arrowIA {
      /*top: 2327%;*/
      margin-top: 2em;
    }

    div.IAFull {
      padding-top: 5em;
      padding-bottom: 4em;
    }

    button.arrowIA2 {
      top: 2383%;
    }
    
    div.container7 {
      padding-top: 3em;
    }

    div.container7 p {
      width: 16em;
      font-size: 18px;
    }

    div.footer {
      height: 9.4em;
    }

    #backTopB {
      left: 290px;
    }

  }

  @media (min-width: 410px) and (max-width: 415px) and (min-height: 890px) { /* iphone 11 */

    div.callVid, div.planVid {
      display: none;
    }

  }

  @media only screen and (device-width: 375px) and (device-height: 812px) { /* iphone 11 pro */

    img.solaceHome { 
      margin-top: 30px; /* used to be 15px */
      display: block;
      width: 375px;
      height: auto;
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
    }

    div.iframe3, img.iframe3, img.solaceHome2, div.safeVid video.smallSafe, div.video video.smallShel, div.video2 video.smallSafe2, div.video1 video.smallOn {
      display: none;
    }

    div.callVid, div.planVid {
      display: none;
    }

    div.callVid video, div.planVid video {
      display: none;
    }

    div.safeVid video.normSafe, div.video video.normShel, div.video2 video.normSafe2, div.video1 video.normOn {
      display: block;
    }

    button.arrow2 {
      width: 70px;
    }

    div.container2 {
      padding-top: 6.5em; /* 7em */
      padding-bottom: 192px; /* 12.5em */
    }

    div.centered h2 {
      margin-bottom: 15px;
    }

    div.container3 {
      height: 58em; /* 53 */
      padding-top: 10em; /* 6em */
    }

    button.arrow3 {
      top: 260%; /*200 */
    }

    div.container4 {
      padding-top: 35px; /* 7em, 50px */
    }

    div.container4 .textSide {
      padding-top: 2em;
      padding-bottom: 0em; /* changed !!!! */
    }

    .textSide p {
      margin-bottom: 1.5em;
      font-size: 18px;
      width: 17em; /* 30em */
      line-height: 26px; /* added */
    }

    button.arrow4 {
      top: 385%; /* 305 */
    }

    div.quote {
      /*margin-top: 4.5em; *//* make sure these work later */
      padding-top: 6em; /* 9em */
      margin-top: 0em;
    }

    blockquote.b1::before {
      left: 25px;
    }

    blockquote.b1 {
      border-right:8px solid #78C0A8;
      font-size: 1.6em;
      line-height: 1.7;
    }

    button.arrowQuote {
      background-color: transparent;
      border: none;
      top: 490%; /* 406 */
    }

    div.features {
      padding-top: 5em;
      height: auto;
      padding-bottom: 2em;
    }

    div.features h2 {
      padding-bottom: 0.6em;
    }

    div.features h1.fea {
      margin-bottom: 15px;
    }

    div.onP p, div.shelP p, div.messP p, div.safetyP p {
      width: 17em;
    }

    div.onP {
      margin-bottom: 1em;
      line-height: 37px; 
      height: auto;
    }

    div.onP h2 {
      margin-top: 10px;
    }

    button.arrowVid {
      top: 620%;
    }

    div.video1 {
      padding-top: 6em; /* 7em */
      margin-top: 0em; /* 1em */
    }

    p.onboardP {
      font-weight: 500;
    }

    button.arrow5 {
      top: 725%;/* 600 */
    }
    
    div.safescreen {
      padding-top: 4em; /* 4.8em */
    }

    div.safeP {
      line-height: 37px;
      /*height: 36em;*/
      height: auto;
      /* check padding top 25px */
    }

    button.arrowVid2 {
      top: 668%;/* 685 */
    }

    div.video2 {
      /*margin-top: 23em;*/
      /*padding-top: 8em;*/
      padding-top: 6em;
      margin-top: 0em;
    }

    button.arrow6 {
      top: 753%; /* 506, 753 */
    }

    div.shelters {
      padding-top: 4em; /* was 5em */
      margin-top: 0em; /* added */
    }

    div.shelP {
      line-height: 37px;
      /*height: 38em; *//* 26em */
      height: auto;
      padding-top: 25px; /* check this!! */
    }

    button.arrowVid3 {
      top: 846%; /* 606, 860 */
    }

    div.video {
      padding-top: 7em;
    }

    button.arrow7 {
      top: 938%; /* 606, 948 */
    }
    
    div.message {
      /*padding-top: 3.7em; 
      margin-top: 2.1em;*/
      padding-top: 3em;
      /*margin-top: 5em;*/
      margin-top: 0em;
      margin-bottom: 2em;
    }

    div.messP {
      padding-top: 25px;
    }

    div.messP, div.messP p {
      line-height: 36px;
    }

    button.arrowVid4 {
      top: 1032%; /* 606, 1042 */
    }

    div.messVid {
      padding-top: 6em; /* 7em */
      /*margin-top: 10em;*/
      margin-top: 0em;
    }

    button.arrow8 {
      top: 1125%; /* 706, 1138 */
    }

    div.safety {
      padding-top: 3em;
      /*margin-top: 3em;*/
      margin-top: 0em;
    }
    
    div.safetyP, div.safetyP p {
      line-height: 37px;
    }

    div.safetyP {
      /*height: 26em;*/
      height: auto;
    }

    button.arrowVid5 {
      top: 1220%; /* 606 */
    }

    div.safeVid {
      /*margin-top: 22em;*/
      margin-top: 0em;
      padding-top: 6em; /* 7em */
    }

    button.arrow9 {
      top: 1312%; /* 808, 1402 */
    }

    button.arrowRes {
      top: 1500%;
    }

    div.container5 {
      /*padding-top: 5em;*/
      padding-top: 2em;
      /*padding-bottom: 10em; */
      padding-bottom: 2em;
    }

    div.research p.int {
      width: 17em;
    }

    div.interview {
      padding-top: 5em;
      margin-top: 3em;
    }

    .takeMid {
      left: 23.2%;
    }

    button.arrowRes2 {
      top: 1700%;
    }

    div.statement {
      margin-top: 3em;
      padding-top: 5em;
    }

    div.quote2 {
      margin-top: 2em;
    }

    div.problem h3 {
      width: 15em;
    }

    blockquote.b2 {
      font-size: 1.5em;
      line-height: 1.8;
    }

    button.arrowDes {
      display: block;
      top: 1795%;
    }

    div.designC {
      margin-top: 2em;
      padding-top: 6em;
    }

    div.designC h4 {
      width: 16em;
      font-size: 19px;
      line-height: 30px; /* changed */
      margin-bottom: 1em; /* changed */
    }

    div.designC b {
      color: #78C0A8;
    }

    button.arrowRes3 {
      top: 1885%;
    }

    div.ideation {
      padding-top: 5em;
    }

    div.ideateIntro {
      height: auto;
    }

    p.ideate {
      width: 15.5em;
      line-height: 35px;
      font-size: 18px;
    }

    button.arrowPro {
      top: 1990%;
      left: 50%;
    }

    div.wireframeFull {
      height: auto;
    }

    div.iframe {
      display: none;
    }

    div.iframe3 {
      display: block;
    }

    div.proto {
      margin-left: 5em;
      /*padding-top: 8em;
      margin-top: 3em;*/
      padding-top: 6em;
      margin-top: 0em;
    }

    button.arrowWire {
      display: block;
      background-color: transparent !important;
      border: none;
      cursor: pointer !important;
      margin-top: 2em;
    }

    div.wireframes {
      /*margin-top: 12em;*/
      margin-top: 5em;
      padding-top: 4em;
      height: auto;
      /*padding-bottom: 7em; */
      padding-bottom: 3em;
    }

    div.row1, div.row2, div.row3 {
      padding-left: 0px;
      justify-content: center;
    }

    button.arrowIA {
      /*top: 2327%;*/
      margin-top: 2em;
    }

    div.IAFull {
      padding-top: 5em;
      padding-bottom: 4em;
    }

    button.arrowIA2 {
      top: 2383%;
    }
    
    div.container7 {
      padding-top: 3em;
    }

    div.container7 p {
      width: 16em;
      font-size: 18px;
    }

    div.footer {
      height: 9.4em;
    }

    #backTopB {
      left: 310px;
    }

  }

  @media (height: 736px) and (min-width: 412px) and (max-width: 416px) { /* iphone 6/7/8 plus*/

    img.solaceHome {
      display: none;
    }

    div.iframe3, img.iframe3 {
      display: none;
    }

    div.safeVid video.normSafe, div.video video.normShel, div.video2 video.normSafe2, div.video1 video.normOn {
      display: block;
    }

    div.safeVid video.smallSafe, div.video video.smallShel, div.video video.smallSafe2, div.video1 video.smallOn {
      display: none;
    }

    img.solaceHome2 {
      width: 100%;
      height: auto;
      margin-top: 30px; /* used to be 6px */
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1em;
      display: block;
    }

    div.container3 {
      padding-top: 6em;
    }

    div.container2 {
      padding-top: 0px;
      height: 53em;
      padding-bottom: 20px;
    }

    div.titleContent {
      margin-top: 5em;
    }

    button.arrow3 {
      top: 193%;
    }

    div.centered h2 {
      font-size: 17px;
      margin-bottom: 0px;
      margin-top: 6px;
    }

    div.container3 {
      padding-top: 5em;
      height: 53em;
    }

    div.container4 {
      padding-top: 30px;
      height: auto;
      padding-bottom: 30px; /* maybe take out! */
    }

    div.container3 div h4 {
      font-size: 23px;
    }

    div.container3 div h5 {
      font-size: 20px;
    }

    .affil div, .timeline div {
      width: 12em;
    }

    div.container4 .textSide {
      padding-top: 30px;
      padding-bottom: 20px;
      flex-grow: 0;
    }

    div.textSide h1 {
      margin-bottom: 20px;
    }

    div.textSide p {
      margin-bottom: 10px;
      line-height: 30px;
      width: 19em;
      font-size: 18px;
    }

    button.arrow4 {
      top: 295%;
    }

    div.quote {
      padding-top: 4em;
    }

    button.arrowQuote {
      top: 385%;
      background-color: transparent;
      border: none;
    }

    blockquote.b1 {
      margin-right: 0px;
      margin-left: 20px;
      font-size: 1.5em;
    }

    div.features {
      padding-top: 6em;
      margin-top: 0px;
      padding-bottom: 0px;
      height: auto;
    }

    div.features h1.fea {
      margin-bottom: 10px;
      margin-top: 0px;
    }

    div.videos p {
      width: 19em;
      font-size: 18px;
    }

    div.onP, div.messP p { /* try to make this global ? */
      line-height: 32px;
      /*font-size: 17px;*/
    }

    div.onP {
      margin-bottom: 0px;
      height: 32em;
    }

    button.arrowVid {
      top: 475%;
    }

    div.video2 {
      padding-top: 5em;
      margin-top: 3em;
    }

    div.video1 {
      padding-top: 4em;
      margin-top: 0px;
    }

    button.arrow5 {
      top: 568%;
    }

    div.safescreen {
      padding-top: 3em; /* 1em */
    }

    div.safeP, div.shelP {
      line-height: 35px;
      font-size: 18px;
    }

    div.shelP p {
      width: 17em;
    }

    div.safeP p, div.shelP p {
      font-size: 18px;
      /*width: 18em;*/
    }

    div.safeP {
      height: auto;
    }

    button.arrowVid2 {
      top: 653%;
    }

    button.arrow6 {
      top: 748%;
    }

    div.video {
      margin-top: 0px;
      padding-top: 4em;
    }

    div.shelters {
      padding-top: 3em;
      margin-top: 4em;
    }

    button.arrowVid3 {
      top: 840%;
    }

    button.arrow7 {
      top: 937%;
    }

    div.message {
      margin-top: 4em;
      padding-top: 3em;
    }

    button.arrowVid4 {
      top: 1030%;
    }

    div.messVid {
      margin-top: 30px;
      padding-top: 5em
    }

    button.arrow8 {
      top: 1120%;
    }

    div.safety {
      margin-top: 2em;
      padding-top: 2em;
      /*padding-bottom: 7em;*/
      padding-bottom: 0em;
    }

    div.safetyP {
      line-height: 32px;
      height: 38em;
    }

    div.safetyP p {
      line-height: 32px;
      font-size: 18px;
      width: 17em
      /*width: 17em;*/
    }

    button.arrowVid5 {
      top: 1214%;
    }

    div.safeVid {
      padding-top: 5em;
      margin-top: 1em;
    }

    button.arrow9 {
      top: 1307%;
    }

    /* adding stuff from previous */

    div.container5 h1 {
      margin-left: 42px;
      margin-top: 20px;
      padding-left: 0px;
    }

    div.research {
      margin-left: 42px;
      padding-left: 0px;
      flex-direction: column;
      /* make this white ??? */
    }

    div.research p {
      width: 19em;
    }

    div.container5 {
      padding-left: 0px;
      padding-top: 4em;
      margin-top: 0px;
      /*height: 301em;
      padding-bottom: 0px;*/
      height: auto;
      padding-bottom: 6em;
    }

    div.research p.int {
      width: 17em;
      font-size: 18px;
    }

    /* arrow */

    button.arrowRes {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 1518%; 
      left: 50%; */
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
    }
    
    .middleRes {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textRes a:hover, .textRes a:focus, .textRes a, .textRes {
      color: black;
      cursor: pointer;
      text-decoration: none;
    }
        
    button.arrowRes:hover .arrRes, button.arrowRes:focus .arrRes {
      opacity: 0;
    }
      
    button.arrowRes:hover .middleRes, button.arrowRes:focus .middleRes {
      opacity: 1;
    }
        
    div.textRes {
      color: black;
      font-size: 16px;
      font-family: 'futura-pt', sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* research takeaways */

    div.interview {
      padding-left: 0px;
      margin-left: 0px;
      padding-top: 4em;
      margin-top: 2em;
    }

    div.interview h2, div.interview h3 {
      margin-left: 42px;
      padding-left: 0px;
      text-align: left;
    }

    h3.resSub {
      font-weight: 400;
    }

    /* circles */

    div.circle1 {
      opacity: 1;
      backface-visibility: visible;
      text-align: center;
      position: relative;
      /*margin-left: 3em;*/
      margin-top: 3em;
      /*margin-right: 1em;*/
      margin-bottom: 3em;
    }

    div.circle1 img {
      border-radius: 50%;
      width: 200px;
      height: 200px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      opacity: 1; 
    }

    .takeText {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }

    .takeMid {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 25.8%; /* new */
      right: 0;
      width: 200px;
      opacity: 0;
      transition: 0.5s ease;
      background-color: #f0f0f0;
      color: black;
      border-radius: 50%;
    }
    
    .circle1:hover .takeMid, .circle1:focus .takeMid {
      opacity: 0.97;
    }

    .takeMid p {
      width: 160px;
    }
    
    .takeText:hover, .takeText:focus, .takeText, .takeText:active {
      color: black;
      text-decoration: none;
      opacity: 1;
    }

    /* next arrow */

    button.arrowRes2 {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 1733%; 
      left: 50%; */
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
    }
    
    .middleRes2 {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textRes2 a:hover, .textRes2 a:focus, .textRes2 a, .textRes2 {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
        
    button.arrowRes2:hover .arrRes2, button.arrowRes2:focus .arrRes2 {
      opacity: 0;
    }
      
    button.arrowRes2:hover .middleRes2, button.arrowRes2:focus .middleRes2 {
      opacity: 1;
    }
        
    div.textRes2 {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* problem statement */

    div.statement {
      padding-left: 0px;
      margin-left: 0px;
      margin-top: 3em;
      padding-top: 4em;
      display: flex;
      flex-direction: column;
      height: auto;
      background-color: #f0f0f0;
    }

    div.statement h2 {
      margin-bottom: 10px;
      padding-bottom: 0px;
    }

    div.problem h3 {
      width: 13em;
      font-weight: 500;
      line-height: 29px;
      padding-bottom: 0px;
      margin-bottom: 0px;
      margin-top: 15px;
    }

    div.fullProblem {
      display: flex;
      flex-direction: column;
      /* justify-content: center; ? */
    }

    /* quote */

    div.quote2 {
      flex-grow: 1;
      margin-top: 1em;
      background-color: #f0f0f0;
    }
  
    blockquote.b2 {
      font-size: 1.5em;
      width:17em; /* 25em */
      margin: 30px auto;
      margin-top: 10px;
      margin-left: 0;
      font-family: Europa, sans-serif;
      color: #555555;
      padding:1.2em 30px 1.2em 75px;
      border-right:8px solid #78C0A8;
      line-height:1.6;
      position: relative;
      border-left: none;
    }
  
    div.quote2 a {
      color: #333333;
    }
    
    blockquote.b2::before {
      font-family: Arial;
      content: "\201C";
      color:#78C0A8;
      font-size:4em;
      position: absolute;
      left: 25px;
      top:-10px;
    }
    
    blockquote.b2::after {
      content: '';
    }
    
    blockquote.b2 span{
      display:block;
      color:#333333;
      font-style: normal;
      font-weight: bold;
      margin-top: 1em;
    } 

    /* next arrow */

    button.arrowDes {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 1825%; 
      left: 50%; */
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
      display: block;
    }
    
    .middleDes {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textDes a:hover, .textDes a:focus, .textDes a, .textDes a:active, .textDes {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
        
    button.arrowDes:hover .arrDes, button.arrowDes:focus .arrDes, button.arrowDes:active .arrDes {
      opacity: 0;
    }
      
    button.arrowDes:hover .middleDes, button.arrowDes:focus .middleDes, button.arrowDes:active .middleDes {
      opacity: 1;
    }
        
    div.textDes {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* next part */

    div.designC {
      padding-top: 4em; /* 3em */
      margin-top: 2em;
      padding-left: 0px;
    }

    div.designC h3 {
      width: 13em;
      font-weight: 500;
      line-height: 29px;
      padding-bottom: 0px;
      margin-bottom: 30px;
      margin-top: 15px;
    }

    div.designC h4 {
      padding-left: 42px;
      line-height: 30px;
      font-size: 19px;
      width: 18em;
    }

    div.designC b {
      color: #78C0A8;
    }

    /* arrow */

    button.arrowRes3 {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 1920%; 
      left: 50%;*/
      position: static; 
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
    }
    
    .middleRes3 {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textRes3 a:hover, .textRes3 a:focus, .textRes3 a, .textRes3 {
      color: black;
      cursor: pointer;
      text-decoration: none;
    }
        
    button.arrowRes3:hover .arrRes3, button.arrowRes3:focus .arrRes3 {
      opacity: 0;
    }
      
    button.arrowRes3:hover .middleRes3, button.arrowRes3:focus .middleRes3 {
      opacity: 1;
    }
        
    div.textRes3 {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

   /* ideation */

    div.ideation {
      padding-top: 3em;
      /*margin-top: 1em;*/
    }

    /* intro & prototype */

    div.ideateIntro {
      padding-left: 42px;
      margin-left: 0px;
      height: 40em;
    }

    div.ideateIntro h1 {
      margin-bottom: 20px;
    }

    p.ideate {
      width: 17em;
      font-size: 18px;
      line-height: 32px;
    }

    /* arrow */

    button.arrowPro {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 2018%; 
      left: 50%; */
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
      display: block;
    }
    
    .middlePro {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textPro a:hover, .textPro a:focus, .textPro a, .textPro a:active, .textPro {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
        
    button.arrowPro:hover .arrPro, button.arrowPro:focus .arrPro, button.arrowPro:active .arrPro {
      opacity: 0;
    }
      
    button.arrowPro:hover .middlePro, button.arrowPro:focus .middlePro, button.arrowPro:active .middlePro {
      opacity: 1;
    }
        
    div.textPro {
      color: black;
      font-size: 16px;
      font-family: 'futura-pt', sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* */

    div.interact, div.interArrow {
      display: none;
    }

    /* prototype !! */

    div.wireframeFull {
      height: 90em;
    }

    div.proto {
      padding-top: 8em;
      margin-left: 6em;
      margin-top: 1em;
    }

    button.arrowWire {
      display: none;
    }
    
    /*div.protoFull {
      top: 1001em;
      position: absolute;
    }*/

    div.iframe {
      display: none;
    }

    div.iframe2 { 
      display: block;
    }

    img.topFull {
      /*width: 508px;*/
      width: 415px;
      height: auto;
      position: absolute;
      /*left: -9.25em;*/
      left: -5.9em;
      /*top: -5.3em;*/
      top: -7em;
      /*left: 67.85em;*/
    /*top: 583em;*/ /* 590em */
    }

    img.leftFull {
      position: absolute;
      /*left: 68.6em;*/ /* 68.45 */
      /*top: 593.3em;*/ /* 600.27em */
      height: 493px; /* 515px */
      width: auto;
      top: 0.6em;
      /*left: -8.61em;*/
      left: -5.85em;
    }

    img.rightFull {
      position: absolute;
      width: auto;
      /*top: 593.3em;*/ /* 600.27em */
      height: 493px; /* 515px */
      /*left: 94.8em;*/ /* 94.75 */
      /*left: 17.69em;*/
      left: 17em;
      top: 0.6em;
     }

    img.bottomFull {
      position: absolute;
      /*width: 499.8px;*/
      width: 415px;
      height: auto;
      /*left: 68.45em;*/
      /*top: 629.4em;*/ /* 637em */
      top: 35.8em;
      /*left: -8.6em;*/
      left: -5.9em;
    }

    /* arrow */

    button.arrowWire {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 2116%;
      left: 50%; */
      position: static;
      margin-left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
      display: block;
      background-color: transparent !important;
      border: none;
      display: none;
    }
    
    .middleWire {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textWire a:hover, .textWire a:focus, .textWire a, .textWire {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
        
    button.arrowWire:hover .arrWire, button.arrowWire:focus .arrWire {
      opacity: 0;
    }
      
    button.arrowWire:hover .middleWire, button.arrowWire:focus .middleWire {
      opacity: 1;
    }
        
    div.textWire {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 7em;
      font-weight: 400;
    }

    /* wireframes */

    div.wireframes {
      padding-top: 4em;
      background-color: #f0f0f0;
      margin-top: 7em;
      height: auto;
    }

    div.wireframes h2 {
      padding-left: 42px;
      margin-left: 0px;
    }

    div.wireframes h3 {
      text-align: left;
      padding-left: 42px;
    }

    div.row1, div.row2, div.row3 {
      display: flex;
      flex-direction: row;
      padding-left: 0px;
      justify-content: center;
      flex-wrap: wrap;
    }

    img.arrowLong3 {
      display: none;
    }

    div.cont1, div.cont2, div.cont3, div.cont4, div.shel1, div.shel2, div.hot1, div.hot2, div.safe1, div.safety2, div.safety3, div.safety4 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img.contwire, img.contwire2, img.contwire4, img.contwire6, img.shelwire1, img.shelwire2, img.hotwire1, img.hotwire2,
    img.safewire1, img.safewire2, img.safewire3, img.safewire4 {
      width: 10em;
      height: auto;
    }

    /* arrow */

    button.arrowIA {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 2405%; 
      left: 50%; */
      position: static;
      margin-left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
    }

    button.arrowIA:hover .arrWire, button.arrowIA:focus .arrWire, button.arrowIA:active .arrWire {
      opacity: 0;
    }
      
    button.arrowIA:hover .middleWire, button.arrowIA:focus .middleWire, button.arrowIA:active .middleWire {
      opacity: 1;
    }

    /* IA */

    div.IAFull {
      padding-top: 4em;
      padding-bottom: 8em;
    }

    div.IAFull h2 {
      padding-left: 30px;
      padding-bottom: 1.5em;
      margin-left: 0px;
    }

    img.IA {
      width: 22em;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      height: auto;
    }

    div.IAEnl, img.IA {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      position: relative;
    }

    div.innerText {
      opacity: 0.8;
      transition: .5s ease;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      background-color: #f0f0f0;
      width: 15em;
      height: 5em;
      border-radius: 10px;
    }

    div.textIA {
      color: black !important;
      font-size: 20px;
      font-family: futura-pt, sans-serif;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 7em;
      font-weight: 500;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      opacity: 1;
    }

    div.textIA a, div.textIA:active a, div.textIA:focus a, div.textIA {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    /* other arrow */

    button.arrowIA2 {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 2465%; 
      left: 50%; */
      position: static;
      margin-left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
    }

    button.arrowIA2:hover .arrRef, button.arrowIA2:focus .arrRef, button.arrowIA2:active .arrRef {
      opacity: 0;
    }
      
    button.arrowIA2:hover .middleWire, button.arrowIA2:focus .middleWire, button.arrowIA2:active .middleWire {
      opacity: 1;
    }

    /* reflection */

    img.reflectionImage {
      display: none;
    }

    div.container7 {
      margin-left: 0px;
      padding-left: 0px;
    }

    div.container7 h1, div.container7 p {
      padding-left: 42px;
    }

    div.container7 h1 {
      margin-bottom: 20px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      color: #333333;
    }

    div.container7 p {
      width: 20em;
    }

    /* footer */

    #backTopB {
      background-color: #D9D3F2;
      border: none;
      /*width: 100px;*/
      border-radius: 50%;
      height: 50px;
      width: 50px;
      outline: none;
      bottom: 63px;
      padding: 0px;
      left: 325px;
    }

    div.backTop2 {
      background-color: transparent;
      outline: none;
      position: fixed;
      padding-bottom: 0px;
      margin-bottom: 0px;
      margin-right: 5px;
    }

    img.backTop {
      width: 80px;
      height: 80px;
      margin-bottom: 5px;
      padding-bottom: 0px;
      background-color: transparent;
    }  

    div.bottomLinks {
      background-color: #D9D3F2;
      width: 100%;
      height: 6.7em;
      border: none;
    }

    div.email2 {
      padding-bottom: 5px;
      padding-top: 35px;
      font-size: 20px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      flex-grow: 1;
    }

    div.email2 a {
      font-weight: 500;
      text-decoration: none;
    }

    img.backTop {
      width: 50px;
      height: 50px;
      margin-bottom: 0px;
      background-color: transparent;
    }  

    div.credit {
      text-align: center;
      padding-top: 10px;
      align-self: flex-end;
      padding-bottom: 5px;
      display: block;
    }

    div.credit p {
      margin-bottom: 0px;
    }

    div.footer {
      height: 9.4em;
      margin-top: 2em;
      display: block;
    }    

    div.email2 a:hover, div.email2 a:focus {
      text-decoration: none;
      opacity: 0.5;
    }

    div.plink {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding-top: 2em;
      margin-bottom: 0px;
    }

    div.nextP {
      font-family: 'europa', sans-serif;
      margin-bottom: 1em;
      font-size: 16px;
    }
    
    div.pName {
      font-size: 32px;
      font-family: 'Castoro', sans-serif;
      color: #333333;
      margin-bottom: 0px;
    }

    div.pName a {
      color: #333333;
      text-decoration: none;
      padding-left: 15px;
      opacity: 0.8;
    }

    img.footLink {
      padding-left: 2px;
    }

    div.pName a:hover, img.footLink:hover, div.pName a:focus, img.footLink:focus {
      text-decoration: none;
      opacity: 0.8;
    }
  }

  @media (min-width: 410px) and (max-width: 415px) and (min-height: 895px) and (max-height: 897px) { /* iphone 11 pro max */


    div.iframe3, img.iframe3 {
      display: none;
    }

  }

  @media (min-width: 768px) { /* iPad */

    img.solaceHome, img.solaceHome2 {
      display: none;
    }  

    div.iframe3, img.iframe3 {
      display: none;
    }
    
    img.test {
      display: block !important;
    }

    div.container2 { 
      padding-bottom: 25.5em;
      display: flex;
      flex-direction: column;
      padding-top: 6em;
    }

    div.titleContent {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
    }

    div.titleSol {
      align-self: normal;
      padding-left: 0px;
    }

    div.centered { /* tried adding */
      display: flex;
      flex-direction: column;
      margin-right: 3em; /* added */
      margin-left: 5em;
      justify-content: center;
      margin-top: 8em;
    }

    .centered h1 {
      font-size: 40px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      color: #333333;
    }

    img.solaceH {
      width: 19em; /* 19 em */
      display: flex;
      margin-right: 2em;
    }

    .centered h2 {
      font-size: 20px !important;
      width: 17em; /* 19em */
      line-height: 40px;
    }

    div.titleSol img.logo {
      width: 60px;
      /*margin-top: 15px;*/
      height: 60px;
      margin-top: 10px;
      padding-top: 5px;
      padding-bottom: 2px;
    }

    div.titleSol img.test {
      /*width: 130px;
      margin-top: 15px;*/
      width: 130px;
      height: 55px;
      padding-top: 3px;
      padding-bottom: 2px;
      margin-top: 9px;
    }

    picture {
      padding-top: 10em;
      display: block; /* added */
    }

    /* added above */

    .centered h2 {
      text-align: left;
      display: block;
    }

    button.arrow4 {
      display: none;
    }

    button.arrowVid, button.arrowVid2, button.arrowVid3, button.arrowVid4, button.arrowVid5 {
      display: none;
    }

    div.safeVid video.normSafe, div.video video.normShel, div.video2 video.normSafe2, div.video1 video.normOn {
      display: block;
    }

    div.safeVid video.smallSafe, div.video video.smallShel, div.video video.smallSafe2, div.video1 video.smallOn {
      display: none;
    }

    div.container3 {
      margin-top: 0px; /* added for iPad */
      /*height: 73em;*/
      justify-content: center;
      padding-top: 0px;
    }

    div.larger h5 {
      line-height: 23px !important;
    }

    button.arrow3 {
      top: 180%;
    }

    div.container4 {
      padding-top: 40px;
      flex-direction: column;
      height: auto;
    }

    div.smallerCont {
      flex-direction: row;
      height: auto;
      align-items: normal;
      align-content: center;
      flex-wrap: nowrap;
      display: flex;
    }

    div.quote {
      flex-grow: 1;
    }

    div.textSide {
      padding-top: 0px;
      flex-grow: 1;
    }

    div.textSide h1 {
      margin-bottom: 20px;
    }

    ul.navbar-right {
      margin-right: 0px; 
    }

    div.textSide p {
      font-size: 19px; 
      line-height: 31px;
      width: 15.5em; 
    }

    blockquote.b1 {
      line-height: 1.8;
      margin-top: 0px; 
      font-size: 1.6em;
      width: 16em;
      padding-top: 70px; 
    }

    blockquote.b1::before {
      top: 15px;
      left: 18px;
    }

    div.features {
      margin-top: 0px;
      height: auto;
      padding-bottom: 3em; 
    }

    div.on, div.shel, div.safe2 {
      flex-direction: row-reverse;
    }

    div.onboarding {
      padding-top: 2em; 
    }

    button.arrow52, button.arrow72, button.arrow92, button.arrow6 {
      display: none;
    }

   div.video1, div.onP, div.video2, div.safeP, div.shelP, div.messP, div.safetyP, div.messVid, div.video, div.safeVid {
     display: flex;
     flex-grow: 1;
   }

   div.onP, div.safeP, div.shelP, div.messP, div.safetyP {
     flex-direction: column;
   }

   div.onP p, div.safeP p, div.shelP p, div.messP p, div.safetyP p {
     width: 18em;
     font-size: 18px;
   }

    div.onP {
      margin-bottom: 5em;
    }

    div.video1 { 
      margin-top: 0em;
      padding-top: 0em;
    }

    button.arrowQuote {
      top: 295%;
      background-color: transparent;
      border: none;
    }

    div.safescreen {
      padding-top: 0px;
      margin-top: 0px;
    }

    div.safe, div.mess {
      flex-direction: row-reverse;
    }

    div.video2 {
      margin-top: 3em;
      padding-top: 0em;
    }

    div.safeP p {
      padding-left: 0px;
      margin-left: 0px;
    }

    div.safescreen h2 {
      margin-left: 0px;
    }

    div.shelters {
      padding-top: 1em;
      margin-top: 4em;
    }

    div.video {
      padding-top: 1em;
      margin-top: 4em;
    }

    div.message {
      padding-top: 1em;
      margin-top: 2em;
    }

    div.messVid {
      padding-top: 1em;
      margin-top: 5em;
    }

    div.safeVid {
      padding-top: 1em;
      margin-top: 3em;
    }

    /* research */

    div.research {
      height: auto;
    }

    div.research p.int {
      width: 30em;
      font-size: 19px;
    } 

    h3.resSub {
      width: 18em;
      line-height: 33px;
    }

    div.takeaways {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 4em;
    }

    div.takeaways .circle1 {
      margin-right: 1.5em;
      margin-top: 0em;
      margin-bottom: 30px;
    }

    .takeMid {
      left: 0%; 
    }

    div.container5 {
      height: auto;
      padding-bottom: 5em;
    }

    div.fullProblem {
      display: flex;
    }

    div.designC, div.quote2 {
      flex-grow: 1;
    }

    div.quote2 {
      margin-top: 4em;
    }

    div.problem {
      flex-grow: 0;
    }

     div.designC h3 {
      width: 14em;
      line-height: 30px;
    }

    div.problem h3 {
      width: 15em;
      line-height: 30px;
    }

    div.designC h3 {
      margin-bottom: 4em;
    }

    blockquote.b2 {
      line-height: 1.8;
      border-right: none;
      font-size: 1.5em;
    }

    div.rectangle {
      height: 32em;
      background-color: #78C0A8;
      width: 6px;
      margin-top: 13em;
      border-radius: 5px;
      display: flex;
    }

    div.designC h4 {
      width: 15em;
      font-size: 19px;
    }

    div.designC {
      padding-top: 0px;
      margin-top: 0px;
      margin-left: 20px;
    }

    button.arrowRes3, button.arrowRes2, button.arrowRes, button.arrow9, button.arrow8 {
      display: none;
    }

    div.wireframeFull {
      display: flex;
    }

    p.ideate {
      width: 17em;
      line-height: 37px;  
    }

    div.proto {
      padding-top: 3em;
      margin-left: 6em;
    }

    div.iframe {
      display: block;
    }

    img.IA {
      width: 40em;
    }

    img.topFull, img.leftFull, img.rightFull, img.bottomFull, div.iframe2 {
      display: none;
    }

    img.topFull2 {
      width: 508px;
      height: auto;
      position: absolute;
      display: block;
      left: -9em;
      top: -8em; 
    }

    img.leftFull2 {
      position: absolute;
      left: -8.2em; /* 68.45 */
      top: 2.26em; /* 600.27em, 593.3em */
      height: 506px; /* 515px */
      width: auto;
      display: block;
    }

    img.rightFull2 {
      position: absolute;
      width: auto;
      top: 2.26em; /* 600.27em, 593.3 */
      height: 506px; /* 515px */
      left: 18em; /* 94.75 */
      display: block;
     }

    img.bottomFull2 {
      position: absolute;
      width: 499.8px;
      height: auto;
      left: -8.29em;
      top: 38.39em; /* 637em, 629.4 */
      display: block;
    }

    div.email2 {
      padding-top: 25px;
    }

    button.arrowDes {
      display: none;
    }

    button.arrowPro {
      display: none;
    }

    div.container7 p {
      width: 30em;
      font-size: 19px;
    }

    #backTopB {
      position: fixed;
      display: block;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      left: 670px;
      bottom: 50px;
    }

  }

  @media (device-height: 1366px) and (device-width: 1024px) { /* iPad pro */

    div.container2 {
      height: 1316px;
      margin-top: 50px;
      justify-content: normal;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    div.titleContent {
      margin-top: 23em;
    }

    div.centered {
      margin-top: 0px;
    }

    div.titleContent picture {
      padding-top: 0px;
    }

    img.solaceH {
      width: 25em;
    }

    div.iframe3, img.iframe3 {
      display: none;
    }

    div.container3 {
      height: 1316px;
      margin-top: 50px;
    }

    div.container4 .textSide {
      padding-left: 6em;
    }

    div.textSide p {
      font-size: 22px;
      line-height: 40px;
      width: 19em;
    }

    blockquote.b1 {
      width: 19em;
      line-height: 2.1;
      font-size: 1.7em;
    }

    div.shelP p, div.messP p, div.safetyP p, div.onP p {
      margin-left: 4em;
      width: 20em;
      font-size: 19px;
    }

    div.onP {
      padding-right: 0px;
    }

    div.onP h2 {
      margin-left: 2.5em;
      padding-left: 0px;
    }

    div.features h1.fea {
      margin-left: 2em;
    }

    button.arrowDes, button.arrowIA2 {
      display: none;
    }

    div.container5 {
      padding-left: 8em; /* check */
    }
    
  }

  /* do media heights for arrows !!!! */

  @media (min-width: 1200px) {

    img.solaceHome, img.solaceHome2 {
      display: none;
    }  

    div.iframe3, img.iframe3 {
      display: none;
    }

    div.container2 {
      /*margin-top: 1em;*/
      padding-bottom: 2em;
      display: flex;
      flex-direction: column;
      align-items: normal;
      padding-top: 0px;
    }

    div.titleContent {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0px;
    }

    div.centered {
      /*margin-left: 20em;*/
      margin-left: 2em;
      margin-right: 0px;
      margin-top: 16em;
      justify-content: normal;
    }

    div.titleSol {
      display: flex;
      align-content: center;
    }

    .centered h1 {
      font-size: 40px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      color: #333333;
    }

    .centered h2 {
      font-size: 20px !important;
      width: 22em;
      line-height: 40px;
      margin-top: 12px;
    }

    div.titleSol img.logo {
      width: 60px;
      height: 60px;
      margin-top: 10px;
      padding-top: 5px;
      padding-bottom: 2px;
    }

    button.arrow2 {
      /*top: 95%;*/
      margin-left: 50%;
      background-color: transparent;
      border: none;
      margin-top: -5em;
      display: block;
      /*width: 100px;*/
      width: 70px;
      height: 48px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
      text-align: center;
    }

    button.arrow3, button.arrow4, button.arrow5, button.arrow6, button.arrowRes2, button.arrowRes, button.arrowVid, button.arrow52, button.arrow2, button.arrowQuote, button.arrow62, button.arrowVid2,
    button.arrow72, button.arrowVid3, button.arrow7, button.arrow8, button.arrow9, button.arrow82, button.arrow72, button.arrow92, button.arrowVid4, button.arrowVid5, button.arrowDes {
      width: 100px !important;
    }

    button.arrowRes3 {
      width: 100px;
    }

    img.solaceH { /* added */
      width: 27em;
      height: auto;
      padding-top: 0px;
      margin-top: 0px;
      margin-right: 0px;
      margin-left: 190px;
    }

    picture {
      padding-top: 0px;
      margin-top: 0px;
    }

    /* container3 */

    div.container3 {
      /*padding-top: 19em;*/
      justify-content: center;
      margin-top: 0px;
      height: 53em; /* might need to delete */
    }

    .container3 div.larger {
      flex-direction: row !important;
      justify-content: center;
    }

    .timeline div, .affil div {
      width: 10em;
      align-self: flex-start;
    }

    .timeline, .affil {
      flex-direction: row !important;
      align-self: flex-start;
    }

    div.aff {
      margin-right: 5em;
    }

    button.arrow3 {
      top: 195%;
    }

    /* container 4 */

    div.container4 {
      padding-top: 5em !important; /* 7em */
      display: flex;
      /*flex-direction: row;
      align-items: normal;
      height: auto;
      align-content: center;*/
      flex-direction: column; /* added !! */
      padding-bottom: 2em; 
      height: auto;
    }

    div.smallerCont {
      flex-direction: row;
      display: flex;
      height: auto;
      align-items: normal;
      align-content: center;
      flex-wrap: nowrap;
    }

    div.container4 .textSide {
      margin-left: 8em;
      padding-bottom: 0px;
      padding-left: 0px;
    }

    .textSide p {
      font-size: 17px;
      line-height: 30px;
      width: 32em !important; /* 30em */
    }

    .textSide p.firstP {
      margin-top: 1.8em;
    }

    blockquote.b1 {
      width: 24em;
      margin-top: 30px; /* try this */
      line-height: 1.8; /* 1.6 */
      padding-right: 30px;
      border-right: 8px solid #78C0A8;
      padding-top: 20px;
    }

    blockquote.b1::before {
      left: 10px;
      top: -30px;
    }

    blockquote.b1::after {
      font-size:4em;
      position: absolute;
      right: 40px;
      top: 220px;
    }

    div.quote {
      /*margin-top: 5.5em;*/
      margin-top: 4em;
      padding-top: 30px; /* check this one */
      margin-right: 0px;
      padding-right: 0px;
      height: 34em;
    }

    button.arrowQuote {
      margin-top: 2em;
      background-color: transparent;
      border: none;
    }

    /* features */

    div.features {
      padding-left: 8em; 
      padding-top: 4em;
      margin-top: 0px;
      /* height: 247em; */
      height: auto;
      padding-bottom: 6.3em;
    }

    div.onboarding {
      width: 85em;
      padding-top: 0px; /* check */
    }
    
    div.photoOne {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div.photo1 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div.photo1 {
      padding-top: 2px;
    }

    div.photo2 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div.on, div.shel, div.safe, div.mess, div.safe2 { /* mobile */
      display: flex;
      flex-direction: row;
    }

    div.video1, div.video2, div.messVid, div.video, div.safeVid {
      display: block;
      flex-grow: 0;
    }

    div.onP, div.safetyP, div.shelP, div.safeP, div.messP {
      flex-grow: 0;
    }

    div.video1, div.video {
      align-items: center;
      padding-top: 0px;
    }

    div.video1 {
      margin-top: 0px; /* check */
      padding-top: 0px; /* added */
    }

    div.safeVid {
      margin-top: 0px;
      padding-top: 0px;
    }

    div.safeVid video.normSafe, div.video video.normShel, div.video2 video.normSafe2, div.video1 video.normOn {
      display: block;
    }

    div.safeVid video.smallSafe, div.video video.smallShel, div.video2 video.smallSafe2, div.video1 video.smallOn {
      display: none;
    }

    div.messVid {
      margin-top: 0px;
      padding-top: 0px;
      padding-right: 1em;
    }

    div.video {
      margin-top: 0px;
    }

    div.onP, div.safeP, div.shelP, div.messP /* adding safeP and shelP, messP */ {
      margin-top: 2.5em;
      height: 18em;
      padding-left: 25px;
      line-height: 30px; /* added */
    }

    div.shelP {
      padding-top: 25px;
    }

    div.onP {
      padding-top: 25px;
      margin-bottom: 0px;
    }

    p.onboardP {
      padding-left: 0px;
      text-align: none;
    }

    div.safetyP p a {
      color: #333333;
      text-decoration: underline;
    }

    div.safetyP p a:hover, div.safetyP p a:focus {
      color: #43B197;
      opacity: 1;
    }

    div.onboarding h2 {
      /*padding-left: 4em !important; *//* 50px */
      padding-left: 82px;
    }

    div.onP h2 {
      margin-top: 20px;
    }

    div.onP p {
      width: 38em; /* 35 em */
      padding-left: 7em; /* 3em */
      margin-left: 0px;
    }

    div.safeP p {
      width: 32em; /* 35 em */
      margin-right: 3em; /* added */
      margin-top: 0.5em; /* might need to mirror onP p above */
      margin-left: 42px; /* added for full */
    }

    div.safeP h2 {
      margin-left: 42px !important; /* added for full */
    }
    
    div.features h1 {
      padding-left: 0px;
      margin-left: 0px;
    }

    div.features h1.fea {
      margin-bottom: 0.7em;
    }
    
    p.onboardP, p.safescreen1, p.sheltervid, p.chatvid, p.safeP2 {
      margin-top: 0.7em;
      font-size: 15px;
      font-weight: 600; /* added */
    }

    button.arrow5 {
      /*top: 395%;
      left: 74%;*/ /* 70% */
      display: block;
      margin-left: 59%;
    }

    button.arrow52 {
      display: none;
    }

    div.safescreen {
      /*padding-top: 5em;*/
      padding-top: 7em;
      margin-top: 0em;
      /*margin-top: 1em;*/
    }

    div.video2 {
      margin-top: 1em;
      padding-top: 0px;
    }

    button.arrow6 {
      /*top: 485%;
      left: 29%; */
      display: none;
    }

    button.arrow62 {
      display: block;
      margin-left: 45%;
      margin-top: 2em;
    }

    div.shelters {
      /*padding-top: 9em;*/
      padding-top: 8em;
      margin-top: 4em; /* check rn !! */
    }

    div.message {
      padding-top: 8em;
      margin-top: 0px;
    }

    div.safety {
      padding-top: 8em;
      margin-top: 0em;
    } 

    div.shelP p {
      width: 38em; /* 35em */
      margin-top: 0.5em;
    }

    div.shelters h2  {
      /*padding-right: 9.7em;
      text-align: right; taking out to try something */
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 125px; /* 1.5em, 4em */
    }

    div.shelP p {
      padding-left: 7em; /* trying 2.7em, orig 3em; trying 5-7em */
      margin-left: 0px;
    }

    div.shelP h2 {
      margin-left: 0px; /* added for full */
    }

    button.arrow7 {
      /*top: 589%; 
      left: 74%; */
      position: static;
      margin-left: 59%;
      width: 60px;
      display: block;
    }

    button.arrow72 {
      display: none;
    }

    div.messP h2 {
      margin-left: 42px;
    }

    div.messP {
      margin-right: 2em;
    }

    div.messP p {
      width: 32em; /* 35em */
      margin-top: 0.5em;
      margin-right: 3em; /* added */
    }

    div.callVid {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    button.arrow8 {
      /*top: 683%; 
      left: 29%;*/
      display: none;
    }

    button.arrow82 {
      display: block;
      position: static;
      margin-left: 45%;
      margin-top: 2em;
    }

    div.planVid {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div.safetyP p {
      width: 38em; /* 35em */
      margin-top: 0.5em;
    }

    div.safetyP p { 
      padding-left: 7em; /* 2.7em */
      margin-left: 0px; /* added for full */
    }

    div.safetyP h2 {
      margin-left: 125px; /* added for full */
    }

    div.safetyP {
      padding-left: 25px;
      height: 18em;
      margin-top: 2.5em;
      line-height: 30px;
    }

    button.arrow9 {
      /*top: 773%;
      left: 74%;*/
      position: static;
      margin-left: 59%;
      margin-top: 2em;
      width: 60px;
    }

    /* post features */

    div.design {
      background-color: #f0f0f0;
      padding-left: 112px;
      margin-top: 5em;
      padding-top: 3em;
      padding-bottom: 1em;
    }

    div.design {
      display: none; /* hidden momentarily */
    }

    button.arrowDes {
      display: none;
    }

    div.ideation {
      margin-top: 10em; /* 120em */
      padding-top: 4em;
      /*padding-left: 8em;*/
    }

    div.ideation h1, div.ideation h2 {
      padding-bottom: 15px;
    }

    p.ideate {
      padding-left: 112px;
      width: 30.5em;
      line-height: 40px !important;
      font-size: 18px;
    }

    p.ideate2 {
      width: 40em;
      line-height: 40px !important;
      font-size: 18px;
      padding-left: 112px;
    }

    img.wire2 {
      width: 55em;
    }

    img.wire3 {
      width: 80em;
    }

    img.shelwire, img.safewire {
      width: 100em;
    }

    /* wireframes attempt 2 */

    img.contwire, img.contwire2, img.contwire4, img.contwire6, img.shelwire1, img.shelwire2, img.hotwire1, img.hotwire2, img.safewire1, img.safewire2, img.safewire3, img.safewire4 {
      height: 500px;
      width: auto;
    }

    div.design p, div.ideation p {
      font-size: 17px;
      line-height: 30px;
      font-family: europa, sans-serif;
      /*width: 50em;*/
    }

    img.IA {
      height: auto;
      width: 82%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      margin-bottom: 2em;
    }

    button.arrowIA2, button.arrowIA2 div.textWire, button.arrowIA2 div.middleWire, div.innerText, button.arrowPro, div.middlePro, div.textPro, div.arrPro, div.textPro a, button.arrowPro img {
      display: none;
    }

    div.cont1, div.cont2, div.cont3, div.cont4, div.shel1, div.shel2, div.hot1, div.hot2, div.safe1, div.safety2, div.safety3, div.safety4 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div.wire p {
      font-size: 15px;
      margin-top: 0px;
      font-family: europa, sans-serif;
      color: #333333;
      font-weight: 600;
    }

    div.row1, div.row2, div.row3 {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      justify-content: center;
    }

    div.row1 p, div.row2 p, div.row3 p {
      margin-left: 0px;
      padding-left: 0px;
    }

    img.arrowLong, img.arrowLong2, img.arrowLong3 {
      width: 60px;
      height: auto;
      margin-left: 20px;
      margin-right: 20px;
    }

    div.wireframes {
      background-color: #f0f0f0;
      padding-top: 0px;
      /* added */
      /*padding-top: 0px;
      margin-top: 0px; */
    }

    div.wireframes h2 {
      padding-top: 2.4em;
      padding-left: 112px;
    }

    div.wireframes h3 {
      text-align: left;
      color: #333333;
      padding-top: 1em;
      padding-left: 112px;
    }

    img.arrowLong3 {
      display: block;
    }

    h3.planning {
      padding-top: 2.2em;
    }

    div.ideation .IA2, div.ideation h1 {
      margin-left: 112px;
    }

    /* research */

    div.research {
      display: flex;
      flex-direction: row;
      padding-top: 1em;
    }

    div.research p {
      line-height: 40px;
      width: 25em;
      margin-bottom: 0px;
      font-family: europa, sans-serif;
    }

    div.research p.int {
      font-size: 17px;
    }

    div.takeaways {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      /*align-self: center;*/
    }

    /*div.inter, div.comp {
      display: none;
    } *//* added if circles work */

    div.container5 {
      display: block;
      background-color: white;
      /*margin-bottom: 5em;
      height: 150em; */
      margin-left: 0px; /* added */
      height: auto;
      padding-bottom: 0px;
      padding-left: 0px; /* added */
      padding-top: 6em; /* added */
    }

    div.research {
      padding-left: 8em;
      margin-left: 0px;
    }

    div.container5 h1, div.takeaways, div.interview h3 {
      padding-left: 112px;
    }

    div.interview h2 {
      padding-left: 112px;
    }

    div.interview h3 {
      text-align: left;
      font-family: 'Lato', sans-serif;
      color: #333333;
      font-size: 20px;
      width: 30em;
      font-weight: 600;
      line-height: 30px;
    }

    div.interview h3.resSub {
      font-weight: 500;
      font-family: 'europa', sans-serif;
    }

    div.container5 h3 {
      margin-bottom: 1em;
    }

    div.takeaways {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      margin-top: 0px;
      padding-top: 0px;
      padding-left: 0px;
    }

    /* divs for circle key research */

    div.takeaways .circle1 {
      opacity: 1;
      backface-visibility: visible;
      text-align: center;
      position: relative;
      margin-left: 2em;
      margin-top: 3em;
      margin-right: 2em;
      margin-bottom: 3em;
    }

    div.circle1 img {
      border-radius: 50%;
      width: 200px;
      height: 200px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }

    .takeText {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }

    .takeMid {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 200px;
      opacity: 0;
      transition: .5s ease;
      background-color: #f0f0f0;
      color: black;
      border-radius: 50%;
    }
    
    .circle1:hover .takeMid, .circle1:focus .takeMid {
      opacity: 0.97;
    }

    .takeMid p {
      width: 160px;
    }
    
    .takeText:hover, .takeText:focus, .takeText, .takeText:active {
      color: black;
      text-decoration: none;
      opacity: 1;
    }
    
     /* */

     div.ideation {
       display: block;
     }

    div.interview {
      display: flex;
      flex-direction: column;
      margin-top: 3em; /* 15em */
      padding-top: 5em;
    }

    /* adding arrow for research */

    button.arrowRes3, button.arrowRes2, button.arrowRes, button.arrow9 {
      display: block;
    }

    button.arrow92 {
      display: none;
    }


    button.arrowRes {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 885%; 
      left: 50%; */
      position: static;
      margin-top: 50px;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
    }
    
    .middleRes {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textRes a:hover, .textRes a:focus, .textRes a, .textRes, .textRes a:active {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
        
    button.arrowRes:hover .arrRes, button.arrowRes:focus .arrRes, button.arrowRes:active .arrRes {
      opacity: 0;
    }
      
    button.arrowRes:hover .middleRes, button.arrowRes:focus .middleRes {
      opacity: 1;
    }
        
    div.textRes {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* next arrow --> problem */

    button.arrowRes2 {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 985%; 
      left: 50%; */
      margin-top: 40px;
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
    }
    
    .middleRes2 {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textRes2 a:hover, .textRes2 a:focus, .textRes2 a, .textRes2 {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
        
    button.arrowRes2:hover .arrRes2, button.arrowRes2:focus .arrRes2 {
      opacity: 0;
    }
      
    button.arrowRes2:hover .middleRes2, button.arrowRes2:focus .middleRes2 {
      opacity: 1;
    }
        
    div.textRes2 {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* problem statement */

    div.problem {
      line-height: 35px;
      margin-bottom: 4em;
      text-align: left;
      display: flex;
      flex-direction: column;
      /*margin-right: 8.6em;*/
    }

    div.problem h2, div.problem h3 {
      padding-left: 112px;
    } 

    div.designC h3 {
      padding-left: 0px;
    }

    div.designC {
      display: flex;
      flex-direction: column;
      /*padding-top: 5em;*/
    }

    div.designC h4 {
      width: 26em;
      line-height: 25px;
      font-size: 18px;
    }

    div.problem h3, div.designC h3 {
      line-height: 33px;
      text-align: left;
      font-size: 20px;
      font-weight: 500;
    }

    div.designC h3 {
      width: 25em;
    }

    div.statement {
      background-color: #f0f0f0;
      display: flex;
      flex-direction: column;
      margin-top: 3.5em; /* 20em */
      padding-top: 8em;
      /*padding-bottom: 13em;*/
      padding-bottom: 2em;
      margin-bottom: 5em;
    }

    div.statement h3 {
      font-weight: 600;
    }

    div.fullProblem {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 80px;
    }

    /* problem statement quote */

    /* added from earlier quote */

    blockquote.b2 {
      width: 20em; /* 25em */
      margin-top: 10px; /* try this */
      line-height: 1.8;
      padding-right: 20px;
      height: 7em;
      text-align: left;
      padding-left: 20px;
    }

    blockquote.b2 {
      margin-left: 0;
      font-family: 'europa', sans-serif;
      font-size: 1.6em;
      color: #555555;
      background-color: #f0f0f0;
      position: relative;
      border-left: none;
      border-right: none;
    }

    div.designC {
      padding-left: 11em;
      margin-left: 0px;
      margin-top: 0px;
      padding-top: 0px;
    }

    div.problem, div.designC {
      width: 45%;
    }    

    div.quote2 {
      padding-top: 10px; /* check this one */
      margin-bottom: 20px;
      background-color: #f0f0f0;
      height: auto;
      margin-left: 0px;
      margin-top: 0px; /* added */
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 112px; /* might take this out */
    }

    div.problem, div.designC {
      flex-grow: 0.8;
    }

    div.rectangle {
      height: 26em;
      background-color: #78C0A8;
      width: 6px;
      margin-top: 1.5em;
      border-radius: 5px;
      display: flex;
    }

    /* earliest quote stuff */
    
    blockquote.b2::before {
      font-size: 4em;
      position: absolute;
      left: -30px;
      top: -35px;
    }
    
    blockquote.b2::after{
      content: "\201D";
      color: #78C0A8;
      font-family: Arial;
      font-size: 4em; 
      position: absolute;
      right: -12px;
      top: 198px;
    } 

    /* problem statement arrow */

    button.arrowRes3 {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 1095%; 
      left: 50%; */
      height: 50px;
      position: static;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
    }
    
    .middleRes3 {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textRes3 a:hover, .textRes3 a:focus, .textRes3 a, .textRes3 {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
        
    button.arrowRes3:hover .arrRes3, button.arrowRes3:focus .arrRes3 {
      opacity: 0;
    }
      
    button.arrowRes3:hover .middleRes3, button.arrowRes3:focus .middleRes3 {
      opacity: 1;
    }
        
    div.textRes3 {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 6em;
      font-weight: 400;
    }

    /* wireframes arrow */

    button.arrowWire {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 1205%; 
      left: 50%; */
      position: static;
      width: 100px;
      height: 50px;
      margin-left: 50%;
      margin-top: 0px;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;
      display: block;
      background-color: transparent !important;
      border: none;

    }
    
    .middleWire {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
        
    .textWire a:hover, .textWire a:focus, .textWire a, .textWire {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
        
    button.arrowWire:hover .arrWire, button.arrowWire:focus .arrWire {
      opacity: 0;
    }
      
    button.arrowWire:hover .middleWire, button.arrowWire:focus .middleWire {
      opacity: 1;
    }
        
    div.textWire {
      color: black;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      padding: 5px;
      width: 7em;
      font-weight: 400;
    }

    /* wave */

    /* wireframe arrow */

    div.ideation {
      margin-top: 0px;
    }

    div.interact {
      margin-left: 10em;
      padding-top: 11em;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div.interact p {
      font-weight: 600;
      font-family: 'europa', sans-serif;
    }

    div.interAct {
      display: flex;
      flex-direction: row !important;
      justify-content: center;
    }

    p.interAct1 {
      width: 10em;
      text-align: center;
      line-height: 25px;
      padding-top: 10px;
    }

    p.interAct2 {
      margin-top: 4em;
    }

    div.ideateIntro h1 {
      margin-top: 0px;
    }

    div.ideateIntro {
      padding-top: 3em;
      padding-left: 0px;
      margin-left: 0px;
    }

    img.protoArrow {
      width: 65px;
      height: 22.5px;
      margin-top: 13em;
      margin-left: 1em;
    }

    div.interArrow {
      display: block;
    }

    img.protoArrow2 {
      width: 65px;
      height: auto;
    }    

    img.protoArrow2:hover, img.protoArrow2:active, img.protoArrow2:focus {
      opacity: 0.8;
      cursor: pointer;
    }

    div.wireframes {
      margin-top: 5.5em;
      padding-bottom: 4.5em;
      /*height: 150em;*/
      height: auto;
    }

    h3.invite {
      padding-top: 0px !important;
      margin-top: 0px;
    }

    /* ideation/wireframe */

    div.wireframeFull {
      display: flex;
      flex-direction: row;
      height: auto; /* added */
    }
  
    div.proto {
      margin-left: 0px;
      /*padding-top: 1.5em;*/
      padding-top: 3em;
      margin-top: 0px;
    }
    
    div.protoFull {
      top: auto;
      position: static;
      /* check this */
      /*position: absolute;*/
      /*left: 4em;*/
    }

    img.topFull, img.rightFull, img.leftFull, img.bottomFull, div.iframe2, div.iframe3 {
      display: none;
    }

    div.iframe {
      padding-left: 9.9em;
      display: block;
    }

    img.topFull2 {
      width: 508px;
      height: auto;
      position: absolute;
      display: block;
      left: 67.85em;
      top: 565.5em; /* 590em, 583em, 559em */
    }

    img.leftFull2 {
      position: absolute;
      left: 68.6em; /* 68.45 */
      top: 575.75em; /* 600.27em, 593.3em */
      height: 506px; /* 515px */
      width: auto;
      display: block;
    }

    img.rightFull2 {
      position: absolute;
      width: auto;
      top: 575.75em; /* 600.27em, 593.3 */
      height: 506px; /* 515px */
      left: 94.8em; /* 94.75 */
      display: block;
     }

    img.bottomFull2 {
      position: absolute;
      width: 499.8px;
      height: auto;
      left: 68.45em;
      top: 611.9em; /* 637em, 629.4 */
      display: block;
    }

    button.arrowPro {
      display: none;
    }

    button.arrowIA {
      opacity: 1;
      transition: .5s ease;
      backface-visibility: visible;
      /*position: absolute;
      top: 1500%; 
      left: 50%; */
      position: static;
      width: 80px;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    button.arrowIA:hover .arrWire, button.arrowIA:focus .arrWire {
      opacity: 0;
    }
      
    button.arrowIA:hover .middleWire, button.arrowIA:focus .middleWire {
      opacity: 1;
    } 

    div.IAFull {
      padding-top: 0px;
      padding-bottom: 0em;
    }

    h2.IA2 {
      padding-top: 2.2em;
      margin-bottom: 0px !important;
      padding-bottom: 15px !important;
    }

    /* reflection */

    div.container7 {
      display: flex;
      padding-left: 8em;
    }

    div.container7 h1 {
      font-family: 'Lato', sans-serif;
      font-weight: 600;
    }

    div.container7 p {
      font-family: europa, sans-serif;
      font-size: 17px;
      /*font-size: 20px;*/
      line-height: 35px;
      width: 30em;
    }

    div.container7 div {
      flex-grow: 1;
    }

    div.container7 img {
      width: 900px;
      height: auto;
    }

    /* footer */

    div.footer {
      height: 9em;
      margin-top: 2em;
      display: block;
    }

    div.email2 {
      font-weight: 500;
      font-size: 20px;
      text-align: right;
    }

    div.email2 a {
      text-decoration: none;
    }

    div.email2 a:hover, div.email2 a:focus {
      text-decoration: none;
      opacity: 0.5;
    }

    div.plink {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding-top: 4.5em;
      margin-bottom: 0px;
    }

    div.nextP {
      font-family: 'europa', sans-serif;
      margin-bottom: 1em;
      font-size: 16px;
    }
    
    div.pName {
      font-size: 32px;
      font-family: 'Castoro', sans-serif;
      color: #333333;
      margin-bottom: 0px;
    }

    div.pName a {
      color: #333333;
      text-decoration: none;
      padding-left: 15px;
      opacity: 1;
    }

    img.footLink {
      padding-left: 2px;
    }

    div.pName a:hover, img.footLink:hover, div.pName a:focus, img.footLink:focus {
      text-decoration: none;
      opacity: 0.8;
    }

    div.bottomLinks {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      background-color: #D9D3F2;
      width: 100%;
      height: 6.5em;
    }

    div.backTop2, div.email2 {
      flex-grow: 1;
    }

    div.email2 {
      margin-right: 95px;
      padding-top: 0px;
    }

    /*div.backTop2 {
      margin-left: 95px;
    }*/

    div.email2, div.backTop2 {
      padding-bottom: 5px;
    }

    img.backTop {
      width: 80px;
      height: 80px;
      margin-bottom: 0px;
      background-color: transparent;
    }  

    div.credit {
      text-align: center;
      padding-top: 10px;
      align-self: flex-end;
      padding-bottom: 5px;
      display: block;
      padding-left: 0px;
      padding-right: 0px;
    }

    div.credit p {
      margin-bottom: 0px;
    }

    img.backTop:hover, img.backTop:focus {
      opacity: 0.8;
    }

    #backTopB {
      background-color: #D9D3F2;
      border: none;
      width: 80px;
      height: 80px;
      outline: none;
      /*bottom: 0;*/
      position: static;
      bottom: 41px;
      /* added left */
      margin-left: 95px;
      display: block;
      z-index: 99;
    }

    div.backTop2 {
      background-color: transparent;
      outline: none;
      display: flex;
      position: static;
      flex-direction: column;
      justify-content: flex-end;
    }

    img.reflectionImage {
      display: block;
    }

  }