body, html {
    height: 100%;
    scroll-behavior: smooth;
  }

 div.bgimg, .bgimg2 {
    font-family: 'europa', sans-serif;
    font-size: 20px;
    margin-bottom: 0px;
}
  
  .left {
    position: normal;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }

  .box {
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 1em;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }

  .box a:hover, .box a:focus {
    opacity: 0.8;
    color: #446992;
  }

  .box p {
    width: 14em;
  }


  div.designL, div.ideationS, div.productS, div.reflectionS, div.researchS {
    display: none;
  }

  div.solsticePage a {
    color: #446992;
  }

  div.solsticePage a:hover, div.solsticePage a:focus, div.solsticePage a:active {
      color: #446992;
      opacity: 0.8;
  }

  div.solsticePage h1 {
    font-family: 'Lato', 'sans-serif';
    font-weight: 600;
  }
  
  /* */

  div.Solscontainer {
    width: 100%;
    padding-top: 10em; /* added */
    padding-bottom: 8em; /* 10em */
    display: flex;
    flex-direction: column;
    justify-content: center; /* added */
    background-color: #f0f0f0;
  }

  div.Solscontainer h2 {
    font-family: 'europa', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px; /* 18px */
  }

  div.centered3 {
    display: flex;
    flex-direction: column;
   /* margin-left: auto;
    margin-right: auto;*/
  }

  div.titleSols {
    display: flex;
    flex-direction: row;
    align-self: center;
  }

  .centered3 h1 {
    font-size: 35px; /*40px */
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #333333;
  }

  .centered3 h2 {
    width: 17em;
    line-height: 32px;
    align-self: center;
    text-align: center;
  }

  div.titleSols img.logo2 {
    width: auto; /*60px */
    height: 60px; /* auto? */
    margin-left: 15px;
    margin-top: 10px;
    /*margin-top: 11px; 
    padding-top: 4px;*/
    /*margin-top: 13px;*/
    /*padding-top: 4px;
    padding-bottom: 2px;*/
  }

  img.solsPic {
    width: 20em;
    height: auto;
    margin-top: 4em;
    margin-left: 190px;
    display: none;
  }

  img.solsPic1, img.solsPic2 {
    display: none;
  }

  button.arrow2S, button.arrow4S, button.arrow5S, button.arrow6S {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    /*position: absolute; 
    left: 50%;*/
    position: static;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 80px;
  }

  button.arrow3S {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: visible;
    position: absolute; 
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    top: 200%; /* 190% */
    width: 80px;
  }

  button.arrow2S {
    /*top: 94%;*/
    margin-top: 2em;
  }

  button.arrow4S {
    top: 315%;
  }

  button.arrow5S {
    top: 90%;
    margin-top: 5em;
  }

  button.arrow6S {
    top: 775%;
    margin-top: 3em;
  }
  
  div.middlethree {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    /*padding-left: 5px;*/
  }
  
  .text3 a:hover, .text3 a, .text3, .text3 a:focus {
    color: black !important;
    text-decoration: none;
    cursor: pointer;
  }

  .text5S a:hover, .text5S a, .text5S, .text5S:focus {
    color: white !important;
    text-decoration: none;
    cursor: pointer;
  }

  div.text5S {
    color: white;
    font-size: 16px;
    padding: 5px !important;
    width: 5em !important;
    font-weight: 400 !important;
    font-family: 'futura-pt', 'sans-serif';
  }
  
  button.arrow3S:hover .arr3S, button.arrow2S:hover .arr2S, button.arrow4S:hover .arr4S, button.arrow5S:hover .arr5S, button.arrow6S:hover .arr6S {
    opacity: 0;
  }
  
  button.arrow3S:hover .middlethree, button.arrow2S:hover .middlethree, button.arrow4S:hover .middlethree, button.arrow5S:hover .middlethree, button.arrow6S:hover .middlethree {
    opacity: 1;
  }

  button.arrow3S:focus .arr3S, button.arrow2S:focus .arr2S, button.arrow4S:focus .arr4S, button.arrow5S:focus .arr5S, button.arrow6S:focus .arr6S {
    opacity: 0;
  }
  
  button.arrow3S:focus .middlethree, button.arrow2S:focus .middlethree, button.arrow4S:focus .middlethree, button.arrow5S:focus .middlethree, button.arrow6S:focus .middlethree {
    opacity: 1;
  }
  
  div.text3 {
    color: black;
    font-size: 16px;
    padding: 5px !important;
    width: 5em !important;
    font-weight: 400;
    font-family: 'futura-pt', 'sans-serif';
  }

  /* next container */

  div.solscontainer2 {
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    height: 48em;
    padding-top: 5em;
    margin-top: 0px;    
    display: flex;
    flex-direction: column;
  }

  div.solscontainer2 div.larger3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  div.solscontainer2 .solstimeline, div.solscontainer2 .solsaffil {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .solstimeline div, .solsaffil div {
    width: 10em;
    margin-bottom: 2em;
  }

  div.solsaff {
    text-align: center;
  }

  div.solsrole {
    margin-left: 5em;
    margin-right: 5em;
  }

  div.solscontainer2 div h4 {
    font-size: 20px;
    margin-bottom: 1em;
  }
  
  div.solscontainer2 h4 {
    font-weight: 600 !important;
    font-family: 'futura-pt', sans-serif;
  }

  div.solscontainer2 div h5 {
    font-size: 17px;
    text-align: center;
  }

  /* overview */

 /* div.largeConcS p {
    margin-right: 5em;
    line-height: 35px; 
    width: 15.5em;
    margin-bottom: 20px;
  }*/

 div.solsroleFull div p {
    margin-right: 5em;
    line-height: 35px; 
    width: 15.5em;
    margin-bottom: 20px;
  }

  div.solsroleFull {
    background-color: #f0f0f0;
    padding-bottom: 2em;
    padding-top: 2em;
  }

  div.largeConcS p {
    font-family: 'europa', sans-serif;
    font-size: 17px;
  }

  div.largeConcS {
    margin-left: 42px;
    padding-left: 0px;
  }

  div.solsroleFull h1, div.featuresS h1 {
    padding-left: 42px;
    margin-left: 0px;
  }

  div.featuresS h2 {
    padding-left: 42px;
    margin-bottom: 20px;
  }

  div.featuresS {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  div.photosTitle {
    display: flex;
    flex-direction: row;
  }

  img.star {
    height: 25px;
    width: auto;
    margin-top: 25px;
    margin-left: 10px;
  }

  /* features images */

  div.lockImg img {
    width: 100px;
    height: auto;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  div.lockImg {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  /* footer */

  div.credit4 p {
    text-align: center;
    font-size: 16px;
    font-family: 'europa', 'sans-serif';
    font-weight: 500;
  }

  div.email4 {
    /*margin-right: 95px;*/
    padding-bottom: 5px;
    padding-top: 35px;
    margin-bottom: 0px;
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
  }

  div.email4 a {
    font-weight: 500;
    text-decoration: none;
    color: #333333;
  }

  #backTopS {
    background-color: #DFD5FF;
    border: none;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    outline: none;
    bottom: 75px;
    
    left: 265px;
    padding: 0px;
    display: block;
    position: fixed;
    z-index: 99;
  }

  div.backTop4 {
    background-color: transparent;
    outline: none;
    left: 240px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-right: 6px;
  }

  img.backTop5 {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
    padding-bottom: 0px;
    background-color: transparent;
  }  

  div.bottomLinks3 {
    background-color: #DFD5FF;
    width: 100%;
    height: 6.5em;
    border: none;
  }

  div.credit4 {
    text-align: center;
    padding-top: 10px;
    align-self: flex-end;
    padding-bottom: 5px;
    display: block;
    padding-left: 30px;
    padding-right: 30px;
  }

  div.credit4 p {
    margin-bottom: 0px;
  }

  div.solsFooter {
    height: 9.1em;
    margin-top: 0em;
    display: block;
  }    

  div.email4 a:hover, div.email4 a:focus, div.email4 a:active {
    text-decoration: none;
    opacity: 0.5;
  }

  div.plink3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 2em;
    margin-bottom: 0px;
  }

  div.nextP3 {
    font-family: 'europa', sans-serif;
    margin-bottom: 1em;
    font-size: 16px;
  }
  
  div.pName3 {
    font-size: 32px;
    font-family: 'Castoro', sans-serif;
    color: #333333;
    margin-bottom: 0px;
  }

  div.pName3 a {
    color: #333333;
    text-decoration: none;
    padding-left: 15px;
    opacity: 0.8;
  }

  img.footLink3 {
    padding-left: 2px;
  }

  div.pName3 a:hover, img.footLink3:hover, div.pName3 a:active, div.pName3 a:focus, img.footLink3:active, img.footLink3:focus {
    text-decoration: none;
    opacity: 0.8;
  }

  div.solsFooter, div.credit4, div.bottomLinks3 {
    background-color: #DFD5FF;
  }


  @media (min-width: 370px) and (max-width: 380px) and (min-height: 660px) and (max-height: 680px) { /* iphone 6 */

    div.bgimg {
      height: 820px;
    }

    .box {
      margin-left: 20px;
      padding-left: 1em;
      padding-right: 1em;
      margin-right: 20px;
      margin-top: 11em;
    }

    button.arrow3S {
      top: 170%;
    }

    .box img.logo2 {
      padding-left: 0px;
    }

    p.smaller {
      margin-top: 1em;
    }

    div.lockImg {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    div.lockImg img {
      width: 130px;
      height: auto;
    }

    div.left {
      right: 16px;
    }

    div.solscontainer2 {
      padding-top: 5em;
    }

    .solstimeline div, .solsaffil div {
      margin-bottom: 1.5em;
    }

    #backTopS {
      left: 310px;
    }
  }  

  @media (min-height: 730px) and (min-width: 370px) { /* iphone x */

    div.bgimg {
      height: 860px;
      margin-top: 1em;
    }

    .box {
      padding: 1em;
      margin-top: 11em;
    }

    img.solsPic1 {
      width: 220px;
      height: auto;
      margin-bottom: 1em;
      margin-right: auto;
      margin-left: auto;
      display: flex;
    }

    img.solsPic2, img.solsPic {
      display: none;
    }

    div.Solscontainer {
      padding-top: 6em;
    }

    .centered3 {
      margin-bottom: 30px;
    }

    div.titleSols img.logo2 {
      width: auto; /*60px */
      height: 40px; 
      margin-left: 15px;
      margin-top: 20px;
    }

    button.arrow2S {
      top: 125%;
    }

    button.arrow3S {
      top: 250%;
    }

    div.solscontainer2 {
      height: 58em;
      padding-top: 10em;
    }

    button.arrow4S {
      top: 610%;
    }

    button.arrow6S {
      top: 808%;
    }

    /* overview */

    div.solsroleFull {
      padding-top: 4em;
      padding-bottom: 10em;
    }
    
    div.largeConcS p {
      width: 17em;
    }

    /* features */

    div.featuresS {
      padding-top: 2em;
      padding-bottom: 0em; /* used to be 2em */
    }

    div.lockImg {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    
    div.lockImg img {
      width: 130px;
      height: auto;
    }

    /* footer */

    div.solsfooter {
      height: 9.4em;
    }

    #backTopS {
      left: 310px;
    }


  }  

  @media (device-height: 736px) and (min-width: 412px) and (max-width: 416px) {

    div.solscontainer2 {
      padding-top: 6em;
    }

    #backTopS {
      left: 340px;
    }



  }  

  @media only screen and (device-width: 375px) and (device-height: 812px) { /* iphone 11 pro */

    div.bgimg {
      height: 860px;
      margin-top: 1em;
    }

    .box {
      padding: 1em;
      margin-top: 11em;
    }

    img.solsPic1 {
      width: auto;
      height: 350px;
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }

    img.solsPic2, img.solsPic {
      display: none;
    }

    div.titleSols img.logo2 {
      width: auto;
      height: 40px; 
      margin-left: 15px;
      margin-top: 20px;
    }

    div.Solscontainer {
      padding-top: 6em;
    }

    .centered3 {
      margin-bottom: 30px;
    }

    button.arrow2S {
      top: 125%;
    }

    div.solscontainer2 {
      height: 58em;
      padding-top: 10em;
    }

    div.solsroleFull {
      padding-top: 4em;
      padding-bottom: 10em;
    }
    
    div.largeConcS p {
      width: 17em;
    }

    button.arrow3S {
      top: 250%;
    }

    button.arrow4S {
      top: 610%;
    }

    button.arrow6S {
      top: 808%;
    }

    /* features */

    div.lockImg {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    div.featuresS {
      padding-top: 2em;
      padding-bottom: 2em;
    }
    
    div.lockImg img {
      height: 130px;
      width: auto;
    }

    div.solsfooter {
      height: 9.4em;
    }

    #backTopS {
      left: 310px;
    }

  }


  @media (min-width: 768px) { /* iPad */

    div.solscontainer2 {
      margin-top: 0px; /* added for iPad */
      height: 73em;
      justify-content: center;
      padding-top: 0px;
    }

    div.larger3 h5 {
      line-height: 23px !important;
    }

  }

  @media (min-width: 1200px) {

    div.bgimg {
      height: 705px;
    }

    img.solsPic1, img.solsPic2 {
      display: none;
    } 

    div.Solscontainer2 h1, div.solsroleFull h1, div.featuresA h1, div.researchA h1, div.ideationA h1, div.prototypeA h1, div.reflectionA h1 {
      font-family: 'Lato', 'sans-serif';
      font-weight: 600;
      color: #333333;
      padding-left: 112px;
      margin-left: 0px;
    }

    div.featuresA h1, div.researchA h1, div.ideationA h1, div.prototypeA h1, div.reflectionA h1 {
      display: none;
    }

    div.Solscontainer {
      padding-bottom: 6em; /* 8em */
      display: flex;
      flex-direction: column;
      align-items: normal;
      padding-top: 0px;
      margin-top: 3em;
      justify-content: center;
    }

    img.solsPic {
      margin-left: 240px;
      display: block;
      width: 20em;
      height: auto;
    }

    div.centered3 {
      margin-top: 12em;
      justify-content: normal;
      display: flex;
      flex-direction: column;
      margin-left: 0px; 
      margin-right: 0px;
    }

    div.titleSols {
      display: flex;
      align-content: center;
      align-self: normal;
    }

    div.titleContent3 {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
    }

    .centered3 h1 {
      font-size: 40px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      color: #333333;
    }

    .centered3 h2 {
      font-size: 20px !important;
      width: 22em;
      line-height: 40px;
      align-self: normal;
      text-align: left;
    }

    button.arrow2S {
      top: 95%;
    }

    button.arrow3S {
      top: 195%;
    }

    button.arrow4S {
      top: 315%;
    }

    /* next container */

    div.solscontainer2 {
      /*padding-top: 19em;*/
      margin-top: 0px;
    }

    div.solscontainer2 {
      height: 53em; /* might need to delete */
    }

    .solscontainer2 div.larger3 {
      flex-direction: row !important;
      justify-content: center;
    }

    .solstimeline div, .solsaffil div {
      width: 10em;
      align-self: flex-start;
    }

    .solstimeline, .solsaffil {
      flex-direction: row !important;
      align-self: flex-start;
    }

    div.solsaff {
      margin-right: 5em;
    }

    /* role & concept */

    /*.solsroleFull div p {
      font-size: 19px;
      line-height: 32px;
      width: 20em; 
      margin-right: 2em;
    }

    .solsroleFull div {
      display: flex;
      flex-direction: row;
      margin-left: 112px;
      margin-top: 2em;
      flex-wrap: wrap;
    }*/

    div.solsroleFull {
      margin-top: 2em;
      padding-top: 5em;
      background-color: #f0f0f0 !important;
      padding-bottom: 7em;
      margin-bottom: 0px;
    }

    div.solsroleFull div.largeConcS {
      display: flex;
      flex-direction: column;
      padding-top: 1.5em;
      padding-left: 0px;
      margin-left: 0px;
    }

    div.solsroleFull div.largeConcS div {
      display: flex;
      flex-direction: row;
      padding-left: 112px;
      margin-left: 0px;
    }

    div.solsroleFull div p {
      line-height: 36px;
      font-family: europa, sans-serif;
      font-size: 17px;
      width: 32em;
      margin-bottom: 10px;
      margin-left: 0px;
      padding-left: 0px;
    }

    div.solsroleFull p.firstP {
      margin-right: 12em;
      padding-left: 0px;
      margin-left: 0px;
    }

    /* features/screens */

    div.lockImg {
      display: flex;
      flex-direction: row;
    }

    div.lockScreen, div.homeScreen, div.messageScreen, div.photosScreen, div.weatherScreen {
      margin-top: 1em;
      margin-bottom: 0.5em;
    }

    div.featuresS {
      padding-top: 3em;
      padding-bottom: 6em; /* used to be 8em */
      display: flex;
      flex-direction: column;
    }

    div.featuresS h1, div.featuresS h2 {
      margin-left: 112px;
      padding-left: 0px;
    }

    div.featuresS h2 {
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      margin-bottom: 1em;
    }

    div.lockImg {
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }

    h2.lockTitle {
      padding-top: 0px;
      margin-top: 0px;
    }

    div.lockImg img {
      height: 500px;
      width: auto;
      margin-right: 4em;
    }

    div.photosTitle {
      display: flex;
      flex-direction: row;
    }

    img.star {
      height: 30px;
      width: auto;
      margin-top: 23px;
      margin-left: 10px;
    }

    button.arrow6S {
      top: 780%;
    }

    /* coming soon */

    .left {
      position: normal;
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
    }
  
    .box {
      background-color: #f0f0f0;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      padding: 2em;
      border-radius: 10px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 11em;
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
    }
  
    .box a:hover, .box a:focus {
      opacity: 0.8;
      color: #446992;
    }
  
    .box p {
      width: 18em;
    }

    /* footer */

    div.solsFooter {
      height: 9em;
      /*margin-top: 2em;*/
      display: block;
      margin-top: 0px;
    }

    div.email4 {
      font-weight: 400;
      font-size: 20px;
      text-align: right;
    }

    div.email4 a {
      text-decoration: none;
    }

    div.email4 a:hover, div.email4 a:focus {
      text-decoration: none;
      opacity: 0.5;
    }

    div.plink3 {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding-top: 4.5em;
      margin-bottom: 0px;
    }

    div.nextP3 {
      font-family: 'europa', sans-serif;
      margin-bottom: 1em;
      font-size: 16px;
    }
    
    div.pName3 {
      font-size: 32px;
      font-family: 'Castoro', sans-serif;
      color: #333333;
      margin-bottom: 0px;
    }

    div.pName3 a {
      color: #333333;
      text-decoration: none;
      padding-left: 15px;
    }

    img.footLink3 {
      padding-left: 2px;
    }

    div.pName3 a:hover, img.footLink2:hover, div.pName3 a:focus, img.footLink2:focus {
      text-decoration: none;
      opacity: 0.8;
      color: #333333;
    }

    div.bottomLinks3 {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      background-color: #DFD5FF;
      width: 100%;
      height: 6.5em;
    }

    div.backTop4, div.email4 {
      flex-grow: 1;
    }

    div.email4 {
      margin-right: 95px;
      font-weight: 500 !important;
      padding-top: 0px;
    }

    /*div.backTop4 {
      margin-left: 95px;
    }*/

    div.email4, div.backTop4 {
      padding-bottom: 5px;
    }

    img.backTop5 {
      width: 80px;
      height: 80px;
      margin-bottom: 0px;
      background-color: transparent;
    }  

    div.credit4 {
      text-align: center;
      padding-top: 10px;
      align-self: flex-end;
      padding-bottom: 5px;
      display: block;
      margin-top: 0px;
    }

    div.credit4 p {
      margin-bottom: 0px;
    }

    img.backTop5:hover, img.backTop5:focus {
      opacity: 0.8;
    }

    div.backTop4 {
      background-color: transparent;
      outline: none;
      display: flex;
      position: static;
      flex-direction: column;
      justify-content: flex-end;
    }

    #backTopS {
      background-color: #DFD5FF;
      border: none;
      width: 80px;
      height: 80px;
      outline: none;
      position: static;
      bottom: 41px;
      margin-left: 95px;
      display: block;
      z-index: 99;
    }

  }
  